import React, {useCallback, useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector} from "react-redux";
import {useLocation} from "react-router";
import {company, companyPrice,} from "../translate";
import axios from "axios";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 800 ? window.innerWidth - 60 : 800,
    height: window.innerHeight - 60,
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function CompanyPrice({create, setCreate,data}) {
    const lang = useSelector(state => state.users.lang)
    const [allData,setAllData] = useState([])
    useEffect(() => {
        (async () =>{
           const {data} = await Api.getIncluded()
            setAllData(data.data)
        })()
    }, [])
    return(
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create}
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create }>
                    <Box sx={style}>
                        <Typography id="transition-modal-title">
                            <span style={{marginBottom: 30, color: 'black', fontSize: 25}}
                                  className="d-flex justify-content-between">
                                <span>{company.title[+lang]}</span>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => setCreate(false)}>x</span>
                            </span>
                        </Typography>

                        <div className="d-flex flex-column align-items-center">
                            {Object.keys(data).map(key => (
                                <>
                                    {Object.keys(data[key]).map(keys => {
                                        return (
                                            <>
                                                <div key={keys}>
                                                    {keys === 'name' ? companyPrice[data[key][keys]][lang] : keys + ' ' + data[key][keys]}
                                                </div>
                                                {keys === 'name' && data[key].name === 'PHONE' && allData?.length?
                                                    <>
                                                        <hr className="w-100"/>
                                                        <p>Phone numbers</p>
                                                        {
                                                            allData.map(s => (
                                                                <div key={s.phone}>
                                                                    {s.phone} {s.context}
                                                                </div>
                                                            ))
                                                        }<hr className="w-100"/>
                                                    </>
                                                    :null
                                                }
                                            </>
                                        )
                                    })}
                                    {key !== 'total_price'?<hr className="w-100"/>:null}
                                </>
                                ))}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default CompanyPrice;

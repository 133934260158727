import {
    CLIENT_CREATE_FAIL,
    CLIENT_CREATE_REQUEST,
    CLIENT_CREATE_SUCCESS, CLIENT_DELETE_FAIL,
    CLIENT_DELETE_REQUEST,
    CLIENT_DELETE_SUCCESS,
    CLIENT_EDIT_FAIL,
    CLIENT_EDIT_REQUEST,
    CLIENT_EDIT_SUCCESS,
    CLIENT_FAIL,
    CLIENT_REQUEST, CLIENT_SINGLE_REQUEST, CLIENT_SINGLE_SUCCESS,
    CLIENT_SUCCESS
} from '../actions/clients';


const initialState = {
    clientData: {},
    clientStatus:'',
    clientSingleData: {},
    clientSingleStatus:'',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLIENT_REQUEST: {
            return {
                ...state,
                clientStatus: 'request',
                clientData: {},
            };
        }
        case CLIENT_SUCCESS: {
            return {
                ...state,
                clientStatus: 'success',
                clientData: action.payload.data,
            };
        }

        case CLIENT_SINGLE_REQUEST: {
            return {
                ...state,
                clientSingleStatus: 'request',
                clientSingleData: {},
            };
        }
        case CLIENT_SINGLE_SUCCESS: {
            return {
                ...state,
                clientSingleStatus: 'success',
                clientSingleData: action.payload.data.client,
            };
        }
        case CLIENT_CREATE_SUCCESS: {
            return {
                ...state,
                clientStatus: 'success',
                clientData: action.payload.data,
            };
        }
        case CLIENT_EDIT_SUCCESS: {
            return {
                ...state,
                clientStatus: 'success',
                clientData: action.payload.data,
            };
        }
        case CLIENT_DELETE_SUCCESS: {
            return {
                ...state,
                clientStatus: 'success',
                clientData: action.payload.data,
            };
        }
        case CLIENT_CREATE_REQUEST: {
            return {
                ...state,
                clientStatus: 'request',
                clientData: {},
            };
        }
        case CLIENT_EDIT_REQUEST: {
            return {
                ...state,
                clientStatus: 'request',
                clientData: {},
            };
        }
        case CLIENT_DELETE_REQUEST: {
            return {
                ...state,
                clientStatus: 'request',
                clientData: {},
            };
        }
        case CLIENT_CREATE_FAIL: {
            return {
                ...state,
                clientStatus: 'fail',
                clientData: {},
            };
        }
        case CLIENT_EDIT_FAIL: {
            return {
                ...state,
                clientStatus: 'fail',
                clientData: {},
            };
        }
        case CLIENT_DELETE_FAIL: {
            return {
                ...state,
                clientStatus: 'fail',
                clientData: {},
            };
        }
        case CLIENT_FAIL: {
            return {
                ...state,
                clientStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

import {
    TASK_ALL_SUCCESS,
    TASK_CREATE_FAIL,
    TASK_ALL_FAIL,
    TASK_CREATE_REQUEST,
    TASK_CREATE_SUCCESS,
    TASK_DELETE_FAIL,
    TASK_ALL_REQUEST,
    TASK_DELETE_REQUEST,
    TASK_DELETE_SUCCESS,
    TASK_EDIT_FAIL,
    TASK_EDIT_REQUEST,
    TASK_EDIT_SUCCESS,
    TASK_FAIL,
    TASK_REQUEST,
    TASK_SUCCESS
} from '../actions/task';

const initialState = {
    taskData: {},
    taskStatus:'',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TASK_REQUEST: {
            return {
                ...state,
                taskStatus: 'request',
                taskData: {},
            };
        }
        case TASK_SUCCESS: {
            return {
                ...state,
                taskStatus: 'success',
                taskData: action.payload.data,
            };
        }

        case TASK_CREATE_SUCCESS: {
            return {
                ...state,
                taskStatus: 'success',
                taskData: action.payload.data,
            };
        }
        case TASK_ALL_SUCCESS: {
            return {
                ...state,
                taskStatus: 'success',
                taskData: action.payload.data,
            };
        }

        case TASK_DELETE_SUCCESS: {
            return {
                ...state,
                taskStatus: 'success',
                taskData: action.payload.data,
            };
        }

        case TASK_EDIT_SUCCESS: {
            return {
                ...state,
                taskStatus: 'success',
                taskData: action.payload.data,
            };
        }
        case TASK_CREATE_REQUEST: {
            return {
                ...state,
                taskStatus: 'request',
                taskData: {},
            };
        }

        case TASK_DELETE_REQUEST: {
            return {
                ...state,
                taskStatus: 'request',
                taskData: {},
            };
        }
        case TASK_ALL_REQUEST: {
            return {
                ...state,
                taskStatus: 'request',
                taskData: {},
            };
        }

        case TASK_EDIT_REQUEST: {
            return {
                ...state,
                taskStatus: 'request',
                taskData: {},
            };
        }case TASK_CREATE_FAIL: {
            return {
                ...state,
                taskStatus: 'fail',
                taskData: {},
            };
        }

        case TASK_DELETE_FAIL: {
            return {
                ...state,
                taskStatus: 'fail',
                taskData: {},
            };
        }
        case TASK_ALL_FAIL: {
            return {
                ...state,
                taskStatus: 'fail',
                taskData: {},
            };
        }

        case TASK_EDIT_FAIL: {
            return {
                ...state,
                taskStatus: 'fail',
                taskData: {},
            };
        }
        case TASK_FAIL: {
            return {
                ...state,
                taskStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

import { takeLatest, call, put } from 'redux-saga/effects';
import {
    DEPARTMENT_REQUEST,
    DEPARTMENT_SUCCESS,
    DEPARTMENT_FAIL, COMPANY_STATUS_SUCCESS, COMPANY_STATUS_FAIL, COMPANY_STATUS_REQUEST
} from '../actions/department';
import Api from '../../Api';

function* handleCompany(action) {
    try {
        const { data } = yield call(Api.getDepartment);
        yield put({
            type: DEPARTMENT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: DEPARTMENT_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleCompanyStatus(action) {
    try {
        const { data } = yield call(Api.getStatus);
        yield put({
            type: COMPANY_STATUS_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: COMPANY_STATUS_FAIL,
            payload: { error: e.response },
        });
    }
}




export default function* watcher() {
    yield takeLatest(DEPARTMENT_REQUEST, handleCompany);
    yield takeLatest(COMPANY_STATUS_REQUEST, handleCompanyStatus);
}

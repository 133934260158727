import { takeLatest, call, put } from 'redux-saga/effects';
import {
    EMPLOYEE_DELETE_SUCCESS,
    EMPLOYEE_DELETE_REQUEST,
    EMPLOYEE_DELETE_FAIL,
    EMPLOYEE_EDIT_FAIL,
    EMPLOYEE_EDIT_REQUEST,
    EMPLOYEE_EDIT_SUCCESS,
    EMPLOYEE_REQUEST,
    EMPLOYEE_SUCCESS,
    EMPLOYEE_FAIL, EMPLOYEE_CREATE_SUCCESS, EMPLOYEE_CREATE_FAIL, EMPLOYEE_CREATE_REQUEST
} from '../actions/employee';
import Api from '../../Api';
import {toast} from "react-toastify";

function* handleEmployee(action) {
    try {
        const {rowsPerPage,page,search,type} = action.payload
        const { data } = yield call(Api.getEmployee, rowsPerPage,page,search,type);
        yield put({
            type: EMPLOYEE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        // toast.error(e.response.data.message)

        yield put({
            type: EMPLOYEE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleEmployeeEdit(action) {
    try {
        const { id, formData, page, limit, search } = action.payload;

        const { data } = yield call(Api.updateEmployee, id, formData, page, limit, search);
        toast.success('Employee is successfully edited')

        yield put({
            type: EMPLOYEE_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: EMPLOYEE_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleEmployeeCreate(action) {
    try {
        const {form, page, limit, search} = action.payload;
        const { data } = yield call(Api.createEmployee,form, page, limit, search);
        toast.success('Employee is successfully created')
        yield put({
            type: EMPLOYEE_CREATE_SUCCESS,
            payload: {data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: EMPLOYEE_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleEmployeeDelete(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(Api.deleteEmployee,id);
        toast.success('Employee is successfully deleted')
        yield put({
            type: EMPLOYEE_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: EMPLOYEE_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(EMPLOYEE_REQUEST, handleEmployee);
    yield takeLatest(EMPLOYEE_CREATE_REQUEST, handleEmployeeCreate);
    yield takeLatest(EMPLOYEE_EDIT_REQUEST, handleEmployeeEdit);
    yield takeLatest(EMPLOYEE_DELETE_REQUEST, handleEmployeeDelete);
}

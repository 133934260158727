import React, {useCallback, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useDispatch, useSelector} from "react-redux";
import Api from "../Api";
import Cookies from 'js-cookie';
import {profileUserRequest} from "../store/actions/users";


export default function LoginAsEmployee({setOpen,open}) {
    const [user,setUser] = useState({})
    const dispatch = useDispatch()
    const employeeData = useSelector((state) => state.employee.employeeData)
    const profileData = useSelector((state) => state.users.userData)


    useEffect(() => {
        setUser(employeeData.employees.find((e) => +e.id === +open))
    }, [open,employeeData])
    const handleId = useCallback(async () => {

        Cookies.set('viewer', user.user.id)
        Cookies.set('original', profileData.id)
        Cookies.set('key', profileData.uid)
        const {data} = await Api.loginAsUser(user.user.id,profileData.id,profileData.uid )
        localStorage.setItem('access_token',data.access_token)
        localStorage.setItem('refresh_token',data.refresh_token)
        await dispatch(profileUserRequest())
        setOpen(0)
    }, [user])
    const handleClose = useCallback(() => {
        setOpen(0);
    },[]);

    return (
        <div>
            <Dialog
                open={!!open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"View as employee"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure that you want to login as {user.first_name} {user.last_name}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleId} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

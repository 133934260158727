import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {FileUploader} from "react-drag-drop-files";
import {toast} from "react-toastify";
import _ from 'lodash'
import StarIcon from '@mui/icons-material/Star';
import PersonIcon from '@mui/icons-material/Person';
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-number-input";
import classNames from "classnames";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {editUserRequest} from "../store/actions/users";
import {REACT_APP_API_URL} from '../config';

function Profile(props) {
    const [form, setForm] = useState({
        first_name: 'Name',
        last_name: 'Sirname',
        sip_operator: '',
        image: {},
        phone: '+37495067515',
        email: 'example@gmail.com',
        birthday: new Date(),
        is_admin: 0,
        status: 1,
        role:'',
        password:''
    })
    const [overlay, setOverlay] = useState(false)
    const [readOnly, setReadOnly] = useState(true)
    const userData = useSelector((state) => state.users.userData)
    const dispatch = useDispatch()
    const fileTypes = ["JPEG", "PNG", "GIF", "JPG"];

    const handleChangeFile = useCallback((ev) => {
        ev._src = URL.createObjectURL(ev);
        if (!ev.type.startsWith('image/')) {
            toast.error('Your file should be image');
            return;
        }
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
            ev._src = e.target.result;
        };
        fileReader.readAsDataURL(ev);
        form.image = ev
        setForm({...form})
        setOverlay(true)
    }, [form])

    useEffect(() => {

        setForm(userData)

    }, [userData])

    const handleChange = useCallback((key, value) => {
        setForm((prev) => ({
            ...prev,
            [key]: value,
        }));
    }, []);

    useEffect(() => {
        if (!_.isEmpty(form.image)) {
            setOverlay(true)
        }
    }, [form])

    const handleSubmit = useCallback(async() => {
        if (!readOnly) {
            await dispatch(editUserRequest(form))
        }
        setReadOnly(!readOnly)

    }, [readOnly, form])


    return (
        <>
            <Helmet>
                <title>Profile</title>
            </Helmet>
                <div style={{padding:'80px 40px 40px'}} className="profileBlock row">
                    <div className="profileLeft col-lg-4">
                        <figure className="profilePictureBlock">
                            {overlay && !readOnly ? <span onClick={() => {
                                setOverlay(false)
                                handleChange('image', {})
                            }} className="close_add">X</span> : null}
                            <FileUploader
                                disabled={readOnly}
                                classNama="hello"
                                handleChange={handleChangeFile}
                                name="file"
                                types={fileTypes}
                            />
                            <img
                                src={!_.isEmpty(form) ? form.image?._src || REACT_APP_API_URL+form.image.path : ''}
                                alt=""
                                style={!overlay ? {zIndex: -77777} : {}}
                                className="blockImg"/>
                        </figure>
                    </div>
                    <div className="profileRight col-lg-8">
                        <div>
                            <div className={classNames("name_loc d-flex flex-row flex-nowrap", {color: !readOnly})}>
                                <h4 style={{margin: '10px 0'}} className="col-lg-5 d-flex">
                                    <input
                                        readOnly={readOnly}
                                        onChange={(ev) => handleChange('first_name', ev.target.value)}
                                        value={form.first_name}/>
                                    <input
                                        readOnly={readOnly}
                                        onChange={(ev) => {
                                            handleChange('last_name', ev.target.value)
                                        }}
                                        value={form.last_name}/>
                                </h4>
                                <div className="d-flex flex-nowrap">
                                    <button
                                        style={{fontSize:13,width:100}}
                                        className="taskBtn"
                                        onClick={handleSubmit}>{readOnly ? 'Edit' : 'Save'}
                                    </button>

                                </div>
                            </div>
                            <span className="role">Role: {form.role === 1?'Moderator':form.role === 2?'Manager':'Super Admin'}</span>
                            <div className="starBlock">
                                <p>Star</p>
                                <p><StarIcon/><StarIcon/><StarIcon/><StarIcon/><StarIcon/><StarIcon/></p>
                            </div>
                        </div>
                        <div className="info">
                            <p className="headline"><PersonIcon/>About</p>
                            <hr/>
                            <span className="headline">Contact information</span>
                            <div className={classNames("d-flex", {color: !readOnly})}>
                                <span className="contact">Phone</span>
                                <PhoneInput
                                    disabled={readOnly}
                                    placeholder="Enter phone number"
                                    value={form.phone}
                                    onChange={(ev) => handleChange('phone', ev)}/>
                            </div>
                            <div className={classNames("d-flex", {color: !readOnly})}>
                                <span className="contact">Sip number(s)</span>
                                <span>{form.sip_operator} </span> {userData?.other_sip_user?.map((u,i) => {
                                if (i !== 0) return (<span key={u.sip}>,{u.sip} </span>)
                            })}
                            </div>
                            <div className={classNames({color: !readOnly})}>
                                <span className="contact">Email</span>
                                <input
                                    onChange={(ev) => handleChange('email', ev.target.value)}
                                    value={form.email}
                                    readOnly/>
                            </div>
                            <span className="headline">Other information</span>
                            <div>
                                <span>Password</span>
                                <input
                                    placeholder="Enter new password"
                                    onChange={(ev) => handleChange('password', ev.target.value)}
                                    value={form.password}
                                    readOnly={readOnly}/>
                            </div>
                            <div className={classNames("d-flex", {color: !readOnly})}>
                                <span className="contact">Birthday</span>
                                <DatePicker
                                    style={{width: 100}}
                                    readOnly={readOnly}
                                    // selected={form.birthday}
                                    onChange={(date) => handleChange('birthday', date)}/>
                            </div>

                        </div>
                    </div>
                </div>
        </>
    );
}

export default Profile;

import * as React from 'react';
import arm from '../assets/img/site/armenia.png'
import ru from '../assets/img/site/russian.png'
import eng from '../assets/img/site/usa.png'
import Popper from '@mui/material/Popper';
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import language from "../assets/img/site/44386.png";
import {useCallback, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {langChange} from "../store/actions/users";

export default function LanguageDrop() {
    const [isDark, setIsDark] = useState(false)
    const dispatch = useDispatch()
    const handleLangChange = useCallback((lang,popupState) => {
        localStorage.setItem('itCrmLang',lang)
        dispatch(langChange(+lang))
        popupState.close();
    }, [])
    return (
        <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
            {(popupState) => (
                <div className="d-flex align-items-center justify-content-center">
                    <div style={{cursor: 'pointer'}} className="d-flex align-items-center" {...bindToggle(popupState)}>
                        <figure style={{ width:30, height: 30,margin: "0 20px"}}>
                            <img className="headerProfileImg" src={language} alt=''/>
                        </figure>
                    </div>
                    <Popper style={{width:190}} className="language__down" {...bindPopper(popupState)} transition>
                        {({TransitionProps}) => (
                            <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                <Paper className="dropTheme">
                                    <ul className="profilePopUp">
                                        <li className="popLi" onClick={() => handleLangChange(0,popupState)}>
                                            <figure style={{margin: '0 10px', width: 30, height: 30, borderRadius: '100%'}}>
                                                <img className="headerProfileImg" src={arm} alt=""/>
                                            </figure>
                                            <span>
                                                Armenian
                                            </span>
                                        </li>
                                        <li className="popLi" onClick={() => handleLangChange(1,popupState)} >
                                            <figur
                                                style={{margin: '0 10px', width: 30, height: 30, borderRadius: '100%'}}>
                                                <img className="headerProfileImg" src={ru} alt=""/>
                                            </figur>
                                            <span>
                                                Russian
                                            </span>
                                        </li>
                                        <li className="popLi" onClick={() => handleLangChange(2,popupState)}>
                                            <figure
                                                style={{margin: '0 10px', width: 30, height: 30, borderRadius: '100%'}}>
                                                <img className="headerProfileImg" src={eng} alt=""/>
                                            </figure>
                                            <span>
                                               English
                                            </span>
                                        </li>
                                    </ul>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </div>
            )}
        </PopupState>
    );
}

import { takeLatest, call, put } from 'redux-saga/effects';
import {
    COMPANY_DELETE_SUCCESS,
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_FAIL,
    COMPANY_EDIT_FAIL,
    COMPANY_EDIT_REQUEST,
    COMPANY_EDIT_SUCCESS,
    COMPANY_REQUEST,
    COMPANY_SUCCESS,
    COMPANY_FAIL,
    COMPANY_CREATE_SUCCESS,
    COMPANY_CREATE_FAIL,
    COMPANY_CREATE_REQUEST,
    COMPANY_SINGLE_REQUEST,
    COMPANY_SINGLE_SUCCESS,
    COMPANY_SINGLE_FAIL,
    COMMENT_CREATE_SUCCESS,
    COMMENT_CREATE_FAIL,
    COMMENT_CREATE_REQUEST,
    COMMENT_DELETE_SUCCESS,
    COMMENT_DELETE_FAIL,
    COMMENT_DELETE_REQUEST,
    COMPANY_EXEL_CREATE_SUCCESS,
    COMPANY_EXEL_CREATE_FAIL, COMPANY_EXEL_CREATE_REQUEST
} from '../actions/company';
import Api from '../../Api';
import {toast} from "react-toastify";

function* handleCompany(action) {
    try {
        const {rowsPerPage,page,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,} = action.payload
        const { data } = yield call(Api.getCompany, rowsPerPage,page,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,);
        yield put({
            type: COMPANY_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        // toast.error(e.response.data.message)
        yield put({
            type: COMPANY_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCompanySingle(action) {
    try {
        const {phone} = action.payload
        const { data } = yield call(Api.getCompanySingle, phone);
        yield put({
            type: COMPANY_SINGLE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        // toast.error(e.response.data.message)
        yield put({
            type: COMPANY_SINGLE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCompanyEdit(action) {
    try {
        const { id, formData, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked, } = action.payload;
        const { data } = yield call(Api.updateCompany,id, formData, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,);
        toast.success('Company is successfully edited')

        yield put({
            type: COMPANY_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMPANY_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCompanyCreate(action) {
    try {
        const {form, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,} = action.payload;
        const { data } = yield call(Api.createCompany,form, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,);
        toast.success('Company is successfully created')
        yield put({
            type: COMPANY_CREATE_SUCCESS,
            payload: {data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMPANY_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCompanyCreateExel(action) {
    try {
        const {exel, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,} = action.payload;
        const { data } = yield call(Api.createExel,exel, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,);
        toast.success('Company is successfully created')
        yield put({
            type: COMPANY_EXEL_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMPANY_EXEL_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCommentCreate(action) {
    try {
        const {id,arr} = action.payload;
        const { data } = yield call(Api.createComment,id,arr);
        toast.success('Comment is successfully created')
        yield put({
            type: COMMENT_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMMENT_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleCompanyDelete(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(Api.deleteCompany,id);
        toast.success('Company is successfully deleted')
        yield put({
            type: COMPANY_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMPANY_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleCommentDelete(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(Api.deleteComment,id);
        toast.success('Comment is successfully deleted')
        yield put({
            type: COMMENT_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMMENT_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(COMPANY_REQUEST, handleCompany);
    yield takeLatest(COMPANY_CREATE_REQUEST, handleCompanyCreate);
    yield takeLatest(COMPANY_EXEL_CREATE_REQUEST, handleCompanyCreateExel);
    yield takeLatest(COMMENT_CREATE_REQUEST, handleCommentCreate);
    yield takeLatest(COMPANY_EDIT_REQUEST, handleCompanyEdit);
    yield takeLatest(COMPANY_DELETE_REQUEST, handleCompanyDelete);
    yield takeLatest(COMMENT_DELETE_REQUEST, handleCommentDelete);
    yield takeLatest(COMPANY_SINGLE_REQUEST, handleCompanySingle);
}

import React, {cloneElement, useCallback, useEffect, useRef, useState} from 'react';
import AdminHeader from './AdminHeader';
import classNames from "classnames";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {profileUserRequest} from "../store/actions/users";
import Api from "../Api";
import CallScreenIn from "./CallScreenIn";
// import {Registerer, UserAgent} from "sip.js";
import { UA } from 'jssip';
import JsSIP from "jssip";
import {Registerer, RegistererState, UserAgent} from "sip.js";
import CallScreenOut from "./CallScreenOut";
import CallIcon from "@mui/icons-material/Call";
import Dial from "./Dial";
import {handleNumberChange} from "../pages/CallSingle";

import { REACT_APP_API_URL } from '../config';
function AdminWrapper({ children,incomeCall, main,call,session,callout ,setIncomeCall,callNumber,ua,income,setIncome,sessionIn}) {
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const userStatus = useSelector((state) => state.users.userStatus);
    const [caller, setCall] = useState(false)
    const [wrapCall, setWrapCall] = useState(false)
    const [outCaller, setOutCaller] = useState(false)
    let inviter = useRef(null)

    const audio = useRef(null)
    const [callOut, setCallOut] = useState(false)

    const [toWho, setToWho] = useState('')

    useEffect( () => {
        if(!localStorage.getItem('access_token') && (!userStatus || userStatus === 'fail')){
            navigate('/login')
        }
    },[userStatus])

    useEffect(() => {
        (async () => {
            await dispatch(profileUserRequest())
        })()
    }, [])


    useEffect(() =>{
        setCall(call)
        if(income){
            document.getElementById('callMusicId').load()
            document.getElementById('callMusicId').play()
        }else{
            if(audio.current){
                // audio.current.pause()

            }
        }
            setOutCaller(callout)
        // const uri = UserAgent.makeURI("sip:3122414@10.223.1.201:8934");
        // const transportOptions = {
        //     server: "ws://usip.ats.am:8934",
        //     traceSip: true,
        // };
        //
        // const userAgentOptions = {
        //     authorizationPassword: 'eJS7t4EB9iZr',
        //     authorizationUsername: '3122414',
        //     transportOptions,
        //     uri
        // };
        // const userAgent = new UserAgent(userAgentOptions);
        // userAgent.start().then((e) => console.log('successs')).catch((e) => console.log(e));
        // console.log(userAgent)

    }, [call,callout,income]);


    return (
        <>
            <Helmet>
                <body className={classNames({dark:localStorage.getItem('theme') === 'dark'})}/>
            </Helmet>
            <div>
                {location.pathname !== '/login'?<AdminHeader main={main}>
                    {children}
                </AdminHeader>:children}
                {/*{caller?<div className="callFromWrapper">*/}
                {/*    <div*/}
                {/*        style={{width:300,*/}
                {/*            right: 0,*/}
                {/*            left: 0,*/}
                {/*            height: 400}} className="dropTheme position-absolute top-0 bottom-0 m-auto">*/}
                {/*        <CallScreenIn audio={audio.current} setCallOut={setOutCaller} session={session} setCall={setCall}/>*/}
                {/*    </div>*/}
                {/*</div>:null}*/}
                {outCaller? <CallScreenOut session={session} setCall={setOutCaller} />
                :null}
                {wrapCall?<>
                    <Dial inviter={inviter} setCallOut={setCallOut} callOut={callOut} toWho={toWho} setToWho={setToWho} ua={ua} />
                    <span className="triangle"/>
                </>:null}
                {location.pathname !== '/call' &&  location.pathname !== '/login'?
                    <span onClick={() => setWrapCall(!wrapCall)} className="wrapper_call">
                    <CallIcon/>
                </span>:null
                }
                {callOut ? <div className="callScreenOutBack">
                    <CallScreenOut incomeCall={incomeCall} caller={toWho || callNumber} callOut={true} newSession={sessionIn} setCall={setCallOut} session={inviter.current}/>
                </div> : null}
                {income ? <div className="callScreenOutBack">
                    <CallScreenIn setCallOut={setCallOut} setIncomeCall={setIncomeCall}  setCall={setIncome} audio={document.getElementById('callMusicId')} caller={callNumber} session={sessionIn}/>
                </div> : null}

                <audio src={REACT_APP_API_URL + '/uploads/mp3audio/ring.mp3'} id="callMusicId"/>

            </div>
        </>
);
}

export default AdminWrapper;

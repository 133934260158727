import React, {useCallback, useEffect, useState} from 'react';
import arm from '../assets/img/site/usa.png'
import PhoneInput from "react-phone-number-input";
import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallScreenOut from "./CallScreenOut";

function CallScreenIn({setCall,setIncomeCall,setCallOut,session,audio,caller}) {
    const [accept, setAccept] = useState()
    const [value, setValue] = useState('+37495067515')
    useEffect(() => {
        console.log(session)
    },[session])
    const handleAnswer = useCallback(async () => {
        console.log(audio)
        if(audio){
            audio.pause()
            session.accept();
            setCallOut(true)
            setIncomeCall(false)
            setCall(false)
        }



    }, [session,audio])

    const handleCancel = useCallback(() => {
        setCall(false)
        session.reject()
        if(audio){
            audio.pause()
        }

    }, [session,audio])
    return (
        <div className="callScreen">
            <div className="d-flex justify-content-center align-items-center flex-column" style={{background:'#adc9ee',height:'80%', borderBottomLeftRadius:10,borderBottomRightRadius:10}}>
                <figure className="call-animation">
                    <img className="img" src={arm} alt=""/>
                </figure>
                <div className="d-flex flex-column align-items-center">
                    <b>{caller}</b>
                    <PhoneInput
                        style={{textAlign:'center', background:'transparent'}}
                        className="phone_input"
                        value={caller}
                        disabled
                        onChange={()=>setValue(value)}
                    />
                    <span>calltype</span>
                </div>

            </div>
            <div style={{height:'20%'}} className="d-flex align-items-center justify-content-around">
                <span className="callBTN" onClick={handleAnswer}><CallIcon/> Answer</span>
                <span className="callBTN red" onClick={handleCancel}><CallEndIcon/> Cancel</span>
            </div>
        </div>
    );
}

export default CallScreenIn;

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export function loginUserRequest({username, password}) {
  return {
    type: LOGIN_USER_REQUEST,
    payload: { username, password },
  };
}


export const SIP_REQUEST = 'SIP_REQUEST';
export const SIP_SUCCESS = 'SIP_SUCCESS';
export const SIP_FAIL = 'SIP_FAIL';

export function sipRequest() {
  return {
    type: SIP_REQUEST,
    payload: {  },
  };
}


export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';
export function logoutUserRequest() {
  return {
    type: LOGOUT_USER_REQUEST,
    payload: {},
  };
}
export const PROFILE_USER_REQUEST = 'PROFILE_USER_REQUEST';
export const PROFILE_USER_SUCCESS = 'PROFILE_USER_SUCCESS';
export const PROFILE_USER_FAIL = 'PROFILE_USER_FAIL';

export function profileUserRequest() {
  return {
    type: PROFILE_USER_REQUEST,
    payload: {},
  };
}

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export function registerUserRequest(form) {
  return {
    type: REGISTER_USER_REQUEST,
    payload: {form},
  };
}

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAIL = 'EDIT_USER_FAIL';

export function editUserRequest(form) {
  return {
    type: EDIT_USER_REQUEST,
    payload: {form},
  };
}

export const LANGUAGE = 'LANGUAGE'
export function langChange(lang) {
  return {
    type: LANGUAGE,
    payload: {lang},
  };
}



import { takeLatest, call, put } from 'redux-saga/effects';
import {
    TASK_DELETE_SUCCESS,
    TASK_DELETE_REQUEST,
    TASK_DELETE_FAIL,
    TASK_EDIT_FAIL,
    TASK_EDIT_REQUEST,
    TASK_EDIT_SUCCESS,
    TASK_REQUEST,
    TASK_SUCCESS,
    TASK_FAIL,
    TASK_CREATE_SUCCESS,
    TASK_CREATE_FAIL,
    TASK_CREATE_REQUEST,
    TASK_ALL_REQUEST,
    TASK_ALL_SUCCESS,
    TASK_ALL_FAIL
} from '../actions/task';
import Api from '../../Api';
import {toast} from "react-toastify";

function* handleTask(action) {
    try {
        const {rowsPerPage,page,search,filter} = action.payload
        const { data } = yield call(Api.getTask, rowsPerPage,page,search,filter);
        yield put({
            type: TASK_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: TASK_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleTaskEdit(action) {
    try {
        const { id, formData, page, limit, search,filter } = action.payload;
        const { data } = yield call(Api.updateTask,id, formData, page, limit, search,filter);
        toast.success('Task is successfully edited')

        yield put({
            type: TASK_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: TASK_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleTaskCreate(action) {
    try {
        const {form, page, limit, search,filter,process_id} = action.payload;
        const { data } = yield call(Api.createTask,form, page, limit, search,filter,process_id);
        toast.success('Task is successfully created')
        yield put({
            type: TASK_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: TASK_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleTaskAll(action) {
    try {
        const {form, page, limit, search, actions,filter} = action.payload;
        const { data } = yield call(Api.allTask,form, page, limit, search,actions,filter);
        toast.success('All tasks are successfully updated')
        yield put({
            type: TASK_ALL_SUCCESS,
            payload: {data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: TASK_ALL_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleTaskDelete(action) {
    try {
        const { id, responsible,filter,page,limit, } = action.payload;
        const { data } = yield call(Api.deleteTask,id,responsible,filter,page,limit,);
        toast.success('Task is successfully deleted')
        yield put({
            type: TASK_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: TASK_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(TASK_REQUEST, handleTask);
    yield takeLatest(TASK_ALL_REQUEST, handleTaskAll);
    yield takeLatest(TASK_CREATE_REQUEST, handleTaskCreate);
    yield takeLatest(TASK_EDIT_REQUEST, handleTaskEdit);
    yield takeLatest(TASK_DELETE_REQUEST, handleTaskDelete);
}

export const top = {
    sip:['Ձեր sip համարը','Ваш сип-номер','Your sip number'],
    profile:['Պրոֆիլ','Профиль','Profile'],
    logout:['Դուրս գալ','Выйти','Logout'],
}
export const history = {
    title:['Պատմություն','История','History'],
    start:['Մեկնարկի ժամանակը','Время начала','Start time'],
    end:['Ավարտի ժամանակը','Время окончания','End time'],
    dis:['կարգավիճ','Disposition','Disposition'],
    state:['Զանգի տեսակ','Статус','Status'],
    sel1:['Ընտրեք Զանգի տեսակ','Выберите статус','Select Status'],
    sel2:['Ընտրեք կարգավիճ','Выберите статус','Select Disposition'],
    week:['Շաբաթ','Неделя','Week'],
    month:['Ամիս','Месяц','Month'],
    day:['Օր','День','Day'],
}

export const global = {
    phone:['Հեռ. Համար','Номер','Phone'],
    view:['Դիտել պատմությունը','Посмотреть историю','View history'],
    profile:['Պրոֆիլ','Профиль','Profile'],
    search:['Որոնել','Поиск','Search'],
    status:['Կարգավիճ','Статус','Status'],
    created:['Ստեղծվել է','Создан в','Created at'],
    title:['Վերնագիր','Название','Title'],
    desc:['Նկարագրութ.','Описание','Description'],
    add:['Ավելացնել','Добавлять','Add'],
    upload:['Վերբեռնել','Загрузить','Upload'],
    edit:['Պահպանել փոփոխությունները','Сохранить изменения','Save changes'],
    select:['Ընտրել','Выбирать','Select'],
    enter:['Մուտքագրեք','Введите','Enter'],
    date:['Ամսաթիվ','Дата','Date'],
    isTest:['Թեստ','Тест','Test'],
    isBlocked:['Արգելափակված','Заблокировано','Blocked'],

}
export const company = {
    title:['Ընկերություն','Компания','Company'],
    profile:['Պրոֆիլ','Профиль','Profile'],
    search:['Որոնել ըստ համարի','Поиск по номеру','Search by number'],
    search2:['Որոնում ըստ մենեջերի','Поиск по менеджеру','Search by manager'],
    status:['Կարգավիճ','Статус','Status'],
    name:['Անվանում','Название','Title'],
    desc:['Նկարագրություն','Описание','Description'],
    dep:['բաժին','Отделение','Department'],
    manager:['Մենեջեր','Менеджер','Manager'],
    comment:['Մեկնաբանություն','Комментарий','Comment'],
    reminder:['Հարցում','Запрос','Request'],
}

export const employee = {
    title:['Աշխատակիցներ','Сотрудники','Employees'],
    profile:['Պրոֆիլ','Фильтры','Profile'],
    search:['Որոնել ըստ համարի','Поиск по номеру','Search by number'],
    search2:['Որոնում ըստ մենեջերի','Поиск по менеджеру','Search by manager'],
    status:['Կարգավիճ','Статус','Status'],
    name:['Անվանում','Название','Title'],
    desc:['Նկարագրություն','Описание','Description'],
    dep:['բաժին','Отделение','Department'],
    manager:['Մենեջեր','Менеджер','Manager'],
    comment:['Մեկնաբանություն','Комментарий','Comment'],
}

export const phones = {
    title:['Հեռախոսներ','Телефоны','Phones'],
    profile:['Պրոֆիլ','Профиль','Profile'],
    search:['Որոնել ըստ համարի','Поиск по номеру','Search by number'],
    search2:['Որոնում ըստ մենեջերի','Поиск по менеджеру','Search by manager'],
    status:['Կարգավիճ','Статус','Status'],
    name:['Անվանում','Название','Title'],
    lName:['Նկարագրություն','Описание','Description'],
    dep:['բաժին','Отделение','Department'],
    manager:['Մենեջեր','Менеджер','Manager'],
    comment:['Մեկնաբանություն','Комментарий','Comment'],
}

export const companyPrice = {
    OP:['Կարճ ենթահամարներ','Короткие номера','Short numbers',],
    IVR:['Ձայնային մենյու (IVR)','Голосовое меню (IVR)','Voice Menu (IVR)',],
    REG:['Գծերի քանակ','Количество Линии','Count Lines',],
    REC:['Զանգերի ձայնագ․','Запись разговоров','Call recording',],
    PHONE:['Ներառված հեռ.','Внутренние моб. номера','Included mob. numbers',],
    MOB:['Կարճ ենթահամարներ','Короткие номера','Short numbers',],
    SIP:['SIP համարներ','SIP Номера','SIP Numbers',],
    CRM:['Ինտեգրացիա','Интеграция','Integration',],
    RECDAY:['Զանգերի ձայնագ․','Запись разговоров','Call recording',],
}
export const tasks = {
    title:['Առաջադրանք','Задача','Tasks'],
    created:['Ստեղծել է','Автор','Created by'],
    call:['Զանգ','Вызов','Call'],
    negotiation:['Քննարկում','Обсуждение','Discussion'],
    meeting:['Հանդիպում','Встреча','Meeting'],
    decision:['Անհաջող զանգեր','Неуспешные звонки','Unsuccessful calls'],
    end:['Ավարտ','Конец','End'],
    type:['Առաջադրանքի տեսակը','Тип задачи','Task type'],
    typeT:['Առաջադրանքի վերնագիր','Название задачи','Task title'],
    desc:['Առաջադրանքի նկարագրություն','Описание задания','Task description'],
    res:['Արդյունք','Результат','Result'],
    response:['Պատասխանատու','Ответственный','Responsible'],
    deadline:['Վերջնաժամկետ','Крайний срок','Deadline'],
    deadlineD:['SMS մինչև վերջնաժամկետը','СМС до истечения срока','SMS before deadline'],

}



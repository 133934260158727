import React, {useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Loader from "./Loader";
import {useDispatch, useSelector} from "react-redux";
import {companySingleRequest} from "../store/actions/company";
import {clientSingleRequest} from "../store/actions/clients";
import Company from "../pages/Company";
import CompanyCreateAndEdit from "./CompanyCreateAndEdit";
import ClientCreateAndEdit from "./ClientCreateAndEdit";

import { REACT_APP_API_URL } from '../config';
function Details({detail, setDetail}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 610?window.innerWidth - 60 : 650,
        height:'fit-content',
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 4,
    };
    const dispatch = useDispatch()
    const client = useSelector((state) => state.client.clientSingleData)
    const clientStatus = useSelector((state) => state.client.clientSingleStatus)
    const company = useSelector((state) => state.company.companySingleData)
    const companyStatus = useSelector((state) => state.company.companySingleStatus)
    const [data, setData] = useState({})
    const [createComp, setCreateComp] = useState(0)
    const [createClient, setCreateClient] = useState(0)

    useEffect(() => {
        (async () => {
            await dispatch(companySingleRequest(detail.hisId))
            await dispatch(clientSingleRequest(detail.hisId))
        })()
    }, [detail])

    useEffect(() => {
        setData({
            client,
            company
        })
    }, [client,company])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!detail.hisId }
                onClose={() => setDetail({hisId:0,record:''})}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade
                    in={!!detail.hisId}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h5" component="h2">
                            <span style={{marginBottom:30}} className="d-flex justify-content-between">
                                <span>Detail</span>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => setDetail({hisId:0,record:''})}>x</span>
                            </span>
                        </Typography>
                        {clientStatus === 'request' && companyStatus==='request'?<Loader/>:<div className="d-flex flex-row">
                            <div className="row w-100">
                                <div style={{marginBottom:25}} className="col-md-6">
                                    <h5 style={{marginBottom:20}}>Company</h5>
                                    {!_.isEmpty(data.company)?(
                                        <div className="company_detail">
                                            <p><b>Title:</b> {data.company.title}</p>
                                            <p><b>Description:</b> {data.company.description}</p>
                                            <p><b>Phone number:</b> {data.company.phone}</p>
                                            <p><b>Manager:</b> {data.company.manager.user.email}</p>
                                        </div>
                                    ):<button onClick={() => setCreateComp(detail.hisId)} className="company_create">Create Company</button>
                                    }
                                </div>
                                <div style={{marginBottom:25}} className="col-md-6">
                                    <h5 style={{marginBottom:20}}>Client</h5>
                                    {!_.isEmpty(data.client)?(
                                        <div className="company_detail">
                                            <p><b>First name:</b> {data.client.first_name}</p>
                                            <p><b>Last name:</b>{data.client.last_name}</p>
                                            <p><b>Phone number:</b> {data.client.phone}</p>
                                            <p><b>Comment:</b> {data.client.comment}</p>
                                        </div>
                                    ):<button onClick={() => setCreateClient(detail.hisId)} className="company_create">Create Client</button>
                                    }
                                </div>
                                {detail.record?<div className="audioBlock"><span>Audio</span> <audio className="d-inline" controls src={REACT_APP_API_URL + detail.record}/></div>
                                :null}
                                {createComp?<CompanyCreateAndEdit setCreate={setCreateComp} create={createComp} phone={createComp}/>:null}
                                {createClient?<ClientCreateAndEdit setCreate={setCreateClient} create={createClient} phone={createClient}/>:null}
                            </div>
                        </div>}
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default Details;

export const PROCESS_REQUEST = 'PROCESS_REQUEST';
export const PROCESS_SUCCESS = 'PROCESS_SUCCESS';
export const PROCESS_FAIL = 'PROCESS_FAIL';

export function processRequest(rowsPerPage,page,search) {
    return {
        type: PROCESS_REQUEST,
        payload: {rowsPerPage,page,search },
    };
}

export const PROCESS_SINGLE_REQUEST = 'PROCESS_SINGLE_REQUEST';
export const PROCESS_SINGLE_SUCCESS = 'PROCESS_SINGLE_SUCCESS';
export const PROCESS_SINGLE_FAIL = 'PROCESS_SINGLE_FAIL';

export function processSingleRequest(id) {
    return {
        type: PROCESS_SINGLE_REQUEST,
        payload: {id},
    };
}
export const PROCESS_EDIT_REQUEST = 'PROCESS_EDIT_REQUEST';
export const PROCESS_EDIT_SUCCESS = 'PROCESS_EDIT_SUCCESS';
export const PROCESS_EDIT_FAIL = 'PROCESS_EDIT_FAIL';

export function processEditRequest(id, formData, page, limit, search) {
    return {
        type: PROCESS_EDIT_REQUEST,
        payload: {id, formData, page,limit,search},
    };
}

export const PROCESS_DELETE_REQUEST = 'PROCESS_DELETE_REQUEST';
export const PROCESS_DELETE_SUCCESS = 'PROCESS_DELETE_SUCCESS';
export const PROCESS_DELETE_FAIL = 'PROCESS_DELETE_FAIL';

export function processDeleteRequest(id) {
    return {
        type: PROCESS_DELETE_REQUEST,
        payload: {id},
    };
}
export const PROCESS_CREATE_REQUEST = 'PROCESS_CREATE_REQUEST';
export const PROCESS_CREATE_SUCCESS = 'PROCESS_CREATE_SUCCESS';
export const PROCESS_CREATE_FAIL = 'PROCESS_CREATE_FAIL';

export function processCreateRequest(form, page, limit, search) {
    return {
        type: PROCESS_CREATE_REQUEST,
        payload: {form,page,limit,search},
    };
}




export const COMMENT_PROCESS_CREATE_REQUEST = 'COMMENT_PROCESS_CREATE_REQUEST';
export const COMMENT_PROCESS_CREATE_SUCCESS = 'COMMENT_PROCESS_CREATE_SUCCESS';
export const COMMENT_PROCESS_CREATE_FAIL = 'COMMENT_PROCESS_CREATE_FAIL';

export function commentPCreateRequest(id,arr) {
    return {
        type: COMMENT_PROCESS_CREATE_REQUEST,
        payload: {id,arr},
    };
}
export const COMMENT_PROCESS_DELETE_REQUEST = 'COMMENT_PROCESS_DELETE_REQUEST';
export const COMMENT_PROCESS_DELETE_SUCCESS = 'COMMENT_PROCESS_DELETE_SUCCESS';
export const COMMENT_PROCESS_DELETE_FAIL = 'COMMENT_PROCESS_DELETE_FAIL';

export function commentPDeleteRequest(id) {
    return {
        type: COMMENT_PROCESS_DELETE_REQUEST,
        payload: {id},
    };
}

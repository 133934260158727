import {tasks} from "../translate";

class Utils {
    static convertHMS(value) {
        let time;
        const sec = parseInt(value, 10);
        let hours = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60);
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
        time = hours + ':' + minutes + ':' + seconds;
        return time
    }

    static tomorrow() {
        let d = new Date();
        d.setDate(d.getDate() + 1);
        let year = d.getFullYear()
        let month = String(d.getMonth() + 1)
        let day = String(d.getDate())
        month = month.length === 1 ?
            month.padStart('2', '0') : month;
        day = day.length === 1 ?
            day.padStart('2', '0') : day;
        return `${year}-${month}-${day}`
    }

    static tomorrowTomorrow() {
        let d = new Date();
        d.setDate(d.getDate() + 2);
        let year = d.getFullYear()
        let month = String(d.getMonth() + 1)
        let day = String(d.getDate())
        month = month.length === 1 ?
            month.padStart('2', '0') : month;
        day = day.length === 1 ?
            day.padStart('2', '0') : day;
        return `${year}-${month}-${day}`
    }

    static nextMonth() {
        let d = new Date();
        let year = d.getFullYear()
        let month = String(d.getMonth() + 2)
        let day = String(d.getDate())
        month = month.length === 1 ?
            month.padStart('2', '0') : month;
        day = day.length === 1 ?
            day.padStart('2', '0') : day;
        return `${year}-${month}-${day}`
    }

    static yesterday() {
        let d = new Date();
        d.setDate(d.getDate() - 1);
        let year = d.getFullYear()
        let month = String(d.getMonth() + 1)
        let day = String(d.getDate())
        month = month.length === 1 ?
            month.padStart('2', '0') : month;
        day = day.length === 1 ?
            day.padStart('2', '0') : day;
        return `${year}-${month}-${day}`
    }

    static TimestampToDate(datetime) {
        return (datetime.toString().split(' ')[0])

    }

    static dateToTimestamp(datetime) {
        const date = datetime.split('T')[0]
        const time = datetime.split('T')[1].split('.')[0]
        return date + ' ' + time

    }

    static toISOLocal(d) {
        const z = n => ('0' + n).slice(-2);
        const zz = n => ('00' + n).slice(-3);
        let off = d.getTimezoneOffset();
        const sign = off > 0 ? '-' : '+';
        off = Math.abs(off);
        return (d.getFullYear() + '-'
            + z(d.getMonth() + 1) + '-' +
            z(d.getDate()) + 'T' +
            z(d.getHours()) + ':' +
            z(d.getMinutes()) + ':' +
            z(d.getSeconds()) + '.' +
            zz(d.getMilliseconds()));

    }

    static sortTasks(task) {
        const today = new Date()
        const tomorrow = new Date(today)
        let result = [
            {id: 1, content: 'Expired tasks', class: 'old', items: []},
            {id: 2, content: 'Tasks for today', class: 'today', items: []},
            {id: 3, content: 'Tasks for future', class: 'new', items: []}
        ]
        if (task && task.task) {
            task?.task?.map((t) => {
                if (new Date(t.deadline) < today) {
                    result[0].items.push(t)
                } else if (new Date(Utils.yesterday()) < new Date(t.deadline) && new Date(Utils.tomorrow()) > new Date(t.deadline)) {
                    result[1].items.push(t)
                } else if (new Date(t.deadline) > today) {
                    result[2].items.push(t)
                }
            })
        }
        return result
    }

    static sortProcess(process) {

        let result = [
            {id: 1, content: tasks.call[+localStorage.getItem('itCrmLang') || 0], class: 'today', items: []},
            {id: 2, content: tasks.decision[+localStorage.getItem('itCrmLang') || 0], class: 'today', items: []},
            {id: 3, content: tasks.negotiation[+localStorage.getItem('itCrmLang') || 0], class: 'today', items: []},
            {id: 4, content: tasks.meeting[+localStorage.getItem('itCrmLang') || 0], class: 'today', items: []},
            {id: 5, content: tasks.end[+localStorage.getItem('itCrmLang') || 0], class: 'today', items: []},
            // {id: 6, content: 'Not realised', class: 'today', items: []},
        ]
        if (process && process.process) {
            process?.process?.map((t) => {
                if (t.status === 1) {
                    result[0].items.push(t)
                } else if (t.status === 2) {
                    result[1].items.push(t)
                } else if (t.status === 3) {
                    result[2].items.push(t)
                } else if (t.status === 4) {
                    result[3].items.push(t)
                } else if (t.status === 5) {
                    result[4].items.push(t)
                }
            })
        }


        return result
    }

    static sortCompany(company, by) {

        let result = []
        if (by === 'phone') {
            if (company && company.company) {
                company?.company?.map((t) => {
                    result.push({value: t.phone, label: t.title})
                })
            }
        } else {
            if (company && company.company) {
                company?.company?.map((t) => {
                    result.push({value: t.id, label: t.title})
                })
            }
        }


        return result
    }

    static sortSip(sip) {
        let result = []
        sip.map((s) => {
            result.push({
                value: s.buddy,
                label: s.buddy + ' ' + s.notes,
            })
        })

        return result
    }

    static sortProcessStatus(process) {
        let result = []

        process.map((s) => {
            result.push({
                value: s.id,
                label: s.text,
            })
        })

        return result
    }

}

export default Utils;

export class SoundMeter {
    constructor(sessionId, lineNum) {
        var audioContext = null;
        try {
            audioContext = new AudioContext();
        } catch (e) {
            console.warn("AudioContext() LocalAudio not available... its fine.");
        }
        if (audioContext == null) return null;
        this.context = audioContext;
        this.source = null;

        this.lineNum = lineNum;
        this.sessionId = sessionId;

        this.captureInterval = null;
        this.levelsInterval = null;
        this.networkInterval = null;
        this.startTime = 0;

        this.ReceiveBitRateChart = null;
        this.ReceiveBitRate = [];
        this.ReceivePacketRateChart = null;
        this.ReceivePacketRate = [];
        this.ReceivePacketLossChart = null;
        this.ReceivePacketLoss = [];
        this.ReceiveJitterChart = null;
        this.ReceiveJitter = [];
        this.ReceiveLevelsChart = null;
        this.ReceiveLevels = [];
        this.SendBitRateChart = null;
        this.SendBitRate = [];
        this.SendPacketRateChart = null;
        this.SendPacketRate = [];

        this.instant = 0; // Primary Output indicator

        this.AnalyserNode = this.context.createAnalyser();
        this.AnalyserNode.minDecibels = -90;
        this.AnalyserNode.maxDecibels = -10;
        this.AnalyserNode.smoothingTimeConstant = 0.85;
    }

    connectToSource(stream, callback) {
        console.log("SoundMeter connecting...");
        try {
            this.source = this.context.createMediaStreamSource(stream);
            this.source.connect(this.AnalyserNode);
            // this.AnalyserNode.connect(this.context.destination); // Can be left unconnected
            this._start();

            callback(null);
        } catch (e) {
            console.error(e); // Probably not audio track
            callback(e);
        }
    }

    _start() {
        var self = this;
        self.instant = 0;
        self.AnalyserNode.fftSize = 32; // 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384, and 32768. Defaults to 2048
        self.dataArray = new Uint8Array(self.AnalyserNode.frequencyBinCount);

        this.captureInterval = window.setInterval(function () {
            self.AnalyserNode.getByteFrequencyData(self.dataArray); // Populate array with data from 0-255

            // Just take the maximum value of this data
            self.instant = 0;
            for (var d = 0; d < self.dataArray.length; d++) {
                if (self.dataArray[d] > self.instant) self.instant = self.dataArray[d];
            }

        }, 1);
    }

    stop() {
        console.log("Disconnecting SoundMeter...");
        window.clearInterval(this.captureInterval);
        this.captureInterval = null;
        window.clearInterval(this.levelsInterval);
        this.levelsInterval = null;
        window.clearInterval(this.networkInterval);
        this.networkInterval = null;
        try {
            this.source.disconnect();
        } catch (e) {
        }
        this.source = null;
        try {
            this.AnalyserNode.disconnect();
        } catch (e) {
        }
        this.AnalyserNode = null;
        try {
            this.context.close();
        } catch (e) {
        }
        this.context = null;
    }
}

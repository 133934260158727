import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBarsProgress, faBarsStaggered} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import FilterDrop from "../components/FilterDrop";
import Utils from "../helpers/Utils";
import {list, menu, processList} from "../menu";
import DeleteIcon from "@mui/icons-material/Delete";
import TaskCreate from "../components/TaskCreate";
import {taskDeleteRequest, taskEditRequest, taskRequest} from "../store/actions/task";
import ProcessCreate from "../components/ProcessCreate";
import {processDeleteRequest, processEditRequest, processRequest} from "../store/actions/process";
import ProcessDrop from "../components/ProcessDrop";
import Loader from "../components/Loader";
import _ from "lodash";
import TaskComponent from "../components/TaskComponent";
import {Pagination} from "@mui/material";
import ProcessComponent from "../components/ProcessComponent";
import ProcessDelete from "../components/ProcessDelete";

function ProcessList(props) {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const processData = useSelector(state => state.process.processData)
    const processStatus = useSelector(state => state.process.processStatus)
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [create, setCreate] = useState(false);
    const [isCheckAllAction, setIsCheckAllAction] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleAllCheck = useCallback(() => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map(li => +li.id));
        setIsCheckAllAction(false)

        if (isCheckAll) {
            setIsCheck([]);
        }

    }, [isCheckAll, list])
    const handleCheck = useCallback((ev) => {
        const {id, checked} = ev.target

        setIsCheck([...isCheck, +id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => +item !== +id));
            setIsCheckAll(false)
            setIsCheckAllAction(false)

        } else {
            setIsCheckAllAction(true)
        }


    }, [isCheck])

    useEffect(() => {
        (async () => {
            setRowsPerPage(+query.limit || 10)
            setPage(+query.page || 1);
            setSearch(query.filter + '' || '')
            await dispatch(processRequest(query.limit || 10, query.page || 1, query.search || ''));
        })();
    }, []);
    const handleChangePage = useCallback(async (ev, newPage) => {
        setPage(newPage);
        query.page = newPage;
        await dispatch(processRequest(query.limit || 10, query.page || 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);
    const handleChangeRowsPerPage = useCallback(async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
        query.limit = event.target.value;
        query.page = '1';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(processRequest(query.limit || 10, query.page || 1, query.search || ''));

    }, [location.search]);
    const handleSearch = useCallback(async (val) => {
        query.page = '1'
        setPage(1)
        if (query.filter && query.filter === val) {
            query.filter = ''
            setSearch('')
        }else{
            query.filter = val
            setSearch(val)
        }

        await dispatch(processRequest(query.limit || 10, query.page || 1, query.search || ''));

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    useEffect(() => {
        setList(processData?.process || [])
    }, [processData])
    return (
        <>
            <Helmet>
                <title>{menu[7].title[+lang]}</title>
            </Helmet>

                <header className="taskFilter">
                    <div className="menuLink">
                        <NavLink to={`/process/line`}>
                            <FontAwesomeIcon icon={faBarsProgress}/>
                        </NavLink>
                        <NavLink to='/process/list'>
                            <FontAwesomeIcon icon={faBarsStaggered}/>
                        </NavLink>
                    </div>
                    <label className="d-flex align-items-center" style={{width: '60%'}}>
                        Filters
                        <ProcessDrop/>
                    </label>
                    <button className="taskBtn" onClick={() => setCreate(true)}>
                        Add Process
                    </button>
                </header>


                <div className="taskTable">
                    {processStatus === 'request' ? (<div className="loaderBack">
                        <Loader/>
                    </div>) : <table>
                        <thead>
                        {!isCheckAll ? (<tr>
                            <th>
                                <input
                                    disabled={_.isEmpty(list)}
                                    checked={isCheckAll}
                                    onChange={handleAllCheck}
                                    type="checkbox"/>
                                Title
                            </th>
                            <th>
                                Created by
                            </th>
                            <th>
                                Company contact
                            </th>
                            <th>
                                Status
                            </th>
                            <th>
                                Budget
                            </th>
                        </tr>) : <TaskComponent id='0' handleAllCheck={handleAllCheck} isCheckAll={isCheckAll}/>}
                        </thead>
                        <tbody>
                        {processData.process?.length ? processData.process.map((t) => {
                            if (!isCheckAllAction || !isCheck.includes(t.id)) {
                                return (<tr key={t.id}>
                                    <td>
                                        <input
                                            id={t.id}
                                            onChange={handleCheck}
                                            value={t.id}
                                            checked={isCheck.includes(t.id)}
                                            type="checkbox"
                                        />
                                        {t.title}

                                    </td>
                                    <td>
                                        {t.process_employee.phone}
                                    </td>
                                    <td>
                                        {t.company.phone}
                                    </td>
                                    <td>
                                        {t.description}
                                    </td>
                                    <td>
                                        {t.budget}
                                    </td>
                                </tr>)
                            } else {
                                return (<ProcessComponent key={t.id} handleAllCheck={handleCheck} setIsCheck={setIsCheck}
                                                       id={t.id} data={t} isCheckAll={isCheck.includes(t.id)}/>)
                            }
                        }) : <tr>
                            <td colSpan="5" align="center">No data</td>
                        </tr>}
                        </tbody>
                        <tfoot style={{borderTop: '1px solid #ccc'}}>
                        <tr>
                            <td align="right" colSpan='3'>
                                <label>
                                    Select row count
                                    <select
                                        value={rowsPerPage} style={{margin: '0 10px'}}
                                        onChange={(ev) => handleChangeRowsPerPage(ev)}
                                    >
                                        <option value="10">10</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </td>
                            <td className="paginationBlock" align="right" colSpan="2">
                                <Pagination count={processData.totalPages} page={+page} onChange={handleChangePage}
                                            variant="outlined" color="primary"/>
                            </td>

                        </tr>
                        </tfoot>

                    </table>}
                </div>
                {create?<ProcessCreate create={create} setCreate={setCreate}/>:null}
        </>
    );
}

export default ProcessList;

import {call, put, takeLatest} from 'redux-saga/effects';
import {
    EDIT_USER_FAIL, EDIT_USER_REQUEST,
    EDIT_USER_SUCCESS,
    LOGIN_USER_FAIL,
    LOGIN_USER_REQUEST,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER_FAIL,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    PROFILE_USER_FAIL,
    PROFILE_USER_REQUEST,
    PROFILE_USER_SUCCESS,
    REGISTER_USER_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS, SIP_FAIL, SIP_REQUEST, SIP_SUCCESS,
} from '../actions/users';
import Api from '../../Api';
import Account from '../../helpers/Account';
import {toast} from "react-toastify";

function* handleAdminLoginRequest(action) {
    try {
        const {username, password} = action.payload;
        const {data} = yield call(Api.login, {username, password});
        Account.setAdminToken(data);
        yield put({
            type: LOGIN_USER_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: LOGIN_USER_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleAdminLogoutRequest(action) {
    try {
        const {data} = yield call(Api.logout);
        Account.logoutAdmin();
        yield put({
            type: LOGOUT_USER_SUCCESS,
            payload: {},
        });
    } catch (e) {

        yield put({
            type: LOGOUT_USER_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleUserEditRequest(action) {
    try {
        const {data} = yield call(Api.edit, action.payload.form);
        yield put({
            type: EDIT_USER_SUCCESS,
            payload: {data},
        });
        toast.success('Your profile is successfully updated')
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: EDIT_USER_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleUserProfileRequest() {
    try {
        const {data} = yield call(Api.profile);
        yield put({
            type: PROFILE_USER_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: PROFILE_USER_FAIL,
            payload: {error: e.response},
        });
    }
}

function* handleSipRequest() {
    try {
        const {data} = yield call(Api.sip);
        yield put({
            type: SIP_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        yield put({
            type: SIP_FAIL,
            payload: {error: e.response},
        });
    }
}


function* handleUserRegisterRequest(action) {
    try {
        const {form} = action.payload;
        yield call(Api.register, form);
        yield put({
            type: REGISTER_USER_SUCCESS,
            payload: {},
        });
        toast.success(form.status === "2" ? 'Manager' + ' is successfully created': 'Moderator' + ' is successfully created')

    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: REGISTER_USER_FAIL,
            payload: {error: e.response},
        });
    }
}


export default function* watcher() {
    yield takeLatest(LOGIN_USER_REQUEST, handleAdminLoginRequest);
    yield takeLatest(PROFILE_USER_REQUEST, handleUserProfileRequest);
    yield takeLatest(REGISTER_USER_REQUEST, handleUserRegisterRequest);
    yield takeLatest(LOGOUT_USER_REQUEST, handleAdminLogoutRequest);
    yield takeLatest(EDIT_USER_REQUEST, handleUserEditRequest);
    yield takeLatest(SIP_REQUEST, handleSipRequest);
}

import React, {useCallback, useState} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useDispatch, useSelector} from "react-redux";
import {taskAllRequest, taskDeleteRequest, taskEditRequest} from "../store/actions/task";
import TaskEdit from "./TaskEdit";
import {useLocation} from "react-router";
import qs from "query-string";
import {processDeleteRequest, processEditRequest, processRequest} from "../store/actions/process";
import ProcessEdit from "./ProcessEdit";
import ProcessDelete from "./ProcessDelete";
import AdminWrapper from "./AdminWrapper";
import TaskCreate from "./TaskCreate";

function ProcessComponent({isCheckAll, handleAllCheck,setIsCheck, id, data}) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [create, setCreate] = useState(false)
    const [isProcess, setIsProcess] = useState(0)
    const [resone, setResone] = useState('')
    const [isAll,setIsAll] = useState(false)
    const profileData = useSelector(state => state.users.userData)

    const [deleteItem, setDeleteItem] = useState(false);
    const location = useLocation();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleCheck = useCallback(async () => {
        if(+id === 0){
            if(query.search === 'done' || query.search === 'deleted' || !query.search){
                // await dispatch(taskAllRequest({},1,10,'','reset'))
            }else{
                // await dispatch(taskAllRequest({},1,10,'','check'))
            }
        }
        else{
            if(query.search === 'done' || query.search === 'delete' || !query.search){
                await dispatch(processEditRequest(data.id, {
                    ...data,
                    status: 1
                },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))

            }
        }

    },[id,data,location.search])

    const handleStatusChange = useCallback(async () => {
        if(+id === 0){
            if(query.search === 'done' || query.search === 'deleted' || !query.search){
                // await dispatch(taskAllRequest({},1,10,'','reset'))
            }else{
                // await dispatch(taskAllRequest({},1,10,'','check'))
            }
        }
        else{
            setResone('status')
            setOpen(true)
        }

    },[id,data,location.search])
    const handleDelete = useCallback(async () => {
        if(+id === 0){
            setDeleteItem(0)
        }else{
           setDeleteItem(id)
        }

    }, [id,location.search])
    const handleEmployeeChange = useCallback(async () => {
        if(+id === 0){
            setIsAll(true)
            setResone('responsible')
            setOpen(true)
        }else{
            setResone('responsible')
            setOpen(true)
        }

    }, [id])

    const handleAddTask = useCallback(async () => {
        if(+id === 0){
            setOpen(true)
            setIsAll(true)
        }else{
            setCreate(true)
            setIsProcess(id)
        }

    }, [id])

    return (
        <tr style={{background: '#d7e5fa'}}>
            <td className="taskAction"  colSpan='42'>
                <span className="p-0">
                    <input
                        id={id}
                        checked={isCheckAll}
                        onChange={handleAllCheck}
                        type="checkbox"/>
                </span>
                {profileData.is_admin?
                    <span onClick={handleEmployeeChange}>
                    <ChangeCircleIcon/>
                    <span>Change the employer</span>
                </span>:null}

                {query.search === 'all' || !query.search?
                    <span onClick={handleDelete}>
                    <DeleteIcon/>
                    <span>Delete</span>
                </span>:null}
                <span onClick={handleAddTask}>
                    <CheckCircleOutlineIcon/>
                    <span>Add task</span>
                </span>
                <span onClick={handleStatusChange}>
                    <ChangeCircleIcon/>
                    <span>Change status</span>
                </span>
                {/*<span onClick={handleDeadlineChange}>*/}
                {/*    <AccessTimeIcon/>*/}
                {/*    <span> Change deadline</span>*/}
                {/*</span>*/}
                <TaskCreate create={create} setCreate={setCreate} isProcess={isProcess}/>
                <ProcessEdit
                    data={data}
                    isAll={isAll}
                    setIsAll={setIsAll}
                    setIsCheck={setIsCheck}
                    handleAllCheck={handleAllCheck}
                    resone={resone}
                    open={open}
                    setOpen={setOpen}/>
                <ProcessDelete deleteItem={deleteItem} setDelete={setDeleteItem}/>

            </td>
        </tr>
    );
}

export default ProcessComponent;

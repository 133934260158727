import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import DatePicker from "react-datepicker";
import {companyRequest} from "../store/actions/company";
import {company, global, history} from "../translate";

export default function CompanyDrop() {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [filters, setFilters] = useState({
        startTime: new Date(),
        endTime: new Date(),
        search: '',
        phoneFil: '',
        searchByManager: '',
        status: '',
        isTest:'',
        isBlocked:'',
    })
    useEffect(() => {
        if (!_.isEmpty(query)) {
            setFilters({
                endTime: new Date(),
                startTime: new Date(),
                search: query.search || '',
                phoneFil: query.phoneFil || '',
                searchByManager: query.searchByManager || '',
                status: query.status || '',
                isTest:query.isTest || '',
                isBlocked:query.isBlocked || '',
            })
            if (query.endTime) {
                setFilters((prev) => ({
                    ...prev,
                    endTime: new Date(query.endTime)
                }))
            }
            if (query.startTime) {
                setFilters((prev) => ({
                    ...prev,
                    startTime: new Date(query.startTime)
                }))
            }
        }

    }, [])
    const statusData = useSelector((state) => state.department.companyStatusData)
    const lang = useSelector((state) => state.users.lang)


    const handleInput = useCallback((name, ev) => {

        if (name === 'startTime' || name === 'endTime') {
            setFilters((prev) => ({
                ...prev,
                [name]: ev
            }));
            query[name] = ev
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
            query.page = '1'
            query[name] = ev.target.value
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [location.search])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(companyRequest(10, 1, query.search,
            query.startTime || '',
            filters.endTime || '',
            query.searchByManager || '',
            query.status || '',
            query.phoneFil || '',
            query.isTest || '',
            query.isBlocked || '',
            ));
    }, [location.search, filters])


    return (
        <>

            <form onSubmit={handleSubmit} style={{width: '100%'}}>
                <div className="d-flex flex-row align-items-end" style={{
                    margin: '25px 30px 0 30px',
                    background: 'white',
                    padding: 32,
                    border: '1px solid #ccc'
                }}
                >
                    <div className="row">
                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row">
                                <span>{global.search[+lang]}:</span>
                                <input
                                    style={{margin: 0}}
                                    className="history_input"
                                    value={filters.search}
                                    onChange={(ev) => handleInput('search', ev)}
                                />
                            </div>
                        </label>
                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row">
                                <span>{company.search[+lang]}:</span>
                                <input
                                    style={{margin: 0}}

                                    className="history_input"
                                    value={filters.phoneFil}
                                    onChange={(ev) => handleInput('phoneFil', ev)}
                                />
                            </div>
                        </label>
                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row">
                                <span>{company.search2[+lang]}:</span>
                                <input
                                    style={{margin: 0}}

                                    className="history_input"
                                    value={filters.searchByManager}
                                    onChange={(ev) => handleInput('searchByManager', ev)}
                                />
                            </div>
                        </label>


                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row h-100 align-items-end">
                                <span>
                                    {history.start[+lang]}:
                                </span>
                                <DatePicker
                                    style={{margin: 0}}
                                    className='startAndEnd'
                                    selected={filters.startTime || new Date()}
                                    onChange={(ev) => handleInput('startTime', ev)}
                                />
                            </div>
                        </label>
                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row h-100 align-items-end">
                                <span>
                                {history.end[+lang]}:
                            </span>
                                <DatePicker
                                    style={{margin: 0}}
                                    className='startAndEnd'
                                    selected={filters.endTime || new Date()}
                                    onChange={(ev) => handleInput('endTime', ev)}
                                />
                            </div>
                        </label>
                        <label
                            style={{margin: '10px 0', fontSize: 13}}
                            className="col-md-4">
                            <div className="d-flex flex-row">
                              <span>
                                 {global.status[+lang]}
                              </span>
                                <select
                                    style={{
                                        border: '1px solid #ccc',
                                        margin: 0,
                                        background: 'white',
                                        width: '100%',
                                        height: 40,
                                        outline: 'none'
                                    }}
                                    value={filters.status}
                                    onChange={(ev) => handleInput('status', ev)}>
                                    <option value="">Select</option>

                                    {statusData.length ? statusData.map(s => (
                                        <option style={{height:30}} key={s.id} value={s.id}>{s.title}</option>
                                    )) : null}
                                </select>
                            </div>

                        </label>
                    </div>

                    <div className="d-flex justify-content-center" style={{margin: '0 45px'}}>
                        <button className="createBtn" onClick={handleSubmit}>{global.search[+lang]}
                        </button>
                    </div>
                </div>

            </form>
        </>
    );
}

import {
    PROCESS_CREATE_FAIL,
    PROCESS_CREATE_REQUEST,
    PROCESS_CREATE_SUCCESS,
    PROCESS_DELETE_FAIL,
    PROCESS_DELETE_REQUEST,
    PROCESS_DELETE_SUCCESS, PROCESS_EDIT_FAIL,
    PROCESS_EDIT_REQUEST,
    PROCESS_EDIT_SUCCESS,
    PROCESS_FAIL,
    PROCESS_REQUEST, PROCESS_SINGLE_REQUEST, PROCESS_SINGLE_SUCCESS,
    PROCESS_SUCCESS
} from '../actions/process';

const initialState = {
    processData: {},
    processSingleData: {},
    processStatus:'',
    processSingleStatus:'',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROCESS_REQUEST: {
            return {
                ...state,
                processStatus: 'request',
                processData: {},
            };
        }
        case PROCESS_SUCCESS: {
            return {
                ...state,
                processStatus: 'success',
                processData: action.payload.data,
            };
        }
        case PROCESS_SINGLE_REQUEST: {
            return {
                ...state,
                processSingleStatus: 'request',
                processSingleData: {},
            };
        }
        case PROCESS_SINGLE_SUCCESS: {
            return {
                ...state,
                processSingleStatus: 'success',
                processSingleData: action.payload.data.single,
            };
        }

        case PROCESS_CREATE_SUCCESS: {
            return {
                ...state,
                processStatus: 'success',
                processData: action.payload.data,
            };
        }

        case PROCESS_DELETE_SUCCESS: {
            return {
                ...state,
                processStatus: 'success',
                processData: action.payload.data,
            };
        }

        case PROCESS_EDIT_SUCCESS: {
            return {
                ...state,
                processStatus: 'success',
                processData: action.payload.data,
            };
        }
        case PROCESS_CREATE_REQUEST: {
            return {
                ...state,
                processStatus: 'request',
                processData: {},
            };
        }

        case PROCESS_DELETE_REQUEST: {
            return {
                ...state,
                processStatus: 'request',
                processData: {},
            };
        }

        case PROCESS_EDIT_REQUEST: {
            return {
                ...state,
                processStatus: 'request',
                processData: {},
            };
        }case PROCESS_CREATE_FAIL: {
            return {
                ...state,
                processStatus: 'fail',
                processData: {},
            };
        }

        case PROCESS_DELETE_FAIL: {
            return {
                ...state,
                processStatus: 'fail',
                processData: {},
            };
        }

        case PROCESS_EDIT_FAIL: {
            return {
                ...state,
                processStatus: 'fail',
                processData: {},
            };
        }
        case PROCESS_FAIL: {
            return {
                ...state,
                processStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

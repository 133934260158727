import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {useDispatch, useSelector} from "react-redux";
import {taskAllRequest, taskEditRequest} from "../store/actions/task";
import {DateTimePicker} from "react-datetime-picker";
import Utils from "../helpers/Utils";
import classNames from "classnames";
import qs from "query-string";
import {useLocation} from "react-router";
import {departmentRequest} from "../store/actions/department";
import {employeeRequest} from "../store/actions/employee";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 610?window.innerWidth - 60 : 650,
    overflowY:'auto',
    height: 'fit-content',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // borderRadius:3,
    boxShadow: 4,
    p: 4,
};

export default function TaskEdit({open, setIsCheck, isAll, setIsAll, setOpen, data, resone}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [value, setValue] = useState(null)
    const [value1, setValue1] = useState(null)
    const [valueType, setValueType] = useState('Meeting')
    const [department, setDepartment] = useState('Developer')
    const departmentData = useSelector(state => state.department.departmentData)
    const employeeData = useSelector(state => state.employee.employeeData)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    useEffect(() => {
        if (resone === 'deadline') {
            setValue(new Date())
            setValue1(new Date())
        } else if(resone === 'responsible'){
            (async () => {
                await dispatch(departmentRequest())
                await dispatch(employeeRequest(10000,1,'','Developer'))
            })()
        }
        else {
            setValue('')
        }
    }, [resone])
    const handleSubmit = useCallback(async () => {
        if (isAll) {
            if (resone === 'deadline') {
                await dispatch(taskAllRequest({[resone]: Utils.dateToTimestamp(Utils.toISOLocal(new Date(value))),}, query.page || 1, query.limit||10, query.filter||'', resone, query.search || ''))
            }else if(resone === 'type'){
                if(valueType === 'other'){
                    await dispatch(taskAllRequest({[resone]: value}, query.page || 1, query.limit||10, query.filter||'', resone))
                }else{
                    await dispatch(taskAllRequest({[resone]: valueType}, query.page || 1, query.limit||10, query.filter||'', resone))
                }
            }
            else {
                await dispatch(taskAllRequest({[resone]: value}, query.page || 1, query.limit||10, query.filter||'', resone))
            }
        } else {
            if (resone === 'result') {
                await dispatch(taskEditRequest(data.id, {
                    ...data,
                    responsible: data['employee_task.user.email'],
                    [resone]: value,
                    status: 2
                },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                setIsCheck([])

            } else if(resone === 'type'){
                if(valueType === 'other'){
                    await dispatch(taskEditRequest(data.id, {
                        ...data,
                        responsible: data['employee_task.user.email'],
                        [resone]: value,
                        status: 1
                    },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                }else{
                    if(value1){
                        await dispatch(taskEditRequest(data.id, {
                            ...data,
                            responsible: data['employee_task.user.email'],
                            [resone]: valueType,
                            deadlineEnd:Utils.dateToTimestamp(Utils.toISOLocal(new Date(value1))),
                            status: 1
                        },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                    }else{
                        await dispatch(taskEditRequest(data.id, {
                            ...data,
                            responsible: data['employee_task.user.email'],
                            [resone]: valueType,
                            status: 1
                        },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                    }

                }
                setIsCheck([])
            }else {
                if(value1){
                    await dispatch(taskEditRequest(data.id, {
                        ...data,
                        responsible: data['employee_task.user.email'],
                        [resone]: value,
                        deadlineEnd:Utils.dateToTimestamp(Utils.toISOLocal(new Date(value1))),
                        status: 1
                    },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                }else{
                    await dispatch(taskEditRequest(data.id, {
                        ...data,
                        responsible: data['employee_task.user.email'],
                        [resone]: value,
                        status: 1
                    },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))
                }
            }
        }
        setIsCheck([])
        setOpen(false)
    }, [value,value1, resone, valueType,location.search])

    const handleEmployerChange = useCallback(async (ev) => {
        setDepartment(ev.target.value)
        await dispatch(employeeRequest(10000,1,'',ev.target.value))
    }, [])

    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                            sx: {
                                backgroundColor: 'rgba(255,255,255,0.4)',
                            },
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box className="dropTheme" sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Edit
                            </Typography>
                            <div style={{marginTop:40}}>
                                {resone !== 'responsible'?<span>Set task {resone}</span>:null}
                                {resone === 'deadline' ? (
                                    <>
                                        <DateTimePicker value={value} onChange={(ev) => setValue(ev)}/>
                                        <div>
                                            {resone !== 'responsible'?<span>Set task {resone} reminder</span>:null}

                                            <DateTimePicker value={value1} onChange={(ev) => setValue1(ev)}/>
                                        </div>

                                    </>
                                ) : resone === 'type' ?(
                                    <div className="d-flex flex-column">
                                        <select
                                            className="taskInput"
                                            style={{height: 40, margin: '12px 0'}}
                                            value={valueType}
                                            onChange={(ev) => setValueType(ev.target.value)}
                                        >
                                            <option selected value="Meeting">Meeting</option>
                                            <option value="Contact">Contact</option>
                                            <option value="other">Other</option>
                                        </select>
                                        <input
                                            className={classNames({'visually-hidden': valueType !== 'other'}, 'taskInput')}
                                            value={value}
                                            onChange={(ev) => setValue(ev.target.value)}
                                        />
                                    </div>) : resone === 'result' ?<input
                                    style={{margin: '0 30px'}}
                                    className='taskInput'
                                    value={value}
                                    onChange={(ev) => setValue(ev.target.value)}
                                />:null}
                                {resone === 'responsible'?<div className="row">
                                    <label
                                        className="col-md-6 m-0">
                                        <span>Department</span>
                                        <select
                                            style={{height: 40, margin:'25px 9px', width:'91%'}}
                                            value={department}
                                            onChange={(ev) => handleEmployerChange(ev)}
                                        >
                                            {departmentData.map((d) => (
                                                <option value={d.title}>{d.title}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label
                                        className="col-md-6 m-0">
                                        <span>Responsible</span>
                                        <select
                                            style={{height: 40, margin:'12px 9px 60px',width:'91%'}}
                                            value={value}
                                            onChange={(ev) => setValue(ev.target.value)}
                                        >
                                            <option value="">Select responsible</option>
                                            {employeeData?.employees?.map((e) => (
                                                <option value={e.user.email} key={e.id}>{e.user.email}</option>
                                            ))}
                                        </select>
                                    </label>
                                    </div>:null}
                            </div>
                           <div className="submitBtn">
                                <span className="setting__icon" onClick={handleSubmit}>
                                    Save changes
                            </span>
                           </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
}

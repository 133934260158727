import React, {useCallback, useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import Loader from "../components/Loader";
import {employeeColumn} from "../tableColumns";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import qs from "query-string";
import {employeeRequest} from "../store/actions/employee";
import EmployeeCreateAndEdit from "../components/EmployeeCreateAndEdit";
import EmployeeDelete from "../components/EmployeeDelete";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginAsEmployee from "../components/LoginAsEmployee";
import Cookies from 'js-cookie';
import {Pagination} from "@mui/material";
import {employee,global} from "../translate";


function Employee(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [edit, setEdit] = useState(0);
    const [deleteItem, setDeleteItem] = useState(0);
    const [create, setCreate] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(0);

    const employeeData = useSelector((state) => state.employee.employeeData)
    const profileData = useSelector((state) => state.users.userData)
    const lang = useSelector((state) => state.users.lang)
    const employeeStatus = useSelector((state) => state.employee.employeeStatus)
    useEffect(() => {

        (async () => {
            setRowsPerPage(+query.limit || 10)
            setPage(+query.page || 1);
            setSearch(query.search || '')
            await dispatch(employeeRequest(query.limit || 10, query.page || 1, query.search || ''));
        })();

    }, [profileData]);

    const handleChangePage = useCallback(async (event, newPage) => {
        setPage(newPage);
        query.page = newPage;
        await dispatch(employeeRequest(query.limit || 10, query.page || 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    const handleChangeRowsPerPage = useCallback(async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
        query.limit = event.target.value;
        query.page = '0';
        await dispatch(employeeRequest(query.limit || 10, query.page || 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    const handleSearch = useCallback(async (ev) => {
        setSearch(ev.target.value)
        query.search = ev.target.value;
        query.page = '1'
        setPage(1)
        await dispatch(employeeRequest(query.limit || 10, 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleEdit = useCallback((id) => {
        setEdit(id)
    }, [])

    const handleDelete = useCallback((id) => {
        setDeleteItem(id)
    }, [])

    return (
        <>
            <Helmet>
                <title>{employee.title[+lang]}</title>
            </Helmet>
            <header className="taskFilter justify-content-between">
                <div className="d-flex align-items-center w-100">
                    <div style={{
                        padding: window.innerWidth < 450 ? '0 15px 0px 90px' : '0 20px',
                        height: 65,
                        borderRight: '1px solid #74c5e2'
                    }}
                         className="d-flex align-items-center">
                        <b>{employee.title[+lang]}</b>
                    </div>
                    <label className="d-flex align-items-center" style={{width: '60%'}}>
                        {global.search[+lang]}
                        <input value={search} onChange={handleSearch}/>
                    </label>
                </div>
                {profileData.is_admin ? <button className="taskBtn" onClick={() => setCreate(true)}>
                    {global.add[+lang]}
                </button> : null}

            </header>
            <div className="taskTable">
                {employeeStatus === 'request' ? (<div className="loaderBack">
                    <Loader/>
                </div>) : (<table>
                    <thead>
                    <tr>
                        {employeeColumn.map((column) => (
                            <th
                                key={column}
                            >
                                {column[+lang]}
                            </th>
                        ))}
                        {profileData.is_admin ? <>
                            <th>{['Գործողություն','Действие','Actions'][+lang]}</th>
                            {!Cookies.get('original') ? <th>{['Դիտել որպես աշխատող','Посмотреть как сотрудник','View as employer'][+lang]}</th> : null}
                        </> : null}
                    </tr>
                    </thead>
                    <tbody>
                    {employeeData.employees?.length ? employeeData.employees?.map((row) => {
                        return (
                            <tr key={row.id}>
                                <td>{row.first_name}</td>
                                <td>{row.last_name}</td>
                                <td>{row.phone}</td>
                                <td>{row.department.title}</td>
                                <td>{+row.status === 1 ? 'Active' : 'Passive'}</td>
                                {profileData.is_admin ?
                                    <>
                                        <td><EditIcon style={{cursor: 'pointer'}}
                                                      onClick={() => handleEdit(row.id)}/>
                                            <DeleteIcon style={{cursor: 'pointer'}}
                                                        onClick={() => handleDelete(row.id)}/>
                                        </td>
                                        {!Cookies.get('original') ? <td>
                                            <AccountCircleIcon onClick={() => setOpen(row.id)}/>
                                        </td> : null}
                                    </> : null}

                            </tr>
                        );
                    }) : <tr>
                        <td colSpan={10} align="center">No data</td>
                    </tr>}

                    </tbody>
                    <tfoot style={{borderTop: '1px solid #ccc'}}>
                    <tr>
                        <td align="right" colSpan='2'>
                            {/*<label>*/}
                            {/*    Select row count*/}
                            {/*    <select value={rowsPerPage} style={{margin: '0 10px'}}*/}
                            {/*            onChange={(ev) => handleChangeRowsPerPage(ev)}>*/}
                            {/*        <option value="10">10</option>*/}
                            {/*        <option value="30">30</option>*/}
                            {/*        <option value="50">50</option>*/}
                            {/*    </select>*/}
                            {/*</label>*/}
                        </td>
                        <td className="paginationBlock" colSpan='5' align="right">
                            <Pagination count={employeeData.totalPages} page={+page} onChange={handleChangePage}
                                        variant="outlined" color="primary"/>
                        </td>
                    </tr>
                    </tfoot>
                </table>)}
            </div>
            <EmployeeCreateAndEdit edit={edit} setEdit={setEdit} create={create} setCreate={setCreate}/>
            <EmployeeDelete deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>
            {+profileData.is_admin && open ? <LoginAsEmployee open={open} setOpen={setOpen}/> : null}
        </>
    );
}

export default Employee;

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useNavigate} from 'react-router';
import {Helmet} from 'react-helmet';
import {loginUserRequest, profileUserRequest} from '../store/actions/users';
import Account from '../helpers/Account';

function AdminLogin() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [username, setUserName] = useState('');
    const [show, setShow] = useState(false);
    const [password, setPassword] = useState('');
    const userStatus = useSelector((state) => state.users.userStatus);
    const user = useSelector((state) => state.users.userData);
    useEffect(() => {
        if (userStatus === 'ok' && Account.getAdminToken()) {
            navigate('/');
        }else{
            Account.logoutAdmin()
        }
    }, [userStatus]);

    // const handleLanguageChange = useCallback((val) => {
    //   localStorage.setItem('lang', val);
    // }, []);


    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault();
        if (!username || !password.trim()) {
            toast.error('Enter email and password');
            return;
        }
        // if(password)
        await dispatch(loginUserRequest({username, password:password.trim()}));
        setTimeout(async () => {
            await dispatch(profileUserRequest())
        }, 500 )
        // window.location.reload();
    }, [username, password]);

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <div className="adminLogin">
                <div className="loginContainer">
                    <div className="adminLoginPage">
                        <form onSubmit={handleSubmit} className="adminLoginForm">
                            {/*<div className="adminLoginHeader">*/}
                            {/*    <div>*/}
                            {/*        <Link style={{textDecoration: 'none'}} to="dfghj">Operator</Link> | <Link*/}
                            {/*        style={{textDecoration: 'none'}} to="dfsghj">Admin</Link>*/}
                            {/*    </div>*/}
                            {/*    <div>*/}
                            {/*        hy|ru|eng*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="adminLoginMain">
                                <div
                                    className="adminInput"
                                ><input
                                        // style={{border:'none', outline:'none',}}
                                        type="text"
                                        placeholder="Your Username"
                                        value={username}
                                        onChange={(ev) => setUserName(ev.target.value)}
                                    />
                                </div>
                                <label htmlFor="adminPass" style={{display: 'flex'}} className="adminFormLabel">
                                    <input
                                        id="adminPass"
                                        value={password}
                                        placeholder="Your Password"
                                        type={show ? 'text' : 'password'}
                                        onChange={(ev) => setPassword(ev.target.value)}
                                    />
                                    {show
                                        ? <RemoveRedEyeIcon fontSize="small" onClick={() => setShow(false)}/>
                                        : <VisibilityOffIcon fontSize="small" onClick={() => setShow(true)}/>}
                                </label>
                                <button type="submit" className="adminLoginBtn">login</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminLogin;

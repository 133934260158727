import React, {useCallback, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Helmet} from "react-helmet";

function HistoryRecords(props) {

    const columns = [
        {id: 'name', label: 'Name', minWidth: 170},
        {id: 'code', label: 'ISO\u00a0Code', minWidth: 100},
        {
            id: 'population',
            label: 'Population',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'size',
            label: 'Size\u00a0(km\u00b2)',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },

        {
            id: 'density',
            label: 'Density',
            minWidth: 170,
            align: 'right',
            format: (value) => value.toFixed(2),
        },
        {id: 'dd', label: 'ISO\u00a0Code', align: 'center', minWidth: 100},

    ];

    function createData(name, code, population, size) {
        const density = population / size;
        return {name, code, population, size, density};
    }

    const rows = [
        createData('India', 'IN', 1324171354, 3287263),
        createData('China', 'CN', 1403500365, 9596961),
        createData('Italy', 'IT', 60483973, 301340),
        createData('United States', 'US', 327167434, 9833520),
        createData('Canada', 'CA', 37602103, 9984670),
        createData('Australia', 'AU', 25475400, 7692024),
        createData('Germany', 'DE', 83019200, 357578),
        createData('Ireland', 'IE', 4857000, 70273),
        createData('Mexico', 'MX', 126577691, 1972550),
        createData('Japan', 'JP', 126317000, 377973),
        createData('France', 'FR', 67022000, 640679),
        createData('United Kingdom', 'GB', 67545757, 242495),
        createData('Russia', 'RU', 146793744, 17098246),
        createData('Nigeria', 'NG', 200962417, 923768),
        createData('Brazil', 'BR', 210147125, 8515767),
    ];

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [edit, setEdit] = useState(0);
    const [create, setCreate] = useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEdit = useCallback((id) => {
        setEdit(id)
    }, [])

    const handleDelete = useCallback((id) => {
        console.log(id)
    }, [])

    return (
        <>
            <Helmet>
                <title>History records</title>
            </Helmet>
            <AdminWrapper>
                <Paper className="paperMat" sx={{width: '100%', zIndex: -8585858}}>
                    <TableContainer sx={{maxHeight: 440}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left" colSpan={4}>
                                        History records
                                    </TableCell>
                                    <TableCell align="right" colSpan={2}>
                                        <AddCircleOutlineIcon style={{cursor: 'pointer'}}
                                                              onClick={() => setCreate(true)}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{top: 57, minWidth: column.minWidth}}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {columns.map((column, i) => {
                                                    if (i === columns.length - 1) {
                                                        return (
                                                            <TableCell colSpan={2} key={column.id} align={column.align}>
                                                                <EditIcon style={{fill: '#34cd34', cursor: 'pointer'}}
                                                                          onClick={() => handleEdit(row.code)}/>
                                                                <DeleteIcon style={{fill: '#c00909', cursor: 'pointer'}}
                                                                            onClick={() => handleDelete(row.code)}/>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {column.format && typeof value === 'number'
                                                                    ? column.format(value)
                                                                    : value}
                                                            </TableCell>
                                                        );
                                                    }
                                                })}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </AdminWrapper>
        </>
    );
}

export default HistoryRecords;


import { takeLatest, call, put } from 'redux-saga/effects';
import {
    PROCESS_DELETE_SUCCESS,
    PROCESS_DELETE_REQUEST,
    PROCESS_DELETE_FAIL,
    PROCESS_EDIT_FAIL,
    PROCESS_EDIT_REQUEST,
    PROCESS_EDIT_SUCCESS,
    PROCESS_REQUEST,
    PROCESS_SUCCESS,
    PROCESS_FAIL,
    PROCESS_CREATE_SUCCESS,
    PROCESS_CREATE_FAIL,
    PROCESS_CREATE_REQUEST,
    PROCESS_SINGLE_SUCCESS,
    PROCESS_SINGLE_FAIL,
    PROCESS_SINGLE_REQUEST,
    COMMENT_PROCESS_DELETE_REQUEST,
    COMMENT_PROCESS_CREATE_REQUEST,
    COMMENT_PROCESS_CREATE_SUCCESS,
    COMMENT_PROCESS_CREATE_FAIL,
    COMMENT_PROCESS_DELETE_FAIL,
    COMMENT_PROCESS_DELETE_SUCCESS
} from '../actions/process';
import Api from '../../Api';
import {toast} from "react-toastify";
import {
    COMMENT_CREATE_FAIL,
    COMMENT_CREATE_SUCCESS,
    COMMENT_DELETE_FAIL,
    COMMENT_DELETE_SUCCESS
} from "../actions/company";

function* handleProcess(action) {
    try {
        const {rowsPerPage,page,search} = action.payload
        const { data } = yield call(Api.getProcess, rowsPerPage,page,search);
        yield put({
            type: PROCESS_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: PROCESS_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleSingleProcess(action) {
    try {
        const {id} = action.payload
        const { data } = yield call(Api.getSingleProcess,id);
        yield put({
            type: PROCESS_SINGLE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: PROCESS_SINGLE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleProcessEdit(action) {
    try {
        const { id, formData, page, limit, search } = action.payload;
        const { data } = yield call(Api.updateProcess,id, formData, page, limit, search);
        toast.success('ProcessLine is successfully edited')

        yield put({
            type: PROCESS_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        yield put({
            type: PROCESS_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleProcessCreate(action) {
    try {
        const {form, page, limit, search} = action.payload;
        const { data } = yield call(Api.createProcess,form, page, limit, search);
        toast.success('ProcessLine is successfully created')
        yield put({
            type: PROCESS_CREATE_SUCCESS,
            payload: {data },
        });
    } catch (e) {
        yield put({
            type: PROCESS_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleProcessDelete(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(Api.deleteProcess,id);
        toast.success('ProcessLine is successfully deleted')
        yield put({
            type: PROCESS_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: PROCESS_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleCommentCreate(action) {
    try {
        const {id,arr} = action.payload;
        const { data } = yield call(Api.createProcessComment,id,arr);
        toast.success('Comment is successfully created')
        yield put({
            type: COMMENT_PROCESS_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMMENT_PROCESS_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleCommentDelete(action) {
    try {
        const { id } = action.payload;
        const { data } = yield call(Api.deleteProcessComment,id);
        toast.success('Comment is successfully deleted')
        yield put({
            type: COMMENT_PROCESS_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: COMMENT_PROCESS_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(PROCESS_REQUEST, handleProcess);
    yield takeLatest(PROCESS_SINGLE_REQUEST, handleSingleProcess);
    yield takeLatest(PROCESS_CREATE_REQUEST, handleProcessCreate);
    yield takeLatest(PROCESS_EDIT_REQUEST, handleProcessEdit);
    yield takeLatest(PROCESS_DELETE_REQUEST, handleProcessDelete);
    yield takeLatest(COMMENT_PROCESS_DELETE_REQUEST, handleCommentDelete);
    yield takeLatest(COMMENT_PROCESS_CREATE_REQUEST, handleCommentCreate);
}

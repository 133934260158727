import {
    faBuilding,
    faDroplet,
    faHome, faListCheck,
    faNewspaper, faPhone,
    faRecordVinyl, faServer,
    faSortNumericDown, faTrashCan, faUsd, faUsers, faUserTie
} from "@fortawesome/free-solid-svg-icons";

export const menu = [
    {id: 5,title:['Գլխավոր','Главная','Homepage'], icon:faHome, link:'/', children:[]},
    {id: 1,title:['Պատմություն','История','History'], icon:faNewspaper, link:'/history', children:[]},
    // {id: 6,title:'History records', icon:faRecordVinyl, link:'/history_records', children:[]},
    {id: 7,title:['Ընկերություն','Компания','Company'], icon:faBuilding, link:'/company', children:[]},
    {id: 8,title:['Հեռախոսներ','Телефоны','Phones'], icon:faUsers, link:'/phones', children:[]},
    {id: 9,title:['Զանգ','Вызов','Call'], icon:faPhone, link:'/call', children:[]},
    {id: 10,title:['Աշխատակիցներ','Сотрудники','Employees'], icon:faUserTie, link:'/employee', children:[]},
    {id: 11,title:['Առաջադրանք','Задача','Tasks'], icon:faListCheck, link:'/task/list', children:[]},
    {id: 12,title:['Գործարքներ','Транзакции','Transactions'], icon: faUsd, link:'/process/line', children:[]},
    {id: 13,title:['Պարամետրեր','Настройки','Settings'], icon: faServer, link:'/settings', children:[]},
    // {id: 2,title:'data2', icon:faHome, link:'', children:[
    //     {id:1,title:'sub', link: 'link'},
    //     {id:2,title:'sub', link: 'link'},
    //     {id:3,title:'sub', link: 'link'},
    //     ]},
    // {id: 3,title:'data2', icon:faHome, link:'', children:[
    //     {id:1,title:'sub', link: 'link'},
    //     {id:2,title:'sub', link: 'link'},
    //     {id:3,title:'sub', link: 'link'},
    //     ]},
]

export const list = [
    {id:1, icon:faTrashCan,text:""},
    {id:2, icon:'',text:"Next day"},
    {id:4, icon:'',text:"Next month"},
    {id:3, icon:'',text:"Finish"},
]

export const processList = [
    {id:1,icon:faTrashCan, text:"Starting"},
    {id:4, icon:'',text:"Successfully finished"},
    {id:3, icon:'',text:"Not implemented"},
]

export const processListStatus = [
    {id:1, icon:'',text:['Զանգ','Вызов','Call']},
    {id:2, icon:'',text:['Անհաջող զանգեր','Неуспешные звонки','Unsuccessful calls']},
    {id:3, icon:'',text:['Քննարկում','Обсуждение','Discussion']},
    {id:4, icon:'',text:['Հանդիպում','Встреча','Meeting']},
    {id:5, icon:'',text:['Ավարտ','Конец','End']},
]

export const disposition = [
    {value:'NO ANSWER'},
    {value:'ANSWERED'},
    {value:'BUSY'},
    {value:'FAILED'},
]

export const statusData = [
    {id:1,name:'Out Call'},
    {id:0,name:'Incoming Call'},
]

import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {bindToggle} from 'material-ui-popup-state';
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import DatePicker from "react-datepicker";
import {historyRequest} from "../store/actions/history";
import {disposition, statusData} from "../menu";
import {global, history} from "../translate";

export default function HistoryDrop() {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [filters, setFilters] = useState({
        startTime: new Date(),
        endTime: new Date(),
        number: '',
        status: '',
        disposition: '',
        company: ''
    })

    const lang = useSelector(state => state.users.lang)

    useEffect(() => {
        if (!_.isEmpty(query)) {
            setFilters({
                endTime: new Date(),
                startTime: new Date(),
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                company: query.company || ''
            })
            if (query.endTime) {
                setFilters((prev) => ({
                    ...prev,
                    endTime: new Date(query.endTime)
                }))
            }
            if (query.startTime) {
                setFilters((prev) => ({
                    ...prev,
                    startTime: new Date(query.startTime)
                }))
            }
        }

    }, [])

    const handleInput = useCallback((name, ev) => {

        if (name === 'startTime' || name === 'endTime') {
            setFilters((prev) => ({
                ...prev,
                [name]: ev
            }));
            query[name] = ev
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        } else {
            setFilters((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
            query[name] = ev.target.value
            navigate(`?${qs.stringify(query, {
                arrayFormat: 'comma',
                skipEmptyString: true,
            })}`);
        }

    }, [location.search])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(historyRequest(10, 1, filters));
    }, [filters])

    return (
        <>
            <form onSubmit={handleSubmit} className="history_filter" style={{overflow: 'hidden', width: '100%'}}>
                <div style={{padding: 30}} className="row">
                    <label className="col-md-12 m-0">
                       <div className="d-flex align-items-end flex-row" style={{marginBottom:15}}>
                           <span>{global.phone[+lang]}:</span>
                           <input
                               className="history_input"
                               value={filters.number}
                               onChange={(ev) => handleInput('number', ev)}
                               />
                       </div>
                    </label>
                    <label className="col-md-6 m-0">
                        <div className="d-flex align-items-end flex-row" style={{marginBottom:15, height:'100%'}}>
                            <span>{history.start[+lang]}</span>
                            <DatePicker
                                className='startAndEnd'
                                selected={filters.startTime || new Date()}
                                onChange={(ev) => handleInput('startTime', ev)}
                            />
                        </div>
                    </label>
                    <label className="col-md-6 m-0">
                        <div className="d-flex align-items-end flex-row" style={{marginBottom:15, height:'100%'}}>
                            <span>{history.end[+lang]}</span>
                            <DatePicker
                                className='startAndEnd'
                                selected={filters.endTime || new Date()}
                                onChange={(ev) => handleInput('endTime', ev)}
                            />
                        </div>

                    </label>
                    <label className="col-md-6 m-0">
                        <div className="d-flex align-items-end flex-row" style={{marginBottom:15,marginTop:7, width:'100%', height:'100%'}}>
                            <span>{history.dis[+lang]}</span>
                            <select
                                style={{width:'100%',height:40}}

                                className="history_select"
                                value={filters.disposition}
                                onChange={(ev) => handleInput('disposition', ev)}
                            >
                                <option value="">{history.sel2[+lang]}</option>
                                {disposition.map((d) => (
                                    <option key={d.value} value={d.value}>{d.value}</option>
                                ))}
                            </select>
                        </div>
                    </label>
                    <label className="col-md-6 m-0">
                        <div className="d-flex align-items-end flex-row" style={{marginBottom:15,marginTop:7,width:'100%', height:'100%'}}>
                            <span>{history.state[+lang]}</span>
                            <select
                                style={{width:'100%',height:40}}
                                className="history_select"
                                value={filters.status}
                                onChange={(ev) => handleInput('status', ev)}
                            >
                                <option value="">{history.sel1[+lang]}</option>
                                {statusData.map((s) => (
                                    <option key={s.id} value={s.id}>{s.name}</option>
                                ))}
                            </select>
                        </div>
                    </label>

                </div>
                <div className="d-flex justify-content-center" style={{margin: '20px 30px'}}>
                    <button className="createBtn" onClick={handleSubmit}>{global.search[+lang]}</button>
                </div>
            </form>
        </>
    );
}

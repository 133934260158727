import React, {useCallback, useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import Loader from "./Loader";
import Utils from "../helpers/Utils";
import {useDispatch, useSelector} from "react-redux";
import {processCreateRequest} from "../store/actions/process";
import {useLocation} from "react-router";
import qs from "query-string";
import Select from "react-select";
import {companyRequest} from "../store/actions/company";
import {departmentRequest} from "../store/actions/department";
import {employeeRequest} from "../store/actions/employee";

function ProcessCreate({create, setCreate, line}) {
    const dispatch = useDispatch()
    const location = useLocation()
    const profileData = useSelector(state => state.users.userData)
    const companyData = useSelector((state) => state.company.companyData)
    const employeeData = useSelector((state) => state.employee.employeeData)
    const departmentData = useSelector((state) => state.department.departmentData)
    const [companySearch, setCompanySearch] = useState('')
    const [department, setDepartment] = useState('Developer')

    const handleCompanySearch = useCallback(async (ev) => {
        setCompanySearch(ev)
        await dispatch(companyRequest(100000,1,ev))
    }, [])
    const handleEmployerRequest = useCallback(async (ev) => {
        setDepartment(ev.target.value)

        await dispatch(employeeRequest(1000, 1, '', ev.target.value))
    }, [])

    useEffect(() => {
        (async () => {
            if(profileData.is_admin){
                await dispatch(departmentRequest())
                await dispatch(employeeRequest(1000,1,'', 'Developer'))
            }
            await dispatch(companyRequest(100000,1,''))

        })()

        setForm(
            (prev) => ({
                ...prev,
                manager: profileData.email
            })
        )
    }, [profileData])
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 610?window.innerWidth - 60 : 650,
        height: window.innerHeight < 600?window.innerHeight - 60 : 600,
        overflowY:'auto',
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 4,
    };

    const [form, setForm] = useState({
        manager:'',
        budget:0,
        company_phone:'',
        title:'',
        description:'',

    })
    const handleInput = useCallback((name, ev) => {
        if(name === 'company_phone'){
            setForm((prev) => ({
                ...prev,
                [name]: ev,
            }));
        }else{
            // ev.target.style.borderBottomColor = "black"
            setForm((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
        }
    }, [])

    const handleSubmit = useCallback(async () => {
        if(line){
            await dispatch(processCreateRequest(form,1,1000,''))
        }else{
            await dispatch(processCreateRequest(form,query.page || 1,query.limit || 10,''))
        }
        setCreate(false)
    }, [form,line])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create }
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={create}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <div style={{marginBottom:30}} className="d-flex justify-content-between">
                                <p>Process</p>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => setCreate(false)}>X</span>
                            </div>
                        </Typography>
                        <div className="d-flex flex-row justify-content-around">
                            <div className="modalCreateAndUpdate row">
                                <label className="col-md-6">
                                    <span>Title</span>
                                    <input
                                        id="title"
                                        style={{marginBottom: 40}}
                                        placeholder="Enter title"
                                        value={form.title}
                                        onChange={(ev) => handleInput('title', ev)}
                                    />
                                </label>
                                <label className="col-md-6">
                                    <span>Description</span>
                                    <input
                                        style={{marginBottom: 40}}
                                        id="description"
                                        placeholder="Enter description"
                                        value={form.description}
                                        onChange={(ev) => handleInput('description', ev)}
                                    />
                                </label>
                                <label className="col-md-6">
                                    <span>Budget</span>
                                    <input
                                        type="number"
                                        id="last_name"
                                        style={{marginBottom: 40}}
                                        // placeholder="Enter budget"
                                        value={form.budget}
                                        onChange={(ev) => handleInput('budget', ev)}
                                    />
                                </label>
                                <label className="col-md-6">
                                    <span>Company phones</span>
                                    <Select
                                        style={{marginBottom: 40}}
                                        value={form.company_phone}
                                        onChange={(ev) => handleInput('company_phone', ev)}
                                        isSearchable
                                        inputValue={companySearch}
                                        onInputChange={handleCompanySearch}
                                        options={Utils.sortCompany(companyData,'phone')}
                                    />
                                </label>
                                {+profileData.is_admin?<>
                                    <label className="col-md-6">
                                        <span>Department:</span>
                                        <select
                                            style={{marginBottom: 25}}
                                            value={department} onChange={(ev) => handleEmployerRequest(ev)}>
                                            {departmentData.map((d) => (
                                                <option value={d.title} key={d.id}>{d.title}</option>
                                            ))}
                                        </select>
                                    </label>
                                    <label className="col-md-6">
                                        <span>Manager</span>
                                        <select value={form.manager} onChange={(ev) => handleInput('manager', ev)}>
                                            <option value="">Select manager</option>

                                            {employeeData?.employees?.map((d) => (
                                                <option value={d.user.email} key={d.id}>{d.user.email}</option>
                                            ))}
                                        </select>
                                    </label>
                                </>:null}

                            </div>

                        </div>

                        <div style={{marginTop:40}} className="d-flex justify-content-center">
                            <button onClick={handleSubmit} className="createBtn">
                                {/*{employeeStatus === "request" ? (*/}
                                {/*<div className="d-flex justify-content-center">*/}
                                {/*    <Loader/>*/}
                                {/*</div>) : edit ? "Edit" : "Create"}*/}
                                Add process
                            </button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default ProcessCreate;

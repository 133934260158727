export const COMPANY_REQUEST = 'COMPANY_REQUEST';
export const COMPANY_SUCCESS = 'COMPANY_SUCCESS';
export const COMPANY_FAIL = 'COMPANY_FAIL';

export function companyRequest(rowsPerPage,page,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,) {
    return {
        type: COMPANY_REQUEST,
        payload: {rowsPerPage,page,search,startTime,endTime,searchByManager,status,phoneFil ,isTest ,isBlocked,},
    };
}


export const COMPANY_SINGLE_REQUEST = 'COMPANY_SINGLE_REQUEST';
export const COMPANY_SINGLE_SUCCESS = 'COMPANY_SINGLE_SUCCESS';
export const COMPANY_SINGLE_FAIL = 'COMPANY_SINGLE_FAIL';

export function companySingleRequest(phone) {
    return {
        type: COMPANY_SINGLE_REQUEST,
        payload: {phone},
    };
}
export const COMPANY_EDIT_REQUEST = 'COMPANY_EDIT_REQUEST';
export const COMPANY_EDIT_SUCCESS = 'COMPANY_EDIT_SUCCESS';
export const COMPANY_EDIT_FAIL = 'COMPANY_EDIT_FAIL';

export function companyEditRequest(id, formData, page, limit, search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,) {
    return {
        type: COMPANY_EDIT_REQUEST,
        payload: {id, formData, page,limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,},
    };
}

export const COMPANY_DELETE_REQUEST = 'COMPANY_DELETE_REQUEST';
export const COMPANY_DELETE_SUCCESS = 'COMPANY_DELETE_SUCCESS';
export const COMPANY_DELETE_FAIL = 'COMPANY_DELETE_FAIL';

export function companyDeleteRequest(id) {
    return {
        type: COMPANY_DELETE_REQUEST,
        payload: {id},
    };
}
export const COMPANY_CREATE_REQUEST = 'COMPANY_CREATE_REQUEST';
export const COMPANY_CREATE_SUCCESS = 'COMPANY_CREATE_SUCCESS';
export const COMPANY_CREATE_FAIL = 'COMPANY_CREATE_FAIL';

export function companyCreateRequest(form, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,) {
    return {
        type: COMPANY_CREATE_REQUEST,
        payload: {form,page,limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,},
    };
}

export const COMPANY_EXEL_CREATE_REQUEST = 'COMPANY_EXEL_CREATE_REQUEST';
export const COMPANY_EXEL_CREATE_SUCCESS = 'COMPANY_EXEL_CREATE_SUCCESS';
export const COMPANY_EXEL_CREATE_FAIL = 'COMPANY_EXEL_CREATE_FAIL';

export function companyCreateExelRequest(exel, page, limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,) {
    return {
        type: COMPANY_EXEL_CREATE_REQUEST,
        payload: {exel,page,limit,search,startTime,endTime,searchByManager,status,phoneFil,isTest ,isBlocked,},
    };
}

export const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
export const COMMENT_CREATE_SUCCESS = 'COMMENT_CREATE_SUCCESS';
export const COMMENT_CREATE_FAIL = 'COMMENT_CREATE_FAIL';

export function commentCreateRequest(id,arr) {
    return {
        type: COMMENT_CREATE_REQUEST,
        payload: {id,arr},
    };
}
export const COMMENT_DELETE_REQUEST = 'COMMENT_DELETE_REQUEST';
export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_FAIL = 'COMMENT_DELETE_FAIL';

export function commentDeleteRequest(id) {
    return {
        type: COMMENT_DELETE_REQUEST,
        payload: {id},
    };
}



import * as React from 'react';
import {useCallback, useState} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {useDispatch} from "react-redux";
import qs from "query-string";
import {useLocation} from "react-router";
import {processEditRequest} from "../store/actions/process";
import {processListStatus} from "../menu";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    // borderRadius:3,
    boxShadow: 4,
    p: 4,
};

export default function ProcessEdit({open, setIsCheck, isAll, setIsAll, setOpen, data, resone}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const [value, setValue] = useState(null)
    const [valueType, setValueType] = useState(1)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleSubmit = useCallback(async () => {
        if (isAll) {
            if (resone === 'deadline') {
                // await dispatch(taskAllRequest({[resone]: Utils.dateToTimestamp(Utils.toISOLocal(new Date(value)))}, query.page || 1, query.limit||10, query.filter||'', resone, query.search || ''))
            } else if (resone === 'type') {
                if (valueType === 'other') {
                    // await dispatch(taskAllRequest({[resone]: value}, query.page || 1, query.limit||10, query.filter||'', resone))
                } else {
                    // await dispatch(taskAllRequest({[resone]: valueType}, query.page || 1, query.limit||10, query.filter||'', resone))
                }
            } else {
                // await dispatch(taskAllRequest({[resone]: value}, query.page || 1, query.limit||10, query.filter||'', resone))
            }
        } else {
            if (resone === 'status') {
                await dispatch(processEditRequest(data.id, {
                    ...data,
                    [resone]: valueType,
                }, query.page || 1, query.limit || 10, query.filter || '', query.search || ''))

                setIsCheck([])
            } else {
                await dispatch(processEditRequest(data.id, {
                    ...data,
                    [resone]: value,
                }, query.page || 1, query.limit || 10, query.filter || '', query.search || ''))
            }
        }
        setIsCheck([])


        setOpen(false)

    }, [value, resone, valueType, location.search])

    return (
        <>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={open}
                    onClose={() => setOpen(false)}
                    closeAfterTransition
                    slots={{backdrop: Backdrop}}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                            sx: {
                                backgroundColor: 'rgba(255,255,255,0.4)',
                            },
                        },
                    }}
                >
                    <Fade in={open}>
                        <Box className="dropTheme" sx={style}>
                            <Typography id="transition-modal-title" variant="h6" component="h2">
                                Edit
                            </Typography>
                            <div className="settings__list">
                                <span>Set process {resone}</span>
                                {resone === 'status' ? (
                                    <select value={valueType} onChange={(ev) => setValueType(ev.target.value)}>
                                        {processListStatus.map((p) => (
                                            <option value={p.id}>{p.text}</option>
                                        ))}
                                    </select>
                                ) : (<input value={value} onChange={(ev) => setValue(ev.target.value)}/>)}
                            </div>
                            <div className="submitBtn">
                                <span className="setting__icon" onClick={handleSubmit}>
                                    Save changes
                            </span>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </>
    );
}

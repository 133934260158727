import { takeLatest, call, put } from 'redux-saga/effects';
import {
    SIP_EDIT_FAIL,
    SIP_EDIT_REQUEST,
    SIP_EDIT_SUCCESS,
    SIP_CREATE_SUCCESS,
    SIP_CREATE_FAIL,
    SIP_CREATE_REQUEST,
    SIP_CHECK_SUCCESS,
    SIP_CHECK_FAIL,
    SIP_CHECK_REQUEST,
    SIP_REGISTER_SUCCESS,
    SIP_REGISTER_FAIL,
    SIP_REGISTER_REQUEST,
} from '../actions/sip';
import Api from '../../Api';
import {toast} from "react-toastify";

function* handleSipEdit(action) {
    try {
        const {form} = action.payload;
        const { data } = yield call(Api.sipExpUpdate,form);
        toast.success('Sip is successfully edited')

        yield put({
            type: SIP_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SIP_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSipCheck(action) {
    try {
        const {form} = action.payload;
        const { data } = yield call(Api.check,form);

        yield put({
            type: SIP_CHECK_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SIP_CHECK_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSipCreate(action) {
    try {
        const {form} = action.payload;
        const { data } = yield call(Api.sipExpCreate,form);
        toast.success('Sip is successfully created')
        yield put({
            type: SIP_CREATE_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SIP_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleSipRegister(action) {
    try {
        const {form} = action.payload;
        const { data } = yield call(Api.registerSip,form);
        toast.success('Sip is successfully registered')
        yield put({
            type: SIP_REGISTER_SUCCESS,
            payload: {data},
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: SIP_REGISTER_FAIL,
            payload: { error: e.response },
        });
    }
}




export default function* watcher() {
    yield takeLatest(SIP_CREATE_REQUEST, handleSipCreate);
    yield takeLatest(SIP_EDIT_REQUEST, handleSipEdit);
    yield takeLatest(SIP_CHECK_REQUEST, handleSipCheck);
    yield takeLatest(SIP_REGISTER_REQUEST, handleSipRegister);
}

export const EMPLOYEE_REQUEST = 'EMPLOYEE_REQUEST';
export const EMPLOYEE_SUCCESS = 'EMPLOYEE_SUCCESS';
export const EMPLOYEE_FAIL = 'EMPLOYEE_FAIL';

export function employeeRequest(rowsPerPage,page,search,type) {
    return {
        type: EMPLOYEE_REQUEST,
        payload: {rowsPerPage,page,search,type },
    };
}
export const EMPLOYEE_EDIT_REQUEST = 'EMPLOYEE_EDIT_REQUEST';
export const EMPLOYEE_EDIT_SUCCESS = 'EMPLOYEE_EDIT_SUCCESS';
export const EMPLOYEE_EDIT_FAIL = 'EMPLOYEE_EDIT_FAIL';

export function employeeEditRequest(id, formData, page, limit, search) {
    return {
        type: EMPLOYEE_EDIT_REQUEST,
        payload: {id, formData, page,limit,search},
    };
}

export const EMPLOYEE_DELETE_REQUEST = 'EMPLOYEE_DELETE_REQUEST';
export const EMPLOYEE_DELETE_SUCCESS = 'EMPLOYEE_DELETE_SUCCESS';
export const EMPLOYEE_DELETE_FAIL = 'EMPLOYEE_DELETE_FAIL';

export function employeeDeleteRequest(id) {
    return {
        type: EMPLOYEE_DELETE_REQUEST,
        payload: {id},
    };
}
export const EMPLOYEE_CREATE_REQUEST = 'EMPLOYEE_CREATE_REQUEST';
export const EMPLOYEE_CREATE_SUCCESS = 'EMPLOYEE_CREATE_SUCCESS';
export const EMPLOYEE_CREATE_FAIL = 'EMPLOYEE_CREATE_FAIL';

export function employeeCreateRequest(form, page, limit, search) {
    return {
        type: EMPLOYEE_CREATE_REQUEST,
        payload: {form,page,limit,search},
    };
}





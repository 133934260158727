import {
    COMPANY_CREATE_FAIL,
    COMPANY_CREATE_REQUEST,
    COMPANY_CREATE_SUCCESS,
    COMPANY_DELETE_FAIL,
    COMPANY_DELETE_REQUEST,
    COMPANY_DELETE_SUCCESS,
    COMPANY_EDIT_FAIL,
    COMPANY_EDIT_REQUEST,
    COMPANY_EDIT_SUCCESS, COMPANY_EXEL_CREATE_FAIL, COMPANY_EXEL_CREATE_REQUEST, COMPANY_EXEL_CREATE_SUCCESS,
    COMPANY_FAIL,
    COMPANY_REQUEST,
    COMPANY_SINGLE_REQUEST,
    COMPANY_SINGLE_SUCCESS,
    COMPANY_SUCCESS
} from '../actions/company';

const initialState = {
    companyData: {},
    companyStatus: '',
    companySingleData: {},
    companySingleStatus: '',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case COMPANY_REQUEST: {
            return {
                ...state,
                companyStatus: 'request',
                companyData: {},
            };
        }
        case COMPANY_SUCCESS: {
            return {
                ...state,
                companyStatus: 'success',
                companyData: action.payload.data,
            };
        }

        case COMPANY_SINGLE_REQUEST: {
            return {
                ...state,
                companySingleStatus: 'request',
                companySingleData: {},
            };
        }
        case COMPANY_SINGLE_SUCCESS: {
            return {
                ...state,
                companySingleStatus: 'success',
                companySingleData: action.payload.data.company,
            };
        }

        case COMPANY_CREATE_SUCCESS: {
            return {
                ...state,
                companyStatus: 'success',
                companyData: action.payload.data,
            };
        }

        case COMPANY_DELETE_SUCCESS: {
            return {
                ...state,
                companyStatus: 'success',
                companyData: action.payload.data,
            };
        }
        case COMPANY_EXEL_CREATE_SUCCESS: {
            return {
                ...state,
                companyStatus: 'success',
                companyData: action.payload.data,
            };
        }


        case COMPANY_EDIT_SUCCESS: {
            return {
                ...state,
                companyStatus: 'success',
                companyData: action.payload.data,
            };
        }
        case COMPANY_CREATE_REQUEST: {
            return {
                ...state,
                companyStatus: 'request',
                companyData: {},
            };
        }
        case COMPANY_EXEL_CREATE_REQUEST: {
            return {
                ...state,
                companyStatus: 'request',
                companyData: {},
            };
        }

        case COMPANY_DELETE_REQUEST: {
            return {
                ...state,
                companyStatus: 'request',
                companyData: {},
            };
        }

        case COMPANY_EDIT_REQUEST: {
            return {
                ...state,
                companyStatus: 'request',
                companyData: {},
            };
        }
        case COMPANY_CREATE_FAIL: {
            return {
                ...state,
                companyStatus: 'fail',
                companyData: {},
            };
        }

        case COMPANY_DELETE_FAIL: {
            return {
                ...state,
                companyStatus: 'fail',
                companyData: {},
            };
        }

        case COMPANY_EDIT_FAIL: {
            return {
                ...state,
                companyStatus: 'fail',
                companyData: {},
            };
        }
        case COMPANY_FAIL: {
            return {
                ...state,
                companyStatus: 'fail',
            };
        }
        case COMPANY_EXEL_CREATE_FAIL: {
            return {
                ...state,
                companyStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

import {
    HISTORY_FAIL,
    HISTORY_REQUEST,
    HISTORY_SUCCESS
} from '../actions/history';

const initialState = {
    historyData: {},
    historyStatus:'',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case HISTORY_REQUEST: {
            return {
                ...state,
                historyStatus: 'request',
                historyData: {},
            };
        }
        case HISTORY_SUCCESS: {
            return {
                ...state,
                historyStatus: 'success',
                historyData: action.payload.data,
            };
        }
        case HISTORY_FAIL: {
            return {
                ...state,
                historyStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

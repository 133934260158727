import React, {useCallback, useEffect, useState} from 'react';
import arm from '../assets/img/site/usa.png'
import PhoneInput from "react-phone-number-input";
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import CallEndIcon from '@mui/icons-material/CallEnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStop } from '@fortawesome/free-solid-svg-icons'
import DialpadIcon from '@mui/icons-material/Dialpad';
import PauseIcon from '@mui/icons-material/Pause';


function CallScreenOut({setCall,incomeCall,caller,newSession,setAccept, callOut, accept, session,setCaller}) {
    // const [second, setAccept] = useState()
    const [time, setTime] = useState({ minutes: 0, seconds: 0 });

    useEffect(() => {
        let timerID
        if(newSession){
            timerID = setInterval(() => {
                setTime((prevTime) => {
                    let seconds = prevTime.seconds + 1;
                    let minutes = prevTime.minutes;

                    if (seconds === 60) {
                        seconds = 0;
                        minutes += 1;
                    }

                    return { minutes, seconds };
                });
            }, 1000);
        }
        else if(accept){
            timerID = setInterval(() => {
                setTime((prevTime) => {
                    let seconds = prevTime.seconds + 1;
                    let minutes = prevTime.minutes;

                    if (seconds === 60) {
                        seconds = 0;
                        minutes += 1;
                    }

                    return { minutes, seconds };
                });
            }, 1000);
        }

        return () => {
            clearInterval(timerID);
        };
    }, [accept,newSession]);

    const [mute, setMute] = useState(true)
    const [onHold, setOnHold] = useState(false)


    const handleMuteUnmute = useCallback(() => {
        if (newSession && newSession.sessionDescriptionHandler) {
            const localAudioTracks = newSession.sessionDescriptionHandler.peerConnection.getSenders().find(sender => sender.track && sender.track.kind === 'audio');

            if (localAudioTracks) {
                localAudioTracks.track.enabled = !localAudioTracks.track.enabled;
                setMute(localAudioTracks.track.enabled)
            }
        }else if(session && session.sessionDescriptionHandler) {
            const localAudioTracks = session.sessionDescriptionHandler.peerConnection.getSenders().find(sender => sender.track && sender.track.kind === 'audio');

            if (localAudioTracks) {
                localAudioTracks.track.enabled = !localAudioTracks.track.enabled;
                setMute(localAudioTracks.track.enabled)
            }
        }
    }, [newSession,session])

    const handleOnHold = useCallback(() =>{
        let sessionToHold
        if(newSession) {
            sessionToHold = newSession
        }else{
            sessionToHold = session

        }
        if(sessionToHold.isOnHold === true) {
            const sessionDescriptionHandlerOptions = sessionToHold.sessionDescriptionHandlerOptionsReInvite;
            sessionDescriptionHandlerOptions.hold = false;
            sessionToHold.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

            var options = {
                requestDelegate: {
                    onAccept: function(){
                        if(sessionToHold && sessionToHold.sessionDescriptionHandler && sessionToHold.sessionDescriptionHandler.peerConnection){
                            var pc = sessionToHold.sessionDescriptionHandler.peerConnection;
                            // Restore all the inbound streams
                            pc.getReceivers().forEach(function(RTCRtpReceiver){
                                if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = true;
                            });
                            // Restore all the outbound streams
                            pc.getSenders().forEach(function(RTCRtpSender){
                                // Unmute Audio
                                if(RTCRtpSender.track && RTCRtpSender.track.kind === "audio") {
                                    if(RTCRtpSender.track.IsMixedTrack === true){
                                        if(sessionToHold.data.AudioSourceTrack && sessionToHold.data.AudioSourceTrack.kind === "audio"){
                                            console.log("Unmuting Mixed Audio Track : "+ sessionToHold.data.AudioSourceTrack.label);
                                            sessionToHold.data.AudioSourceTrack.enabled = true;
                                        }
                                    }
                                    console.log("Unmuting Audio Track : "+ RTCRtpSender.track.label);
                                    RTCRtpSender.track.enabled = true;
                                }
                            });
                        }
                        sessionToHold.isOnHold = false;
                    },
                    onReject: function(){
                        sessionToHold.isOnHold = true;
                        console.warn("Failed to put the call on hold", lineNum);
                    }
                }
            };
            sessionToHold.invite(options).catch(function(error){
                sessionToHold.isOnHold = true;
                console.warn("Error attempting to take to call off hold", error);
            });
            setOnHold(false)

        }
        else{
            sessionToHold.isOnHold = true;

            const sessionDescriptionHandlerOptions = sessionToHold.sessionDescriptionHandlerOptionsReInvite;
            sessionDescriptionHandlerOptions.hold = true;
            sessionToHold.sessionDescriptionHandlerOptionsReInvite = sessionDescriptionHandlerOptions;

            const options = {
                requestDelegate: {
                    onAccept: function(){
                        if(sessionToHold && sessionToHold.sessionDescriptionHandler && sessionToHold.sessionDescriptionHandler.peerConnection){
                            const pc = sessionToHold.sessionDescriptionHandler.peerConnection;
                            // Stop all the inbound streams
                            pc.getReceivers().forEach(function(RTCRtpReceiver){
                                if (RTCRtpReceiver.track) RTCRtpReceiver.track.enabled = false;
                            });
                            // Stop all the outbound streams (especially useful for Conference Calls!!)
                            pc.getSenders().forEach(function(RTCRtpSender){
                                // Mute Audio
                                if(RTCRtpSender.track && RTCRtpSender.track.kind === "audio") {
                                    if(RTCRtpSender.track.IsMixedTrack === true){
                                        if(sessionToHold.data.AudioSourceTrack && sessionToHold.data.AudioSourceTrack.kind === "audio"){
                                            console.log("Muting Mixed Audio Track : "+ sessionToHold.data.AudioSourceTrack.label);
                                            sessionToHold.data.AudioSourceTrack.enabled = false;
                                        }
                                    }
                                    console.log("Muting Audio Track : "+ RTCRtpSender.track.label);
                                    RTCRtpSender.track.enabled = false;
                                }
                                // Stop Video
                            });
                        }
                        sessionToHold.isOnHold = true;
                        console.log("Call is is on hold:");

                    },
                    onReject: function(){
                        sessionToHold.isOnHold = false;
                    }
                }
            };
            sessionToHold.invite(options).catch(function(error){
                sessionToHold.isOnHold = false;

                console.warn("Error attempting to put the call on hold:", error);
            });
            setOnHold(true)

        }

    },[newSession,session])

    useEffect(() => {
        if(incomeCall){
            setCall(false)
        }
    },[incomeCall])


    const handleCancel = useCallback(() => {
        console.log(session,newSession)
        if(newSession){
            if (newSession.state === 'Established') {
                newSession.bye();

                // Stopping the microphone tracks
            } else {
                newSession.reject();
            }
        }else{
            if (session.state === 'Established') {
                session.bye();

                // Stopping the microphone tracks
            } else {
                session.cancel();
            }
        }

        if(setCaller){
            setCaller('')

        }
        if(setAccept){
            setAccept(false)

        }

        document.getElementById('callMusicId').pause()
        setCall(false)
    }, [session,newSession])
    return (
        <div className="callScreen" style={{height:510,width: 300,margin:'auto'}}>
            <div className="d-flex justify-content-center align-items-center flex-column" style={{
                background: '#adc9ee',
                height: '80%',
            }}>
                <figure className="call-animation">
                    <img className="img" src={arm} alt=""/>
                </figure>
                <div className="d-flex flex-column align-items-center">
                    {/*<b>{caller}</b>*/}
                    <span>{caller}</span>
                    {/*<span>Calling...</span>*/}
                    {newSession ? <span>{time.minutes < 10 ? '0' : ''}
                        {time.minutes}:{time.seconds < 10 ? '0' : ''}
                        {time.seconds}</span>:null}
                    {session && accept === false ? <span>Calling...</span>:session?<span>{time.minutes < 10 ? '0' : ''}
                        {time.minutes}:{time.seconds < 10 ? '0' : ''}
                        {time.seconds}</span>:null}
                </div>
            </div>

            <div style={{height: '20%', background:'white'}} className="d-flex align-items-center justify-content-around flex-column">
                <div className="w-100 d-flex justify-content-around align-items-center">
                <span
                    onClick={() =>handleMuteUnmute()}
                    style={{fontSize:12,cursor:'pointer'}}
                    className="d-flex flex-column align-items-center">
                    {mute?<MicOffIcon style={{cursor:'pointer'}}/>:<MicIcon style={{color:"#64f556",cursor:'pointer'}}/>}
                    Mute
                </span>
                {/*    <span*/}
                {/*    style={{fontSize:12}}*/}
                {/*        className="d-flex flex-column align-items-center">*/}
                {/*    <DialpadIcon/>*/}
                {/*    Keyboard*/}
                {/*</span>*/}
                    <span
                        onClick={handleOnHold}
                        style={{fontSize:12,cursor:'pointer'}}
                        className="d-flex flex-column align-items-center">
                        {!onHold?<FontAwesomeIcon icon={faStop} />:<PauseIcon style={{cursor:'pointer',color:"#64f556"}}/>}
                    Hold
                </span>
                </div>
                <span className="callBTN red" onClick={handleCancel}><CallEndIcon/> End</span>
            </div>
        </div>
    );
}

export default CallScreenOut;

import React, {useCallback, useEffect, useState} from 'react';
import {company, global, tasks} from '../translate'
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate, useParams} from "react-router";
import {Helmet} from "react-helmet";
import TaskCreate from "../components/TaskCreate";
import {
    commentPCreateRequest,
    commentPDeleteRequest,
    processEditRequest,
    processSingleRequest
} from "../store/actions/process";
import Loader from "../components/Loader";
import {processListStatus} from "../menu";
import { REACT_APP_API_URL } from '../config';
import {commentCreateRequest, commentDeleteRequest} from "../store/actions/company";
import {profileUserRequest} from "../store/actions/users";
import ProcessHistory from "../components/ProcessHistory";
function ProcessSingle() {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams();
    const [isProcess, setIsProcess] = useState(0)
    const [toSave, setToSave] = useState(true)
    const [processData, setProcessData] = useState({
        budget: 0,
        title:'',
        description:'',
        company: {},
        company_id: 7,
        status:''
    })
    const [createTask, setCreateTask] = useState(false)
    const processSingleData = useSelector(state => state.process.processSingleData)
    const lang = useSelector(state => state.users.lang)
    const profileData = useSelector(state => state.users.userData)
    const processSingleStatus = useSelector(state => state.process.processSingleStatus)
    const [comment, setComment] = useState({
        id: new Date(),
        setter: profileData?.email,
        description: '',
    })
    const [allComments, setAllComments] = useState([])
    const [open, setOpen] = useState(false)

    const handleCommentDelete = useCallback(async (id) => {
        if (typeof id !== 'object') {
            setAllComments(allComments.filter(a => a.id !== id));
            await dispatch(commentPDeleteRequest(id))
        } else {
            setAllComments(allComments.filter(a => a.id !== id));
        }
    }, [allComments])
    const handleCommentAdd = useCallback(async () => {
        setAllComments((prev) => ([
            ...prev,
            comment
        ]))
        await dispatch(commentPCreateRequest(params.id, comment))
        setComment({
            id: new Date(),
            setter: profileData.email,
            description: '',
        })
    }, [comment, profileData,params])
    useEffect(() => {
        (async () => {
            await dispatch(processSingleRequest(params.id));
        })();
    }, [params.id]);
    useEffect(() => {
        setProcessData(processSingleData)
        setToSave(false)
        setAllComments(processSingleData?.process_comments)
    }, [processSingleData]);

    const handleChange = useCallback((name, ev) => {

        setProcessData((prev) => ({
            ...prev,
            [name]: ev.target.value
        }))
    }, [])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        await dispatch(processEditRequest(processData.id, processData, 1, 10, ''))
        navigate('/process/line')
    }, [processData])

    const handleKeyDown = useCallback( (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            handleSubmit(event).then(); // Manually trigger your form submission logic
        }
    },[processData]);
    const handleComment = useCallback((name, ev) => {
        setComment((prev) => ({
            ...prev,
            setter:profileData?.email,
            [name]: ev,
        }));
    }, [profileData])
    return (
        <>
            <Helmet>
                <title>Process</title>
            </Helmet>
                {/*<header className="taskFilter">*/}
                {/*    <div className="menuLink">*/}
                {/*        <NavLink to={`/process/line`}>*/}
                {/*            <FontAwesomeIcon icon={faBarsProgress}/>*/}
                {/*        </NavLink>*/}
                {/*        <NavLink to='/process/list'>*/}
                {/*            <FontAwesomeIcon icon={faBarsStaggered}/>*/}
                {/*        </NavLink>*/}
                {/*    </div>*/}
                {/*</header>*/}
                {processSingleStatus === 'request' ? <div className="loaderBack">
                        <Loader/>
                    </div> :
                    <div className="row">
                        <form onKeyDown={handleKeyDown} onSubmit={handleSubmit} style={{ width: 400}}
                             className="process col-lg-4 col-md-6 flex-column">
                            <div className="content_header">
                                <label className="d-flex">
                                    <span
                                        className="process_single"
                                    >{global.title[+lang]}</span>
                                    <input
                                        value={processData.title}
                                        onChange={((ev) => handleChange('title', ev))}
                                    />
                                </label>
                                <label className="d-flex">
                                    <span className="process_single">{global.desc[+lang]}</span>
                                    <textarea
                                        value={processData.description}
                                        onChange={((ev) => handleChange('description', ev))}/>
                                </label>
                                <label className="d-flex">
                                    <span className="process_single">{global.status[+lang]}</span>
                                    <select value={processData.status} onChange={(ev) => handleChange('status',ev)}>
                                        {processListStatus.map((p) => {
                                            if(!p.icon){
                                               return (
                                                    <option key={p.id} style={{color:'black'}} value={p.id}>{p.text[+lang]}</option>
                                               )
                                            }
                                        })}
                                    </select>
                                </label>
                                <label className="d-flex">
                                    <span className="process_single">{global.created[+lang]}</span>
                                    <input
                                        disabled
                                        value={new Date(processSingleData.createdAt).toLocaleString('en-US', {
                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                            hour12: false,
                                        })}
                                        onChange={((ev) => handleChange('title', ev))}
                                    />
                                </label>
                            </div>
                            <div style={{padding: 20}} className="content_body">
                                <div className="left d-flex flex-column">
                                    <div>Company</div>
                                    <label className="process_border">Company title: {processData.company?.title}</label>
                                    <label className="process_border">Company description: {processData.company?.description}</label>
                                    <label className="process_border">Company phone: {processData.company?.phone}</label>

                                    <img style={{width: 50,borderRadius: '100%', height: 50, objectFit: 'cover',margin: 20}} src={REACT_APP_API_URL + processData.process_employee?.user?.image?.path} alt=""/>
                                    <label className="process_border">Employee: {processData.process_employee?.first_name} {processData.process_employee?.last_name}</label>
                                    <label className="process_border">Budget: {processData.budget}</label>
                                    <label className="process_border">Employee email: {processData.process_employee?.user?.email}</label>
                                    <label className="process_border">Department: {processData.process_employee?.department.title}</label>
                                    <hr/>
                                    <button
                                        className="taskBtn"
                                        onClick={handleSubmit}>{global.edit[+lang]}
                                    </button>
                                </div>
                                <div className="right">

                                </div>
                            </div>

                        </form>
                        <div className="task col-lg-8 col-md-6 flex-column">
                            <h4>{tasks.title[+lang]}</h4>

                            {processSingleData?.task_process?.length ? processSingleData?.task_process?.map((t) => (
                               <div key={t.id} style={{padding: 'inherit'}} className="d-flex flex-column">
                                   <span>Task title: {t.title}</span>
                                   <span>Task description: {t.description}</span>
                                   <span>Task deadline: {t.deadline}</span>
                                   <span>Task responsible: {t.employee_task.user.email}</span>
                                   <hr />
                               </div>
                            )) : <span>No data</span>}
                            <button
                                className="taskBtn"
                                onClick={() => {
                                    setCreateTask(true)
                                    setIsProcess(+params.id)
                                }}>+ {global.add[+lang]} {tasks.title[+lang]}
                            </button>
                            <hr/>
                            <span style={{border:'none'}}>{company.comment[+lang]}</span>

                            {allComments? allComments.map(c => (
                                <label key={c.id} className="d-flex flex-column">
                                    <p className="d-flex align-items-center justify-content-end">
                                        {profileData.email === c.setter || profileData.is_admin ?
                                            <span style={{cursor: 'pointer',border:'none',textAlign:'right'}}
                                                  onClick={() => handleCommentDelete(c.id)}>x</span> : null}
                                    </p>
                                    <textarea
                                        disabled
                                        style={{marginBottom: 5}}
                                        placeholder={`${global.enter[+lang]} ${company.title[+lang]} ${company.comment[+lang]}`}
                                        value={c.description}
                                        onChange={(ev) => null}/>
                                    <p>
                                        {c.createdAt ?
                                            <span style={{fontSize: 10, marginBottom: 20, display: 'inline-block'}}>Created at: {new Date(c.createdAt).toLocaleString('en-US', {
                                                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                                hour12: false,
                                            })}</span> : null}
                                        {c.setter ? <span style={{
                                            fontSize: 10,
                                            marginBottom: 20,
                                            border:'none',
                                            display: 'inline-block'
                                        }}>By: <span>{c.setter}</span></span> : null}
                                    </p>

                                </label>
                            )):null}
                            <label style={{marginBottom: 25}} className="d-flex flex-column">
                                <textarea
                                    placeholder={`${global.enter[+lang]} ${company.comment[+lang]}`}
                                    value={comment.description}
                                    onChange={(ev) => handleComment('description', ev.target.value)}/>
                            </label>

                            <button
                                className="taskBtn"
                                onClick={handleCommentAdd}> {global.add[+lang]}
                            </button>
                            <hr/>

                            <div
                                style={{textAlign:'center'}}
                                className="taskBtn"
                                onClick={() => setOpen(true)}> {global.view[+lang]}
                            </div>
                        </div>



                    </div>}
            <TaskCreate setIsProcess={setIsProcess} isProcess={isProcess} create={createTask}
                        setCreate={setCreateTask}/>
            <ProcessHistory data={processSingleData.comp_history} setCreate={setOpen} create={open} />
        </>
    );
}

export default ProcessSingle;

export const DEPARTMENT_REQUEST = 'DEPARTMENT_REQUEST';
export const DEPARTMENT_SUCCESS = 'DEPARTMENT_SUCCESS';
export const DEPARTMENT_FAIL = 'DEPARTMENT_FAIL';

export function departmentRequest() {
    return {
        type: DEPARTMENT_REQUEST,
        payload: {},
    };
}

export const COMPANY_STATUS_REQUEST = 'COMPANY_STATUS_REQUEST';
export const COMPANY_STATUS_SUCCESS = 'COMPANY_STATUS_SUCCESS';
export const COMPANY_STATUS_FAIL = 'COMPANY_STATUS_FAIL';

export function companyStatusRequest() {
    return {
        type: COMPANY_STATUS_REQUEST,
        payload: {},
    };
}

import React from 'react';
import { Inviter, SessionState, UserAgent } from "sip.js";
// Create user agent instance (caller)

function Sip(props) {
    const userAgent = new UserAgent({
        uri: UserAgent.makeURI("sip:1235@ats.am"),
        authorizationUsername:'1235',
        authorizationPassword:'1235',
        contactParams: {"transport":"wss"},
        transportOptions: {"server":"wss://ats.am:8089/ws","traceSip":false,"connectionTimeout":15},
    });
    const audio = new window.Audio()
// Connect the user agent
    userAgent.start().then(() => {
// Set target destination (callee)
        const target = UserAgent.makeURI("sip:1234@ats.am");
        if (!target) {
            throw new Error("Failed to create target URI.");
        }
            const inviter = new Inviter(userAgent, target, {
                sessionDescriptionHandlerOptions: {
                    constraints: { audio: true, video: false }
                },
                requestDelegate: {
                    onAccept: () => {
                        audio.srcObject = new MediaStream([inviter.sessionDescriptionHandler.localMediaStream]);
                        audio.play();
                    },
                }
            });

        console.log(inviter)
// Handle outgoing session state changes
            inviter.stateChange.addListener((newState) => {
                switch (newState) {
                    case SessionState.Establishing:
                        console.log('Establishing')
                        // Session is establishing
                        break;
                    case SessionState.Established:
                        // Session has been established
                        console.log('Established')

                        break;
                    case SessionState.Terminated:
                        // Session has terminated
                        console.log('Terminated')

                        break;
                    default:
                        break;
                }
            });
// Send initial INVITE request
            inviter.invite()
                .then(() => {
                    // INVITE sent
                    console.log('invite')
                })
                .catch((error) => {
                    console.log('error')

                });
        })
    return (
        <div></div>
    );
}

export default Sip;

import axios from 'axios';
import Account from './helpers/Account';
// const REACT_APP_API_URL = 'http://localhost:4000'

import {REACT_APP_API_URL} from './config';

const api = axios.create({
    // baseURL: 'http://10.227.0.86:4000',
    baseURL: REACT_APP_API_URL,
    // baseURL: 'http://localhost:4000',
    'Access-Control-Allow-Origin': REACT_APP_API_URL
});

const updateAndCreateApi = axios.create({
    // baseURL: 'http://10.227.0.86:4000',
    baseURL: REACT_APP_API_URL,
    // baseURL: 'http://localhost:4000',
});


api.interceptors.request.use(
    // eslint-disable-next-line no-unused-vars
    (config) => {
        config.headers = {
            Authorization: `Bearer ${Account.getAdminToken()}`,
        };
        return config;
    },
    (error) => Promise.resolve({error}),
);

updateAndCreateApi.interceptors.request.use(
    // eslint-disable-next-line no-unused-vars
    (config) => {
        config.headers = {
            Authorization: `Bearer ${Account.getAdminToken()}`,
            'Content-Type': 'multipart/form-data',
        };
        return config;
    },
    (error) => Promise.resolve({error}),
);
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            if (!isRefreshing) {
                isRefreshing = true;

                try {
                    const { data } = await api.post('users/refresh', { token: Account.getAdminRefreshToken() });

                    Account.setAdminToken(data);
                    isRefreshing = false;
                    processQueue(null, data.accessToken);

                    return api(originalRequest);
                } catch (e) {
                    Account.logoutAdmin();
                    processQueue(e, null);
                    return Promise.reject(error);
                } finally {
                    isRefreshing = false;
                }
            } else {
                // If a refresh request is already pending, add the original request to the failedQueue
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                }).then((token) => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return api(originalRequest);
                }).catch((err) => {
                    return Promise.reject(err);
                });
            }
        } else if (error.response.status === 405) {
            if (window.location.pathname !== '/login') {
                window.location.pathname = '/login';
                Account.logoutAdmin();
            }
            return Promise.reject(error);
        } else {
            return Promise.reject(error);
        }
    }
);
// api.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         if (error.response.status === 401) {
//             try {
//                 const {data} = await api.post('users/refresh', {token: Account.getAdminRefreshToken()});
//                 Account.setAdminToken(data);
//                 // window.location.reload(false)
//             } catch (e) {
//                 Account.logoutAdmin()
//             }
//
//         }
//         else if(error.response.status === 405){
//             if (window.location.pathname !== '/login') {
//                 window.location.pathname = '/login'
//                 Account.logoutAdmin()
//             }
//
//             return Promise.reject(error);
//         }else{
//             return Promise.reject(error);
//         }
//     },
// );

class Api {

    static login(form) {
        return api.post('users/login', form);
    }

    static check(form) {
        return api.get(`users/check_auth?phone=${form.phone}&cash=${form.cash}`);
    }

    static getIncluded(form) {
        return api.get(`company/get_included`);
    }

    static registerSip(form) {
        return api.post(`users/sip_reg`, form);
    }

    static verifySip(form) {
        return api.post(`users/verify`, form);
    }

    static sip() {
        return api.get('users/sip')
    }

    static sipDelete(buddy) {
        return api.get(`users/sip_delete?buddy=${buddy}`)
    }

    static sipCreate(form) {
        return api.post('users/sip_create', form)
    }

    static sipExpUpdate(form) {
        return api.post('api/update', form)
    }

    static sipExpCreate(form) {
        return api.post('api/create', form)
    }

    static register(form) {
        return axios({
            headers: {
                Authorization: `Bearer ${Account.getAdminToken()}`,
                'content-type': 'multipart/form-data',
            },
            method: 'post',
            url: `${REACT_APP_API_URL}/users/register`,
            data: form,
        }).then((response) => response)
    }


    static profile() {
        return api.get('/users/profile');
    }

    static loginAsUser(id, original, key) {
        return api.post(`/users/loginAsEmployee`, {id, original, key});
    }

    static logout() {
        return api.post('/logout');
    }

    static getDepartment() {
        return api.get("/department/get");
    }

    static getStatus() {
        return api.get("/department/get_status");
    }

    static getCompany(rowsPerPage = 10, page = 1, search = '', startTime, endTime, searchByManager, status, phoneFil, isTest, isBlocked,) {
        return api.get(`company/get?page=${page}&limit=${rowsPerPage}&search=${search}&startTime=${startTime || ''}&endTime=${endTime || ''}&searchByManager=${searchByManager || ''}&status=${status || ''}&phoneFil=${phoneFil || ''}&isTest=${isTest || ''}&isBlocked=${isBlocked || ''}`);
    }

    static getCompanySingle(phone) {
        return api.get(`company/single?phone=${phone}`);
    }

    static getCompanySingleId(id) {
        return api.get(`company/singleid?id=${id}`);
    }

    static createCompany(data, page, limit, search, startTime, endTime, searchByManager, status, phoneFil, isTest, isBlocked,) {
        return api.post(`company/create?page=${page}&limit=${limit}&search=${search}&startTime=${startTime || ''}&endTime=${endTime || ''}&searchByManager=${searchByManager || ''}&status=${status || ''}&phoneFil=${phoneFil || ''}&isTest=${isTest || ''}&isBlocked=${isBlocked || ''}`, data);
    }

    static createComment(id, arr) {
        return api.post(`company/comment?id=${id || ''}`, {arr});
    }

    static createReminder(id, arr) {
        return api.post(`company/createMemory?id=${id || ''}`, arr);
    }

    static deleteCompany(id) {
        return api.post(`company/delete`, {id});
    }

    static deleteComment(id) {
        return api.post(`company/commentDelete`, {id});
    }

    static deleteReminder(id) {
        return api.post(`company/reminderDelete`, {id});
    }

    static deletePhone(id) {
        return api.post(`company/deletePhone`, {id});
    }

    static updateCompany(id, data, page, limit, search, startTime, endTime, searchByManager, status, phoneFil, isTest, isBlocked,) {
        return api.post(`company/update?page=${page}&limit=${limit}&search=${search}&startTime=${startTime || ''}&endTime=${endTime || ''}&searchByManager=${searchByManager || ''}&status=${status || ''}&phoneFil=${phoneFil || ''}&isTest=${isTest || ''}&isBlocked=${isBlocked || ''}`, {
            id,
            data
        });
    }

    static createExel(exel, page, limit, search, startTime, endTime, searchByManager, status, phoneFil, isTest, isBlocked,) {
        const formData = new FormData();
        formData.append('exel', exel);
        return axios({

            headers: {
                Authorization: `Bearer ${Account.getAdminToken()}`,
                'content-type': 'multipart/form-data',
            },
            method: 'post',
            url: `${REACT_APP_API_URL}/company/createExel?page=${page}&limit=${limit}&search=${search}&startTime=${startTime || ''}&endTime=${endTime || ''}&searchByManager=${searchByManager || ''}&statusFil=${status || ''}&phoneFil=${phoneFil || ''}&isTest=${isTest || ''}&isBlocked=${isBlocked || ''}`,
            data: formData,
        }).then((response) => response)
    }
    static editExel(exel) {
        const formData = new FormData();
        formData.append('exel', exel);
        return axios({

            headers: {
                Authorization: `Bearer ${Account.getAdminToken()}`,
                'content-type': 'multipart/form-data',
            },
            method: 'post',
            url: `${REACT_APP_API_URL}/company/updateExel`,
            data: formData,
        }).then((response) => response)
    }


    static getTask(rowsPerPage = 10, page = 1, search = '', filter = '') {
        return api.get(`task/get?page=${page || 1}&limit=${rowsPerPage || 20}&search=${search || ''}&filter=${filter || ''}`);
    }

    static createTask(data, page, limit, search, filter, process_id) {
        return api.post(`task/create?page=${page || 1}&limit=${limit || 20}&search=${search || ''}&filter=${filter}&process_id=${process_id}`, data);
    }

    static allTask(data, page, limit, search, action) {
        return api.post(`task/all?page=${page || 1}&limit=${limit || 10}&search=${search || ''}&action=${action}`, data);
    }

    static deleteTask(id, responsible, filter, page, limit,) {
        return api.post(`task/delete?page=${page || 1}&limit=${limit || 1000}`, {id, responsible, filter});
    }

    static updateTask(id, data, page = 1, limit = 10, search = '', filter = '') {
        return api.post(`task/update?page=${page || 1}&limit=${limit || 10}&search=${search || ''}&filter=${filter || ''}`, {
            id,
            data
        });
    }

    static getProcess(rowsPerPage = 10, page = 1, search = '') {
        return api.get(`process/get?page=${page}&limit=${rowsPerPage}&search=${search}`);
    }

    static createProcessComment(id, arr) {
        return api.post(`process/comment?id=${id || ''}`, arr);
    }

    static getSingleProcess(id) {
        return api.get(`process/single?id=${id}`);
    }

    static createProcess(data, page, limit, search) {
        return api.post(`process/create?page=${page}&limit=${limit}&search=${search}`, data);
    }

    static deleteProcess(id) {
        return api.post(`process/delete`, {id});
    }

    static deleteProcessComment(id) {
        return api.post(`process/commentDelete`, {id});
    }

    static updateProcess(id, data, page, limit, search) {
        return api.post(`process/update?page=${page}&limit=${limit}&search=${search}`, {id, data});
    }

    static getEmployee(rowsPerPage = 10, page = 1, search = '', type = '') {
        return api.get(`employee/get?page=${page}&limit=${rowsPerPage}&search=${search}&type=${type}`);
    }

    static createEmployee(data, page, limit, search) {
        return api.post(`employee/create?page=${page}&limit=${limit}&search=${search}`, data);
    }

    static deleteEmployee(id) {
        return api.post(`employee/delete`, {id});
    }

    static updateEmployee(id, data, page, limit, search) {
        return api.post(`employee/update?page=${page}&limit=${limit}&search=${search}`, {id, data});
    }

    static getClient(rowsPerPage = 10, page = 1, search) {
        return api.get(`clients/get?page=${page}&limit=${rowsPerPage}&search=${search}`);
    }

    static getClientSingle(phone) {
        return api.get(`clients/single?phone=${phone}`);
    }

    static createClient(data, limit, page, search) {
        return api.post(`clients/create?page=${page}&limit=${limit}&search=${search}`, data);
    }

    static deleteClient(id) {
        return api.post(`clients/delete`, {id});
    }

    static updateClient(id, data, limit, page, search) {
        return api.post(`clients/update?page=${page}&limit=${limit}&search=${search}`, {id, data});
    }

    static edit(form) {
        return axios({
            headers: {
                Authorization: `Bearer ${Account.getAdminToken()}`,
                'content-type': 'multipart/form-data',
            },
            method: 'post',
            url: `${REACT_APP_API_URL}/users/edit`,
            data: form,
        }).then((response) => response)
    }


    static history(rowsPerPage, page, search) {
        return api.get(`history/get?page=${page}&limit=${rowsPerPage}&phone=${search?.number || ''}&startTime=${search?.startTime || ''}&endTime=${search?.endTime || ''}&disposition=${search?.disposition || ''}&status=${search?.status || ''}`);
    }

    //
    // static getSingleLabel(id) {
    //   return api.get(`api/label/${id}`);
    // }
    //
    // static getLanguages() {
    //   return api.get('api/languages');
    // }
    //
    // static createUser(data) {
    //   return updateAndCreateApi({
    //     headers: {
    //       'content-type': 'multipart/form-data',
    //     },
    //     method: 'post',
    //     url: '/api/user',
    //     data,
    //   });
    // }
    //
    // static getUser(id) {
    //   return api.get(`api/user/${id}`);
    // }
    //
    // static getAppUser(page) {
    //   return api.get(`api/app_user?page=${page || 1}`);
    // }
    //
    // static getUserData(page = 1) {
    //   return api.get(`api/user?page=${page}`);
    // }
    //
    // static deleteUser(id) {
    //   return api.delete(`api/user/${id}`);
    // }
    //
    // static updateUser(id, data) {
    //   return updateAndCreateApi({
    //     headers: {
    //       'content-type': 'multipart/form-data',
    //     },
    //     method: 'post',
    //     url: `/api/user/${id}`,
    //     data,
    //   })
    //     .then((response) => console.log(response))
    //     .catch((error) => console.log(error, 99));
    //   // return api.post(`api/user/${id}`, data, {
    //   //   headers: {
    //   //     'content-type': 'multipart/form-data',
    //   //   },
    //   // });
    // }
}

export default Api;

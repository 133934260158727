import React from 'react';
import {FallingLines} from "react-loader-spinner";

function Loader(props) {
    return (
        <FallingLines
            color="#8dd7f1"
            width="100"
            visible={true}
            ariaLabel='falling-lines-loading'
        />
    );
}

export default Loader;

import React, {useCallback, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch} from "react-redux";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {toast} from "react-toastify";
import qs from "query-string";
import {useLocation} from "react-router";
import {companyCreateExelRequest} from "../store/actions/company";
import Api from "../Api";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function CompanyExel({create, setCreate}) {
    const location = useLocation();

    const [file,setFile] = useState(null)
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleSubmit = useCallback(async () => {
        if(create === 'create'){
            await dispatch(companyCreateExelRequest(file,query.page || 1, query.limit || 10, query.search || '',query.startTime || '',new Date() || '',query.searchByManager || '',))

        }else if(create === 'edit'){
            await Api.editExel(file)
        }
        setCreate('')
    }, [file,location.search,create])

    const handleChange = useCallback((ev) => {
        if('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === ev.target.files[0].type){
            setFile(ev.target.files[0])
        }else{
            toast.error('File should be exel')
        }
    }, [])
    const dispatch = useDispatch()
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create}
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <div className="createHeader">
                                <span style={{cursor: 'pointer'}} onClick={() => setCreate(false)}>X</span>
                            </div>

                        </Typography>
                        <div className="createDiv">
                            <p className="deleteTitle">Upload exel file</p>
                            <span style={{margin:'20px 0', display:'inline-block',textAlign:'center'}}>It should have only <b>Title ,Description, phone number in 374XXXXXXXX format. and status: 1 if user and 2 if not </b>
                                First title then description and at the end phone number</span>
                                <input style={{width:'100%'}} type="file" onChange={handleChange}/>
                            <div className="deleteBtnBlock">
                                <button onClick={handleSubmit} className="btn_no">Upload</button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default CompanyExel;

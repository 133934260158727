import { combineReducers } from 'redux';
import users from './users';
import company from './company';
import client from './client';
import department from './department';
import employee from './employee';
import history from './history';
import task from './task';
import process from './process';

export default combineReducers(
  {
    users, company, client, department, employee,history,task,process
  },
);

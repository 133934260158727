import {all, fork} from 'redux-saga/effects';
import users from './users';
import company from './company';
import client from './client';
import department from './department';
import employee from './employee';
import history from './history';
import task from "./task";
import process from "./process";
import sip from "./sip";


export default function* watchers() {
    yield all([
        users,
        company,
        client,
        department,
        employee,
        history,
        task,
        process,
        sip
    ].map(fork));
}

import * as React from 'react';
import Popper from '@mui/material/Popper';
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import classNames from "classnames";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import {taskRequest} from "../store/actions/task";
import {processRequest} from "../store/actions/process";

export default function ProcessDrop({line}) {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const userData = useSelector((state) => state.users.userData)
    const handleFilter = useCallback(async (val,popupState) => {
        query.page = '1'
        query.search = val
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        popupState.close();

        if(line){
            await dispatch(processRequest(1000, 1, query.search || ''))
        }else{
            await dispatch(processRequest(query.limit, 1, query.search || '' ))
        }
    }, [location.search,line])

    return (
       <>
           <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
               {(popupState) => (
                   <div style={{overflow:'hidden', width:'100%'}}>
                       <div style={{cursor:'pointer'}} className="d-flex align-items-center w-100" {...bindToggle(popupState)}>
                           <span className="d-flex w-100" style={{height: 20}} />
                       </div>
                       <Popper className="profile__down" style={{width:'60%'}} {...bindPopper(popupState)} transition>
                           {({TransitionProps}) => (
                               <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                   <Paper>
                                       <span className="d-flex justify-content-end" {...bindToggle(popupState)} style={{fontSize:16,cursor:'pointer', padding:10}}>x</span>
                                       <div className="d-flex flex-row">
                                          <ul className="profilePopUp">
                                              <li {...bindToggle(popupState)} className={classNames("popLi",{active:query.search === 'all'})} onClick={() => handleFilter('all',popupState)}>
                                                  All processes
                                              </li>
                                              {+userData.is_admin ?<li {...bindToggle(popupState)} className={classNames("popLi",{active:query.search === 'myProcess'})} onClick={() => handleFilter('myProcess',popupState)}>
                                                  My processes
                                              </li>:null}
                                              <li {...bindToggle(popupState)} className={classNames("popLi",{active:query.search === 'success'})} onClick={() => handleFilter('success',popupState)}>
                                                  Successfully done
                                              </li>
                                              <li {...bindToggle(popupState)} className={classNames("popLi",{active:query.search === 'notRealised'})} onClick={() => handleFilter('notRealised',popupState)}>
                                                  Not realised processes
                                              </li>
                                              <li {...bindToggle(popupState)} className={classNames("popLi",{active:query.search === 'dead'})} onClick={() => handleFilter('dead',popupState)}>
                                                  Processes with expired deadlines
                                              </li>

                                          </ul>
                                      </div>
                                   </Paper>
                               </Fade>
                           )}
                       </Popper>
                   </div>
               )}
           </PopupState>
       </>
    );
}

import React, {useCallback, useRef, useState} from 'react';

import {numberPad} from "../number-pad";
import CallIcon from "@mui/icons-material/Call";
import {Inviter, UserAgent} from "sip.js";
import CallScreenOut from "./CallScreenOut";

function Dial({ua,toWho, setToWho,setCallOut,inviter}) {
    let lineObj = useRef(null)
    let audioTrack = useRef(null)
    const [callIn, setCallIn] = useState(false)
    const [callOutWrapper, setCallOutWrapper] = useState(false)
    const handleNumberChange = useCallback((n) => {
        setToWho(toWho + n)
    }, [toWho])

    // const audio = new window.Audio()
    const handleCall = useCallback(() => {
        if(ua.current == null) return;

        let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

        let spdOptions = {
            earlyMedia: true,
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: { deviceId : "default" },
                    video: false
                }
            }
        }
        // Add additional Constraints
        if(supportedConstraints.autoGainControl) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = true;
        }
        if(supportedConstraints.echoCancellation) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = true;
        }
        if(supportedConstraints.noiseSuppression) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = true;
        }
        let targetURI = UserAgent.makeURI("sip:" + toWho + "@" + 'ats.am');
        lineObj.SipSession = new Inviter(ua.current,targetURI);
        lineObj.SipSession.data = {}
        lineObj.SipSession.data.calldirection = "outbound";
        lineObj.SipSession.data.dst = toWho;
        let inviterOptions = {
            extraHeaders: [
                'P-Api-Key: YourApiKeyHere', // Replace YourApiKeyHere with your actual API key value
                // ... (other headers if needed)
            ],
            requestDelegate: {
                onTrying: function(sip){
                    console.log('try',sip)
                },
                onProgress:function(sip){
                    console.log('onProgress',sip)

                },
                onRedirect:function(sip){
                    console.log('onRedirect',sip)

                },
                onAccept:function(sip){
                    console.log('onAccept',sip)

                },
                onReject:function(sip){
                    console.log('onReject',sip)

                }
            }
        }
        inviter.current = new Inviter(ua.current,targetURI,inviterOptions)
        inviter.current.invite().then(async () => {
            const localStream = await navigator.mediaDevices.getUserMedia({ audio: true })
            const audioTracks = localStream.getAudioTracks()[0];
            inviter.current.sessionDescriptionHandler.peerConnection.addTrack(audioTracks, localStream);
            const pc = inviter.current.sessionDescriptionHandler.peerConnection;
            pc.addEventListener('track', (event) => {
                const remoteTrack = event.track;
                if (remoteTrack.kind === 'audio') {
                    const remoteAudioStream = new MediaStream();
                    remoteAudioStream.addTrack(remoteTrack);
                    const audioElement = new Audio();
                    audioElement.srcObject = remoteAudioStream;
                    audioElement.play();
                }
            });
        });
        setCallOut(true)

    }, [ua,toWho,lineObj])

    return (
        <div className="dialScreen" style={{height: 510,width: 248,}}>
            <div className="d-flex justify-content-center align-items-center flex-column" style={{
                background: '#adc9ee',
                height: '72%',
            }}>
                <div className="d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <div style={{width: 183,
                            height: 250,}}>
                            <div
                                className="d-flex justify-content-center"
                            ><input value={toWho} onChange={(ev) => setToWho(ev.target.value)}/></div>
                            <div className="dial">
                                {numberPad.map((n) => <div onClick={() => handleNumberChange(n)} key={n}>{n}</div>)}
                                <div onClick={() => handleNumberChange('*')}>*</div>
                                <div onClick={() => handleNumberChange('0')}>0</div>
                                <div onClick={() => handleNumberChange('#')}>#</div>
                            </div>
                        </div>
                        <button
                            className="call_btn"
                            onClick={handleCall}><CallIcon/></button>
                    </div>
                </div>


            </div>
        </div>

    );
}

export default Dial;

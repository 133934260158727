export const TASK_REQUEST = 'TASK_REQUEST';
export const TASK_SUCCESS = 'TASK_SUCCESS';
export const TASK_FAIL = 'TASK_FAIL';

export function taskRequest(rowsPerPage,page,search,filter) {
    return {
        type: TASK_REQUEST,
        payload: {rowsPerPage,page,search,filter },
    };
}
export const TASK_EDIT_REQUEST = 'TASK_EDIT_REQUEST';
export const TASK_EDIT_SUCCESS = 'TASK_EDIT_SUCCESS';
export const TASK_EDIT_FAIL = 'TASK_EDIT_FAIL';

export function taskEditRequest(id, formData, page, limit, search,filter) {
    return {
        type: TASK_EDIT_REQUEST,
        payload: {id, formData, page,limit,search,filter},
    };
}

export const TASK_DELETE_REQUEST = 'TASK_DELETE_REQUEST';
export const TASK_DELETE_SUCCESS = 'TASK_DELETE_SUCCESS';
export const TASK_DELETE_FAIL = 'TASK_DELETE_FAIL';

export function taskDeleteRequest(id,responsible,page,limit,search, filter) {
    return {
        type: TASK_DELETE_REQUEST,
        payload: {id, responsible,search, filter,page,limit,},
    };
}
export const TASK_CREATE_REQUEST = 'TASK_CREATE_REQUEST';
export const TASK_CREATE_SUCCESS = 'TASK_CREATE_SUCCESS';
export const TASK_CREATE_FAIL = 'TASK_CREATE_FAIL';

export function taskCreateRequest(form, page, limit, search,filter,process_id) {
    return {
        type: TASK_CREATE_REQUEST,
        payload: {form,page,limit,search,filter,process_id},
    };
}




export const TASK_ALL_REQUEST = 'TASK_ALL_REQUEST';
export const TASK_ALL_SUCCESS = 'TASK_ALL_SUCCESS';
export const TASK_ALL_FAIL = 'TASK_ALL_FAIL';

export function taskAllRequest(form, page, limit, search,actions, filter) {
    return {
        type: TASK_ALL_REQUEST,
        payload: {form,page,limit,search,actions,filter},
    };
}





import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {
    ArcElement,
    CategoryScale,
    Chart as ChartJS,
    Chart,
    Filler,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import {Line, Pie} from 'react-chartjs-2';
import faker from 'faker';
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TableBody from "@mui/material/TableBody";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";
import Utils from "../helpers/Utils";
import {Helmet} from "react-helmet";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

ChartJS.register(ArcElement, Tooltip, Legend);

export const dataPie = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
    labels,
    datasets: [
        {
            fill: true,
            label: 'Dataset 2',
            data: labels.map(() => faker.datatype.number({min: 0, max: 1000})),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        },
    ],
};

Chart.defaults.color = "#929292";

function Home(props) {
    // const [isRecording, setIsRecording] = useState(false)
    // const [second, setSecond] = useState(0)
    // const [time, setTime] = useState(0)
    // const columns = [
    //     {id: 'name', label: 'Name', minWidth: 170},
    //     {id: 'code', label: 'ISO\u00a0Code', minWidth: 100},
    //     {
    //         id: 'population',
    //         label: 'Population',
    //         minWidth: 170,
    //         align: 'right',
    //         format: (value) => value.toLocaleString('en-US'),
    //     },
    //     {
    //         id: 'size',
    //         label: 'Size\u00a0(km\u00b2)',
    //         minWidth: 170,
    //         align: 'right',
    //         format: (value) => value.toLocaleString('en-US'),
    //     },
    //
    //     {
    //         id: 'density',
    //         label: 'Density',
    //         minWidth: 170,
    //         align: 'right',
    //         format: (value) => value.toFixed(2),
    //     },
    //     {id: 'dd', label: 'ISO\u00a0Code', align: 'center', minWidth: 100},
    //
    // ];
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function createData(name, code, population, size) {
        const density = population / size;
        return {name, code, population, size, density};
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const rows = [
        createData('India', 'IN', 1324171354, 3287263),
        createData('China', 'CN', 1403500365, 9596961),
        createData('Italy', 'IT', 60483973, 301340),
        createData('United States', 'US', 327167434, 9833520),
        createData('Canada', 'CA', 37602103, 9984670),
        createData('Australia', 'AU', 25475400, 7692024),
        createData('Germany', 'DE', 83019200, 357578),
        createData('Ireland', 'IE', 4857000, 70273),
        createData('Mexico', 'MX', 126577691, 1972550),
        createData('Japan', 'JP', 126317000, 377973),
        createData('France', 'FR', 67022000, 640679),
        createData('United Kingdom', 'GB', 67545757, 242495),
        createData('Russia', 'RU', 146793744, 17098246),
        createData('Nigeria', 'NG', 200962417, 923768),
        createData('Brazil', 'BR', 210147125, 8515767),
    ];
    // useEffect(() => {
    //      if(isRecording){
    //          let timer = setInterval(() => {
    //              setSecond(second + 1)
    //              setTime(Utils.convertHMS(second + 1))
    //          }, 1000)
    //
    //          return () => clearInterval(timer)
    //      }
    // }, [second,isRecording])


    return (
        <>
            <Helmet>
                <title>Homepage</title>
            </Helmet>
                <div style={{padding:25}} className="row justify-content-around">
                    {/*<div className="col-lg-6 ">*/}
                    {/*    <Paper sx = {{width: '100%',height: 300}}>*/}
                    {/*        <TableContainer sx = {{height: 245}}>*/}
                    {/*            <Table sx = {{height: 340}}>*/}
                    {/*                <TableHead>*/}
                    {/*                    <TableRow>*/}
                    {/*                        <TableCell align="left" colSpan={4}>*/}
                    {/*                            Short number*/}
                    {/*                        </TableCell>*/}
                    {/*                        <TableCell align="right" colSpan={2}>*/}
                    {/*                            <AddCircleOutlineIcon style={{cursor: 'pointer'}}*/}
                    {/*                                                  onClick={() => setCreate(true)}/>*/}
                    {/*                        </TableCell>*/}
                    {/*                    </TableRow>*/}
                    {/*                    <TableRow>*/}
                    {/*                        {columns.map((column) => (*/}
                    {/*                            <TableCell*/}
                    {/*                                key={column.id}*/}
                    {/*                                align={column.align}*/}
                    {/*                                style={{top: 57, minWidth: column.minWidth}}*/}
                    {/*                            >*/}
                    {/*                                {column.label}*/}
                    {/*                            </TableCell>*/}
                    {/*                        ))}*/}
                    {/*                    </TableRow>*/}
                    {/*                </TableHead>*/}
                    {/*                <TableBody>*/}
                    {/*                    {rows*/}
                    {/*                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                    {/*                        .map((row) => {*/}
                    {/*                            return (*/}
                    {/*                                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>*/}
                    {/*                                    {columns.map((column, i) => {*/}
                    {/*                                        if (i === columns.length - 1) {*/}
                    {/*                                            return (*/}
                    {/*                                                <TableCell colSpan={2} key={column.id}*/}
                    {/*                                                           align={column.align}>*/}
                    {/*                                                    <EditIcon*/}
                    {/*                                                        style={{fill: '#34cd34', cursor: 'pointer'}}*/}
                    {/*                                                        onClick={() => handleEdit(row.code)}/>*/}
                    {/*                                                    <DeleteIcon*/}
                    {/*                                                        style={{fill: '#c00909', cursor: 'pointer'}}*/}
                    {/*                                                        onClick={() => handleDelete(row.code)}/>*/}
                    {/*                                                </TableCell>*/}
                    {/*                                            );*/}
                    {/*                                        } else {*/}
                    {/*                                            const value = row[column.id];*/}
                    {/*                                            return (*/}
                    {/*                                                <TableCell key={column.id} align={column.align}>*/}
                    {/*                                                    {column.format && typeof value === 'number'*/}
                    {/*                                                        ? column.format(value)*/}
                    {/*                                                        : value}*/}
                    {/*                                                </TableCell>*/}
                    {/*                                            );*/}
                    {/*                                        }*/}
                    {/*                                    })}*/}
                    {/*                                </TableRow>*/}
                    {/*                            );*/}
                    {/*                        })}*/}
                    {/*                </TableBody>*/}
                    {/*            </Table>*/}
                    {/*        </TableContainer>*/}
                    {/*        <TablePagination*/}
                    {/*            rowsPerPageOptions={[10, 25, 100]}*/}
                    {/*            component="div"*/}
                    {/*            count={rows.length}*/}
                    {/*            rowsPerPage={rowsPerPage}*/}
                    {/*            page={page}*/}
                    {/*            onPageChange={handleChangePage}*/}
                    {/*            onRowsPerPageChange={handleChangeRowsPerPage}*/}
                    {/*        />*/}
                    {/*    </Paper>*/}
                    {/*</div>*/}
                    {/*<div style={{height:300}} className="col-lg-6 callBlockTime">*/}
                    {/*    <div>*/}
                    {/*        <button onClick={() => {*/}
                    {/*            setIsRecording(!isRecording)*/}
                    {/*        }} style={isRecording?{background:'red'}:{background:'green'}} className="button">{isRecording ? 'Stop' : 'Start'}</button>*/}
                    {/*        <div>Time: {time === 0?'00:00:00':time}</div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className='lineChart col-lg-6'>
                        <Line options={options} data={data}/>
                    </div>
                    <div className='lineChart col-lg-6'>
                        <Pie data={dataPie}/>
                    </div>
                </div>
        </>
    );
}

export default Home;

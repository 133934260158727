import * as React from 'react';
import Popper from '@mui/material/Popper';
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import {useCallback, useEffect, useState} from "react";
import TaskEdit from "./TaskEdit";
import Account from "../helpers/Account";
import {useSelector} from "react-redux";
import _ from 'lodash'
import HistoryIcon from '@mui/icons-material/History';

export default function CallHistoryNew({setWho}) {
    const [open,setOpen] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const handleLogout = useCallback(async () => {
        try {
            Account.logoutAdmin()
            window.location.reload(false);

        }catch (e) {
            console.log(e)
        }
    }, []);

    return (
        <>
            <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
                {(popupState) => (
                    <div style={{overflow:'hidden'}}>
                        <div style={{cursor:'pointer'}} className="d-flex align-items-center" {...bindToggle(popupState)}>
                            <HistoryIcon />
                        </div>
                        <Popper className="profile__down" style={{width:200}} {...bindPopper(popupState)} transition>
                            {({TransitionProps}) => (
                                <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                    <Paper>
                                        <ul className="profilePopUp">
                                            {JSON.parse(localStorage.getItem('callArray'))?
                                                JSON.parse(localStorage.getItem('callArray')).map((c) => (
                                                <li onClick={() => setWho(c)} key={_.uniqueId()} className="popLi">
                                                    {c}
                                                </li>
                                            )):<li className="popLi">No data</li>}

                                        </ul>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </div>
                )}
            </PopupState>
            <TaskEdit line={false} open={open} setOpen={setOpen}/>
        </>
    );
}

import * as React from 'react';
import Popper from '@mui/material/Popper';
import PopupState, {bindPopper, bindToggle} from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import classNames from "classnames";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import {taskRequest} from "../store/actions/task";

export default function FilterDrop({line}) {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const userData = useSelector((state) => state.users.userData)
    const handleFilter = useCallback(async (val,popupState) => {
        query.page = '1'
        query.search = val
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        popupState.close();

        if(line){
            await dispatch(taskRequest(1000, 1, query.filter || '', query.search || '' ))

        }else{
            await dispatch(taskRequest(query.limit, 1, query.filter || '', query.search || '' ))
        }
    }, [location.search,line])

    return (
       <>
           <PopupState variant="popper" popupId="demo-popup-popper" disableAutoFocus={true}>
               {(popupState) => (
                   <div style={{overflow:'hidden', width:'100%'}}>
                       <div style={{cursor:'pointer'}} className="d-flex align-items-center w-100" {...bindToggle(popupState)}>
                           <span className="d-flex w-100" style={{height: 20}} />
                       </div>
                       <Popper className="profile__down" style={{width:'60%'}} {...bindPopper(popupState)} transition>
                           {({TransitionProps}) => (
                               <Fade className="dropTheme" {...TransitionProps} timeout={350}>
                                   <Paper>
                                      <div className="d-flex flex-row">
                                          <ul className="profilePopUp">
                                              <li className={classNames("popLi",{active:query.search === 'all'})} onClick={() => handleFilter('all',popupState)}>
                                                  All
                                              </li>
                                              {+userData.is_admin ?<li className={classNames("popLi",{active:query.search === 'myTask'})} onClick={() => handleFilter('myTask',popupState)}>
                                                  my tasks
                                              </li>:null}

                                              <li className={classNames("popLi",{active:query.search === 'dead'})} onClick={() => handleFilter('dead',popupState)}>
                                                  deadline
                                              </li>
                                              <li  className={classNames("popLi",{active:query.search === 'done'})} onClick={() => handleFilter('done',popupState)}>
                                                  done
                                              </li>
                                              <li className={classNames("popLi",{active:query.search === 'deleted'})} onClick={() => handleFilter('deleted',popupState)}>
                                                  deleted
                                              </li>
                                          </ul>
                                          <div>
                                              <label>
                                                  <span>Sort by types</span>
                                                  <select>
                                                      <option value="meeting">Meeting</option>
                                                      <option value="contact">Contact</option>
                                                  </select>
                                              </label>
                                          </div>
                                      </div>
                                   </Paper>
                               </Fade>
                           )}
                       </Popper>
                   </div>
               )}
           </PopupState>
       </>
    );
}

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import {employeeCreateRequest, employeeEditRequest} from "../store/actions/employee";
import Loader from "./Loader";
import {useLocation} from "react-router";
import qs from "query-string";
import {departmentRequest} from "../store/actions/department";
import Select from "react-select";
import Utils from "../helpers/Utils";
import Api from "../Api";
import {profileUserRequest, sipRequest} from "../store/actions/users";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 610?window.innerWidth - 60 : 650,
    height: window.innerHeight < 600?window.innerHeight - 60 : 600,
    overflowY:'auto',
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function EmployeeCreateAndEdit({create, setCreate, edit, setEdit}) {
    const location = useLocation();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const employeeData = useSelector((state) => state.employee.employeeData)
    const sip = useSelector((state) => state.users.sip)
    const employeeStatus = useSelector((state) => state.employee.employeeStatus)
    const departmentData = useSelector((state) => state.department.departmentData)
    // const [previousSelectedOptions, setPreviousSelectedOptions] = useState([]);
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        notes: '',
        status: '1',
        department_id: "1",
        email:'',
        password:'',
        password_confirm:'',
        isAllowed:false,
        sip:[]
    })
    useEffect(() => {
        (async () => {
            await dispatch(departmentRequest())
            await dispatch(sipRequest())

        })()
        if (edit) {
            let other = []
            const final = {}
            const editItem = employeeData.employees.find((i) => i.id === edit)

            if(editItem?.user?.other_sip_user){
                editItem?.user?.other_sip_user?.map(o => {
                    other.push({value:o.sip, label:o.sip})
                })
                final.first_name = editItem.first_name
                final.phone = editItem.phone
                final.last_name = editItem.last_name
                final.department_id = editItem.department.id
                final.notes = editItem.notes
                final.status = editItem.status
                final.email = editItem.user.email
                final.sip = other
                final.isAllowed = editItem.user.isAllowed
                setForm(final)
            }

        }
    }, [edit])

    const handleInput = useCallback(async (name, ev) => {

        if (name === 'phone') {
            setForm((prev) => ({
                ...prev,
                [name]: ev,
            }));
        }else  if (name === 'sip') {
            // if(edit){
            //     const removedOptions = previousSelectedOptions.filter(
            //         (prevOption) => !ev.some((currOption) => currOption.value === prevOption.value)
            //     );
            //     if(removedOptions.length === 1){
            //         await Api.sipDelete(removedOptions[0].value)
            //     }else if(removedOptions.length > 1){
            //         removedOptions.map(async (r) => {
            //             await Api.sipDelete(r.value)
            //         })
            //     }
            //     else{
            //         await Api.sipCreate({key:'cca766442cf1be18bd4ec9feedce144f',crm_id:'200',buddy:ev[ev.length - 1]})
            //     }
            // }
            console.log(ev)
            setForm((prevForm) => ({
                ...prevForm,
                [name]: ev,
            }));

            // setPreviousSelectedOptions(ev);
        } else {
            ev.target.style.borderBottomColor = "black"
            setForm((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
        }

    }, [])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault();
        let name = '';

        if ((!form.first_name || !form.last_name || !form.department_id ) && edit) {
            for (let i in form) {
                if (!form[i]) {
                    try {
                        name = i.split('_').join(" ")
                    } catch (e) {
                    }
                    document.getElementById(i).style.borderBottomColor = 'red'

                    toast.error("Please enter " + name.toUpperCase())
                }
            }
        } else if((!form.first_name || !form.last_name || !form.phone || !form.department_id || !form.password_confirm || !form.password || !form.sip) && create){
            for (let i in form) {
                if (!form[i]) {
                    try {
                        name = i.split('_').join(" ")
                    } catch (e) {
                    }
                    document.getElementById(i).style.borderBottomColor = 'red'

                    toast.error("Please enter " + name.toUpperCase())
                }
            }
        }else {

            if (edit) {
                await dispatch(employeeEditRequest(edit, form, query.page || 1,query.limit || 10,  query.search || ''))
                setEdit(0)
            } else {
                if(form.password_confirm === form.password){
                    await dispatch(employeeCreateRequest(form,  query.page || 1, query.limit || 10,query.search || ''))
                    setCreate(false)
                    setForm({
                        first_name: '',
                        last_name: '',
                        phone: '',
                        notes: '',
                        department_id: "1",
                        status: '1',
                        email:'',
                        password: '',
                        password_confirm: '',
                        isAllowed: 0,
                        sip:''
                    })
                }else{
                    toast.error("The entered password is not the same as confirm password")
                }
            }


        }
    }, [form, edit, location.search])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create || !!edit}
                onClose={() => edit ? setEdit(0) : setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create || !!edit}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <span style={{marginBottom:30}} className="d-flex justify-content-between">
                                <p>Employee</p>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => edit ? setEdit(0) : setCreate(false)}>x</span>
                            </span>
                        </Typography>
                        <div className="d-flex flex-row justify-content-around">
                            <div className="modalCreateAndUpdate row">
                                <label
                                 className="col-md-6 ">
                                    <span>First name</span>
                                    <input
                                        style={{marginBottom:40}}
                                        id="first_name"
                                        placeholder="Enter first name"
                                        value={form.first_name}
                                        onChange={(ev) => handleInput('first_name', ev)}/>
                                </label>
                                <label
                                 className="col-md-6 ">
                                    <span>Last name</span>
                                    <input
                                        id="last_name"
                                        style={{marginBottom:40}}
                                        placeholder="Enter last name"
                                        value={form.last_name}
                                        onChange={(ev) => handleInput('last_name', ev)}/>
                                </label>
                                {edit?null: <label
                                 className="col-md-6 ">
                                    <span>Email</span>
                                    <input
                                        style={{marginBottom:40}}
                                        id="email"
                                        placeholder="Enter email"
                                        value={form.email}
                                        onChange={(ev) => handleInput('email', ev)}/>
                                </label>}
                                {edit?null:<label
                                     className="col-md-6 ">
                                    <span>Phone</span>
                                    <PhoneInput
                                        style={{marginBottom:40, width:'91%'}}
                                        id="phone"
                                        placeholder="Enter phone number"
                                        value={form.phone}
                                        onChange={(ev) => handleInput('phone', ev)}/>
                                </label>}
                                {edit?null: <label
                                    className="col-md-6 ">
                                    <span>Password</span>
                                    <input
                                        style={{marginBottom:40}}
                                        id="password"
                                        placeholder="Enter password"
                                        value={form.password}
                                        onChange={(ev) => handleInput('password', ev)}/>
                                </label>}
                                {edit?null: <label
                                className="col-md-6 ">
                                <span>Confirm password</span>
                                <input
                                    style={{marginBottom:40}}
                                    id="password_confirm"
                                    placeholder="Confirm password"
                                    value={form.password_confirm}
                                    onChange={(ev) => handleInput('password_confirm', ev)}/>
                            </label>}
                                <label
                                     className="col-md-6 ">
                                    <span>Status</span>
                                    <select
                                        style={{marginBottom:40,height: 40, width: '92%'}}
                                        value={form.status}
                                        onChange={(ev) => handleInput('status', ev)}>
                                        <option value="1">Active</option>
                                        <option value="2">Passive</option>
                                    </select>
                                </label>
                                <label
                                 className="col-md-6 ">
                                    <span>Department</span>
                                    <select
                                        style={{marginBottom:40,height: 40, width:'92%'}}
                                        value={form.department_id}
                                        onChange={(ev) => handleInput('department_id', ev)}>
                                        {departmentData.map((d) => (
                                            <option key={d.id} value={d.id}>{d.title}</option>
                                        ))}
                                    </select>
                                </label>
                                <label
                                    className="col-md-6 ">
                                    <span>Notes:</span>
                                    <textarea
                                        style={{width:'92%'}}
                                        id="notes"
                                        placeholder="Enter comment"
                                        value={form.notes}
                                        onChange={(ev) => handleInput('notes', ev)}/>
                                </label>
                                <label
                                    className="col-md-6 ">
                                    <span>Sip:</span>
                                    <Select
                                        style={{marginBottom: 40}}
                                        value={form.sip}
                                        onChange={(ev) => handleInput('sip',ev)}
                                        isSearchable
                                        isMulti={true}
                                        // inputValue={sipValue}
                                        // onInputChange={handleCompanySearch}
                                        options={Utils.sortSip(sip)}
                                    />
                                </label>
                                <label
                                    className="col-md-6 ">
                                    <span>Is Allowed Sip:</span>
                                    <input
                                        type="checkbox"
                                        style={{width: 100}}
                                        checked={form.isAllowed}
                                        onChange={() => {
                                            console.log(form.isAllowed)
                                            setForm((prev) => ({
                                                ...prev,
                                                isAllowed: !form.isAllowed,
                                            }))
                                        }}
                                    />
                                </label>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                            <button onClick={handleSubmit} className="createBtn">{employeeStatus === "request" ? (
                                <div className="d-flex justify-content-center">
                                    <Loader/>
                                </div>) : edit ? "Save changes" : "Add employee"}</button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default EmployeeCreateAndEdit;

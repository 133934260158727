import React, {useCallback, useEffect, useRef, useState} from 'react';
import HistoryDrop from "../components/HistoryDrop";
import CallIcon from '@mui/icons-material/Call';
import CallScreenOut from "../components/CallScreenOut";
import {useSelector} from "react-redux";
import SipSharpIcon from '@mui/icons-material/SipSharp';
import WifiSharpIcon from '@mui/icons-material/WifiSharp';
// import {useNavigate} from "react-router";
import {numberPad} from "../number-pad";
import {Inviter, UserAgent} from 'sip.js'
import VerifyCall from "../components/VerifyCall";
import Api from "../Api";
import {toast} from "react-toastify";
import CallHistoryNew from "../components/CallHistoryNew";
import HistoryIcon from "@mui/icons-material/History";

// import {SoundMeter} from "../helpers/Utils";

function CallSingle({ua, status, setStatus}) {
    const profileData = useSelector((state) => state.users.userData)
    let lineObj = useRef(null)
    let inviter = useRef(null)
    const [toWho, setToWho] = useState('')
    const [verify, setVerify] = useState(false)
    const [callOut, setCallOut] = useState(false)
    const [loading, setLoading] = useState(true)
    const [accept, setAccept] = useState(false)

    // const navigate = useNavigate()

    const handleNumberChange = useCallback((n) => {
        setToWho(toWho + n)
    }, [toWho])
    useEffect(() => {
        (async () => {
            try{
                setLoading(true)
                const {data} = await Api.profile()
                setVerify(!data.user?.employee?.sip_manager?.sip_api_key)
                if (data.user?.employee?.sip_manager?.sip_api_key) {

                    let check = await Api.check({
                        phone: data.user?.employee?.sip_manager?.uri,
                        cash: data.user?.employee?.sip_manager?.sip_api_key
                    })
                    if (+check.data.data === 0) {
                        setVerify(true)
                        toast.success('Sip is registered')
                        await Api.registerSip({phone: data.user?.employee?.sip_manager?.uri})
                    }
                } else {
                    const reg = await Api.registerSip({phone: data.user?.employee?.sip_manager?.uri});
                    if (reg.data.status === 'ok') {
                        toast.success('Sip is registered');
                    }
                }
                setLoading(false)

            }catch(e){
                console.log(e)
            }

        })()


    }, [])


    const handleCall = useCallback(() => {
        if (ua.current == null) return;
        setCallOut(true)
        let callArr = localStorage.getItem('callArray');

        if (!callArr) {
            let arr = [toWho];
            localStorage.setItem('callArray', JSON.stringify(arr));
        } else {
            let arr = JSON.parse(callArr);

            if (arr.length === 8) {
                // Remove the first element from the array
                arr.shift();
            }

            // Add toWho to the array
            arr.push(toWho);

            localStorage.setItem('callArray', JSON.stringify(arr));
        }
        let localStream
        let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

        let spdOptions = {
            earlyMedia: true,
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {deviceId: "default"},
                    video: false
                }
            }
        }
        // Add additional Constraints
        if (supportedConstraints.autoGainControl) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = true;
        }
        if (supportedConstraints.echoCancellation) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = true;
        }
        if (supportedConstraints.noiseSuppression) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = true;
        }
        spdOptions.extraHeaders = [
            `P-Api-Key: ${profileData?.employee?.sip_manager?.sip_api_key}`, // Replace YourApiKeyHere with your actual API key value
            // ... (other headers if needed)
        ];
        let inviterOptions = {
            // extraHeaders: [
            //     `P-Api-Key: ${profileData?.employee?.sip_manager?.sip_api_key}`, // Replace YourApiKeyHere with your actual API key value
            //     // ... (other headers if needed)
            // ],
            requestDelegate: {
                onTrying: function (sip) {
                    console.log('try', sip);
                },
                onProgress: function (sip) {
                    console.log('onProgress', sip);
                },
                onRedirect: function (sip) {
                    console.log('onRedirect', sip);
                },
                onAccept: function (sip) {
                    console.log('onAccept', sip);
                    setAccept(true)

                },
                onReject: function (sip) {
                    console.log('onReject', sip);
                },
            },
        }

        let targetURI = UserAgent.makeURI("sip:" + toWho + "@" + 'ats.am');
        lineObj.SipSession = new Inviter(ua.current, targetURI,spdOptions);
        lineObj.SipSession.data = {}
        lineObj.SipSession.data.calldirection = "outbound";
        lineObj.SipSession.data.dst = toWho;
        lineObj.SipSession.data.earlyReject = false;
        lineObj.SipSession.isOnHold = false;
        lineObj.SipSession.delegate = {
            onBye: function (sip) {
                setCallOut(false);
                setToWho('');
                if (localStream) {
                    // Stop the audio tracks
                    localStream.getAudioTracks().forEach(track => track.stop());

                    // Release the stream
                    localStream = null;
                }
                setAccept(false)

                console.log(lineObj.SipSession, sip);
            },
            onByeRequest: function (sip) {
                setCallOut(false);
                setAccept(false)
                setToWho('');
                console.log(lineObj.SipSession, sip);
                if (localStream) {
                    // Stop the audio tracks
                    localStream.getAudioTracks().forEach(track => track.stop());

                    // Release the stream
                    localStream = null;
                }
            },
            onMessage: function (sip) {
                console.log(lineObj, sip);
            },
            onInvite: function (sip) {
                console.log(lineObj.SipSession, sip);
            },
            onSessionDescriptionHandler: async function () {
                localStream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const audioTracks = localStream.getAudioTracks()[0];
                lineObj.SipSession.sessionDescriptionHandler.peerConnection.addTrack(audioTracks, localStream);
                const pc = lineObj.SipSession.sessionDescriptionHandler.peerConnection;
                pc.addEventListener('track', (event) => {
                    const remoteTrack = event.track;
                    if (remoteTrack.kind === 'audio') {
                        const remoteAudioStream = new MediaStream();
                        remoteAudioStream.addTrack(remoteTrack);
                        const audioElement = new Audio();
                        audioElement.srcObject = remoteAudioStream;
                        audioElement.play();
                    }
                });
            },
            onTrying: function (sip) {
                console.log('try', sip);
            },
            onAccept: function (sip) {
                console.log('try', sip);

            },
        }

        lineObj.SipSession.invite(inviterOptions).then(() => {}).catch((e) => console.log(e));


    }, [ua.current, toWho, lineObj.SipSession, profileData])

    const handleRestart = useCallback(() => {
        ua.current.registerer.unregister().then(() => console.log('unregister'));
        setStatus('Re registering...')
        ua.current.transport.attemptingReconnection = false;
        ua.current.registering = false;
        setTimeout(function () {
            console.log("Starting registration...");
            let RegistererRegisterOptions = {
                requestDelegate: {
                    onReject: function (sip) {
                        setStatus('Rejected')
                        console.log(sip.message.reasonPhrase, sip.message.statusCode);
                    }
                }
            }

            console.log("Sending Registration...");
            ua.current.registering = true
            ua.current.registerer.register(RegistererRegisterOptions).then(() => console.log('registered')).catch(() => {
                setStatus('Not registered')
            });
        }, 1000);
    }, [ua])

    return (
        <>
            <header className="taskFilter justify-content-start">
                <div style={{
                    padding: window.innerWidth < 450 ? '0 15px 0px 90px' : '0 20px',
                    height: 65,
                    borderRight: '1px solid #74c5e2'
                }}
                     className="d-flex align-items-center">
                    <b>Call single</b>
                </div>
            </header>

            <main style={{padding: 40}}
                  className="d-flex justify-content-center flex-column align-items-center callerMain">
                <h1>{profileData?.employee?.sip_manager?.username || 'Caller name'}</h1>
                <div className="d-flex flex-row" style={{marginBottom: 40, cursor: 'pointer'}}>
                    <div className="call_action" onClick={handleRestart}>Restart</div>
                </div>
                <span>Caller status: {status} {status === 'Registered' || status === 'ReRegistered' ? (
                        <SipSharpIcon sx={{fill: 'green'}}/>) :
                    <WifiSharpIcon sx={{fill: '#aa075b'}}/>}</span>
                <div className="d-flex">
                    <div className="d-flex flex-column align-items-center">
                        <div style={{width: 250, height: 345}}>
                            <div
                                className="d-flex justify-content-center"
                            ><input value={toWho} onChange={(ev) => setToWho(ev.target.value)}/>
                            <span>
                                <CallHistoryNew setWho={setToWho} />
                                </span>
                            </div>

                            <div className="dial">
                                {numberPad.map((n) => <div onClick={() => handleNumberChange(n)} key={n}>{n}</div>)}
                                <div onClick={() => handleNumberChange('*')}>*</div>
                                <div onClick={() => handleNumberChange('0')}>0</div>
                                <div onClick={() => handleNumberChange('#')}>#</div>
                            </div>
                        </div>
                        <button
                            className="call_btn"
                            onClick={handleCall}><CallIcon/></button>
                    </div>
                </div>
            </main>
            {/*<CallWidget />*/}
            {/*{notRegister? <div></div>}*/}
            {/*{callOut ? <div className="callScreenOutBack">*/}
            {/*    <CallScreenOut setCall={setCallOut} session={session.current} handleEnd={handleEnd}/>*/}
            {/*</div> : null}*/}
            {callOut ? <div className="callScreenOutBack">
                <CallScreenOut callOut={callOut} setCaller={setToWho} caller={toWho} setAccept={setAccept} accept={accept} setCall={setCallOut} lineObj={lineObj} session={lineObj.SipSession}/>
            </div> : null}
            {verify ? <VerifyCall loading={loading} phone={'37495067515'} setVerHome={setVerify}/> : null}
        </>
    );
}

export default CallSingle;

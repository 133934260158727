import React, {useCallback, useEffect, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import classNames from 'classnames'
import Loader from "./Loader";
import DateTimePicker from 'react-datetime-picker';
import Utils from "../helpers/Utils";
import {useDispatch, useSelector} from "react-redux";
import {taskCreateRequest} from "../store/actions/task";
import qs from "query-string";
import {useLocation} from "react-router";
import {processRequest, processSingleRequest} from "../store/actions/process";
import {departmentRequest} from "../store/actions/department";
import {employeeRequest} from "../store/actions/employee";
import {toast} from "react-toastify";
import {company, global, tasks} from "../translate";

function TaskCreate({create,line,isProcess,setCreate}) {
    const [task, setTask] = useState({
        title:'',
        description:'',
        deadlineForShow: new Date(),
        deadline: Utils.dateToTimestamp(Utils.toISOLocal(new Date())),
        beforeDeadline: Utils.dateToTimestamp(Utils.toISOLocal(new Date())),
        responsible: '',
        type:'Meeting',
        process_id:null
    })
    const [department, setDepartment] = useState('Developer')
    const taskStatus = useSelector(state => state.task.taskStatus)
    const departmentData = useSelector(state => state.department.departmentData)
    const profileData = useSelector(state => state.users.userData)
    const lang = useSelector(state => state.users.lang)
    const employeeData = useSelector(state => state.employee.employeeData)
    const dispatch = useDispatch()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    useEffect(() => {
        if(profileData.is_admin){
            (async () => {
                await dispatch(departmentRequest())
                await dispatch(employeeRequest(10000,1,'','Developer'))

            })()}
        setTask(
            (prev) => ({
                ...prev,
                responsible: profileData.email
            })
        )

    }, [profileData])

    const [type, setType] = useState('')

    const handleInput = useCallback((name, ev) => {
        if(name === 'deadlineForShow'){

            setTask((prev) => ({
                ...prev,
                deadline: new Date(ev).toISOString(),
                deadlineForShow: new Date(ev)
            }));
        }else if(name === 'beforeDeadline'){
            setTask((prev) => ({
                ...prev,
                beforeDeadline: new Date(ev).toISOString(),
            }));
        }else{
            // ev.target.style.borderBottomColor = "black"

            setTask((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
        }
    }, [])

    const handleSubmit = useCallback(async () => {
        if (!task.title || !task.description || !task.deadline || !task.type || !task.responsible) {
            let name = '';

            for (let i in task) {
                if (!task[i] && i !== 'process_id') {
                    try {
                        name = i.split('_').join(" ")
                    } catch (e) {
                    }
                    // document.getElementById(i).style.borderBottomColor ='red'

                    toast.error("Please enter " + name.toUpperCase())
                }
            }
        }else{
            if(+isProcess){
                if(line){
                    await dispatch(taskCreateRequest(task, 1, 1000, query.filter || '',query.search || '',isProcess))
                    await dispatch(processRequest(1000,1,''))
                    await dispatch(processSingleRequest(isProcess))

                }else{
                    await dispatch(taskCreateRequest(task,query.page || 1, query.limit || 10, query.filter || '',query.search || '',isProcess))
                    await dispatch(processRequest(10,1,''))
                    await dispatch(processSingleRequest(isProcess))
                }
            }else{
                if(line){
                    await dispatch(taskCreateRequest(task, 1, 1000, query.filter || '',query.search || ''))
                }else{
                    await dispatch(taskCreateRequest(task,query.page || 1, query.limit || 10, query.filter || '',query.search || ''))

                }
            }
        }
        setCreate(false)


    }, [task, location.search,line, isProcess])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: window.innerWidth < 610?window.innerWidth - 60 : 650,
        overflowY:'auto',
        height: window.innerHeight < 600?window.innerHeight - 60 : 600,
        bgcolor: 'background.paper',
        boxShadow: 2,
        p: 4,
    };

    const handleEmployerChange = useCallback(async (ev) => {
        setDepartment(ev.target.value)
        await dispatch(employeeRequest(10000,1,'',ev.target.value))
    }, [])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create }
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={create}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <span style={{marginBottom:30}} className="d-flex justify-content-between">
                                <span>{tasks.title[+lang]}</span>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => setCreate(false)}>x</span>
                            </span>
                        </Typography>
                        <div className="d-flex flex-row justify-content-around">
                            <div className="modalCreateAndUpdate row">
                                <label
                                className="col-md-6 m-0">
                                    <span>{tasks.typeT[+lang]}</span>
                                    <input
                                        style={{marginBottom: 40}}
                                        id="title"
                                        placeholder="Enter task title"
                                        value={task.title}
                                        onChange={(ev) => handleInput('title', ev)}
                                    />
                                </label>
                                <label
                                    className="col-md-6 m-0">
                                    <span>{tasks.type[+lang]}</span>
                                    <select
                                        style={{height: 40, margin:'12px 9px'}}
                                        value={task.type}
                                        onChange={(ev) => handleInput('type', ev)}
                                    >
                                        <option value="Meeting">Meeting</option>
                                        <option value="Contact">Contact</option>
                                        <option value="other">Other</option>
                                    </select>
                                    <input
                                        style={{marginBottom: 40}}
                                        placeholder="Enter task type"
                                        className={classNames({'visually-hidden':task.type !== 'other'})}
                                        value={type}
                                        onChange={(ev) => setType(ev.target.value)}
                                    />
                                </label>

                                <label
                                    className="col-md-12 m-0">
                                    <span>{tasks.desc[+lang]}</span>
                                    <textarea
                                        style={{marginBottom: 40, width:'92%'}}
                                        id="description"
                                        placeholder="Enter description"
                                        value={task.description}
                                        onChange={(ev) => handleInput('description', ev)}
                                    />
                                </label>
                                {+profileData.is_admin?
                                   <>
                                       <label
                                           className="col-md-6 m-0">
                                           <span>{company.dep[+lang]}</span>
                                           <select
                                               style={{height: 40, margin:'12px 9px'}}
                                               value={department}
                                               onChange={(ev) => handleEmployerChange(ev)}
                                           >
                                               {departmentData.map((d) => (
                                                   <option key={d.title} value={d.title}>{d.title}</option>
                                               ))}
                                           </select>
                                       </label>

                                       <label
                                           className="col-md-6 m-0">
                                           <span>{tasks.response[+lang]}</span>
                                           <select
                                               style={{height: 40, margin:'12px 9px 60px'}}
                                               value={task.responsible}
                                               onChange={(ev) => handleInput('responsible', ev)}
                                           >
                                               <option value="">Select responsible</option>
                                               {employeeData?.employees?.map((e) => (
                                                   <option value={e.user.email} key={e.id}>{e.user.email}</option>
                                               ))}
                                           </select>
                                       </label>
                                   </>:null}
                                <label
                                    className="col-md-12 m-0" style={task.type === 'other'? {margin: '65px 10px 0'}:{margin: '35px 10px 0'}}>
                                    <span>{tasks.deadline[+lang]}</span>
                                    <DateTimePicker
                                        style={{margin: '10px 0', width:'100%'}}
                                        id='deadline'
                                        value={task.deadlineForShow}
                                        onChange={(date) => handleInput('deadlineForShow',date)}/>
                                </label>
                                <label
                                    className="col-md-12 m-0" style={task.type === 'other'? {margin: '65px 10px 0'}:{margin: '35px 10px 0'}}>
                                    <span>{tasks.deadlineD[+localStorage.getItem('itCrmLang') || 0]}</span>
                                    <DateTimePicker
                                        style={{margin: '10px 0', width:'100%'}}
                                        id='deadline'
                                        value={task.beforeDeadline}
                                        onChange={(date) => handleInput('beforeDeadline',date)}/>
                                </label>
                            </div>


                        </div>

                        <div style={{marginTop:40}} className="d-flex justify-content-center">
                            <button onClick={handleSubmit} className="createBtn">{taskStatus === "request" ? (
                                <div className="d-flex justify-content-center">
                                    <Loader/>
                                </div>) :global.add[+localStorage.getItem('itCrmLang') || 0]}</button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default TaskCreate;

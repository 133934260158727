export const SIP_CREATE_REQUEST = 'SIP_CREATE_REQUEST';
export const SIP_CREATE_SUCCESS = 'SIP_CREATE_SUCCESS';
export const SIP_CREATE_FAIL = 'SIP_CREATE_FAIL';

export function sipCreateRequest(form) {
    return {
        type: SIP_CREATE_REQUEST,
        payload: {form },
    };
}
export const SIP_EDIT_REQUEST = 'SIP_EDIT_REQUEST';
export const SIP_EDIT_SUCCESS = 'SIP_EDIT_SUCCESS';
export const SIP_EDIT_FAIL = 'SIP_EDIT_FAIL';

export function sipEditRequest(form) {
    return {
        type: SIP_EDIT_REQUEST,
        payload: {form},
    };
}
export const SIP_REGISTER_REQUEST = 'SIP_REGISTER_REQUEST';
export const SIP_REGISTER_SUCCESS = 'SIP_REGISTER_SUCCESS';
export const SIP_REGISTER_FAIL = 'SIP_REGISTER_FAIL';

export function sipRegisterRequest(form) {
    return {
        type: SIP_REGISTER_REQUEST,
        payload: {form},
    };
}

export const SIP_CHECK_REQUEST = 'SIP_CHECK_REQUEST';
export const SIP_CHECK_SUCCESS = 'SIP_CHECK_SUCCESS';
export const SIP_CHECK_FAIL = 'SIP_CHECK_FAIL';

export function sipCheckRequest(form) {
    return {
        type: SIP_CHECK_REQUEST,
        payload: {form},
    };
}

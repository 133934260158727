import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PhoneInput from "react-phone-number-input";
import {clientCreateRequest, clientEditRequest} from "../store/actions/clients";
import Loader from "./Loader";
import {useLocation} from "react-router";
import qs from "query-string";
import {companyRequest} from "../store/actions/company";
import Select from 'react-select';
import Utils from "../helpers/Utils";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth < 610?window.innerWidth - 60 : 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    overflowY:'auto',
    p: 4,
};

function ClientCreateAndEdit({create, setCreate, edit,phone, setEdit}) {
    const location = useLocation();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const clientData = useSelector((state) => state.client.clientData)
    const companyData = useSelector((state) => state.company.companyData)
    const clientStatus = useSelector((state) => state.client.clientStatus)
    const [companySearch, setCompanySearch] = useState('')
    const [form, setForm] = useState({
        first_name: '',
        last_name: '',
        phone: '',
        comment: '',
        company_id:''
    })
    useEffect(() => {
        (async () => {
            await dispatch(companyRequest(1000000,1,''))
        })()
        if (edit) {
            const final = {}
            const editItem = clientData.client.filter((i) => i.id === edit)
            final.first_name = editItem[0]?.first_name
            final.phone = editItem[0]?.phone
            final.last_name = editItem[0]?.last_name
            final.comment = editItem[0]?.comment
            setForm(final)
        }else if(phone){
            setForm((prev) => ({
                ...prev,
                phone:"+"+phone
            }));
        }
    }, [edit,phone])

    const handleInput = useCallback((name, ev) => {
        if(name === 'phone' || name === 'company_id'){
            setForm((prev) => ({
                ...prev,
                [name]: ev,
            }));
        }else{
            // ev.target.style.borderBottomColor = "black"
            setForm((prev) => ({
                ...prev,
                [name]: ev.target.value,
            }));
        }

    }, [])

    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault();
        if (!form.first_name || !form.last_name || !form.phone || !form.comment) {
            let name = '';

            for (let i in form) {
                if (!form[i] && i !== 'company_id') {
                    try {
                        name = i.split('_').join(" ")
                    } catch (e) {
                    }
                    // document.getElementById(i).style.borderBottomColor ='red'

                    toast.error("Please enter " + name.toUpperCase())
                }
            }
        } else {

            if (edit) {
                await dispatch(clientEditRequest(edit, form,query.limit || 10, query.page || 1,query.search || ''))
                setEdit(0)
            } else {
                await dispatch(clientCreateRequest(form,query.limit || 10, query.page || 1,query.search || ''))
                setCreate(false)
            }

            setForm({
                first_name: '',
                last_name: '',
                phone: '',
                comment: '',
                company_id:''
            })
        }
    }, [form, edit,location.search])

    const handleCompanySearch = useCallback(async (ev) => {
        await dispatch(companyRequest(100000,1,ev))
        setCompanySearch(ev)
    }, [])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create || !!edit}
                onClose={() => edit ? setEdit(0) : setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create || !!edit}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title"  variant="h6" component="h2">
                            <span style={{marginBottom:30}} className="d-flex justify-content-between">
                                <span>Client</span>
                                <span style={{cursor: 'pointer'}}
                                      onClick={() => edit ? setEdit(0) : setCreate(false)}>x</span>
                            </span>
                        </Typography>
                        <div className="d-flex flex-row justify-content-around">
                            <div className="modalCreateAndUpdate row">
                                <label
                                    className="col-md-6 m-0">
                                    <span>First name</span>
                                    <input
                                        style={{marginBottom:40}}
                                        id="first_name"
                                        placeholder="Enter first name"
                                        value={form.first_name}
                                        onChange={(ev) => handleInput('first_name', ev)}/>
                                </label>
                                <label
                                    className="col-md-6 m-0">
                                    <span>Last name</span>
                                    <input
                                        style={{marginBottom:40}}
                                        id="last_name"
                                        placeholder="Enter last name"
                                        value={form.last_name}
                                        onChange={(ev) => handleInput('last_name', ev)}/>
                                </label>
                                {edit?null:<label
                                     className="col-md-6 m-0">
                                    <span>Phone</span>
                                    <PhoneInput
                                        id="phone"
                                        placeholder="Enter phone number"
                                        value={form.phone}
                                        onChange={(ev) => handleInput('phone', ev)}/>
                                </label>}
                                <label
                                     className="col-md-6 m-0">
                                    <span>Comment</span>
                                    <textarea
                                        id="comment"
                                        placeholder="Enter comment"
                                        value={form.comment}
                                        onChange={(ev) => handleInput('comment', ev)}/>
                                </label>
                                <label
                                    className="col-md-12 m-0">
                                    <span>Company</span>
                                    <Select
                                        value={form.company_id}
                                        onChange={(ev) => handleInput('company_id', ev)}
                                        isSearchable
                                        inputValue={companySearch}
                                        onInputChange={handleCompanySearch}
                                        options={Utils.sortCompany(companyData)}
                                    />

                                </label>
                            </div>

                        </div>

                        <div style={{marginTop:40}} className="d-flex justify-content-center">
                            <button onClick={handleSubmit} className="createBtn" style={{padding:'3px 32px'}}>{clientStatus === "request"? (<div className="d-flex justify-content-center">
                                <Loader/>
                            </div>) : edit ? "Edit the client" : "Add new client"}</button>
                        </div>
                    </Box>
                </Fade>

            </Modal>
        </div>

    );
}

export default ClientCreateAndEdit;

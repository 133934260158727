import {
    COMPANY_STATUS_FAIL, COMPANY_STATUS_REQUEST,
    COMPANY_STATUS_SUCCESS,
    DEPARTMENT_FAIL,
    DEPARTMENT_REQUEST,
    DEPARTMENT_SUCCESS
} from '../actions/department';


const initialState = {
    departmentData: [],
    departmentStatus: '',

    companyStatusData: [],
    companyStatusStatus: '',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEPARTMENT_REQUEST: {
            return {
                ...state,
                departmentStatus: 'request',
                departmentData: [],
            };
        }
        case DEPARTMENT_SUCCESS: {
            return {
                ...state,
                departmentStatus: 'success',
                departmentData: action.payload.data.departments,
            };
        }

        case DEPARTMENT_FAIL: {
            return {
                ...state,
                departmentStatus: 'fail',
            };
        }
        case COMPANY_STATUS_REQUEST: {
            return {
                ...state,
                companyStatusStatus: 'request',
                companyStatusData: [],
            };
        }
        case COMPANY_STATUS_SUCCESS: {
            return {
                ...state,
                companyStatusStatus: 'success',
                companyStatusData: action.payload.data.status,
            };
        }

        case COMPANY_STATUS_FAIL: {
            return {
                ...state,
                companyStatusStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {BrowserRouter, Navigate, Route, Routes,} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import NotFound from './pages/NotFound';
import AdminLogin from './pages/AdminLogin';
import AdminWrapper from "./components/AdminWrapper";
import Profile from "./pages/Profile";
import History from "./pages/History";
import Home from "./pages/Home";
import HistoryRecords from "./pages/HistoryRecords";
import Company from "./pages/Company";
import Clients from "./pages/Clients";
import Employee from "./pages/Employee";
import ProcessLine from "./pages/ProcessLine";
import TaskLine from "./pages/TaskLine";
import TaskList from "./pages/TaskList";
import ProcessList from "./pages/ProcessList";
import ProcessSingle from "./pages/ProcessSingle";
import CallSingle from "./pages/CallSingle";
import Settings from "./pages/Settings";
import Sip from "./pages/Sip";
import {Registerer, RegistererState, UserAgent} from 'sip.js'

import {useDispatch, useSelector} from "react-redux";
import {langChange} from "./store/actions/users";

// eslint-disable-next-line react/prefer-stateless-function


function App(props) {
    const ua = useRef(null)
    const [status, setStatus] = useState('Unregistered')
    const [income, setIncome] = useState(false)
    const [incomeCall, setIncomeCall] = useState(false)
    const [session, setSession] = useState(null)
    const [callNumber, setCallNumber] = useState('')

    const handleUALogout = useCallback((ev) => {
        // ev.preventDefault()
        // ev.returnValue = ''
        if (ua.current) {
            setStatus('Unregistering...')
            ua.current.registerer.unregister().then(() => setStatus('Unregister'))
        }
    }, [ua])
    const profileData = useSelector((state) => state.users.userData)
    const Line = function (lineNumber, displayName, displayNumber, buddyObj) {
        this.LineNumber = lineNumber;
        this.DisplayName = displayName;
        this.DisplayNumber = displayNumber;
        this.IsSelected = false;
        this.BuddyObj = buddyObj;
        this.SipSession = null;
        this.LocalSoundMeter = null;
        this.RemoteSoundMeter = null;
    }

    function ReceiveCall(session) {
        const audioElement = new Audio();
        let localStream
        let audioTracks
        var callerID = session.remoteIdentity.displayName;
        var did = session.remoteIdentity.uri.user;
        setCallNumber(session.remoteIdentity.uri.user)
        if (typeof callerID === 'undefined') callerID = did;
        setIncome(true)
        var supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
        var spdOptions = {
            earlyMedia: true,
            sessionDescriptionHandlerOptions: {
                constraints: {
                    audio: {deviceId: "default"},
                    video: false
                }
            }
        }
        if (supportedConstraints.autoGainControl) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.autoGainControl = true;
        }
        if (supportedConstraints.echoCancellation) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.echoCancellation = true;
        }
        if (supportedConstraints.noiseSuppression) {
            spdOptions.sessionDescriptionHandlerOptions.constraints.audio.noiseSuppression = true;
        }
        // Make new contact of its not there

        // Create the line and add the session so we can answer or reject it.
        let newLineNumber = +1;
        var lineObj = new Line(newLineNumber, callerID, did, null);
        lineObj.SipSession = session;
        lineObj.SipSession.data = {}
        lineObj.SipSession.data.line = lineObj.LineNumber;
        lineObj.SipSession.data.calldirection = "inbound";
        lineObj.SipSession.data.terminateby = "";
        lineObj.SipSession.data.src = did;
        lineObj.SipSession.data.earlyReject = false;
        // Detect Video
        lineObj.SipSession.data.withvideo = false;
        lineObj.SipSession.delegate = {
            onBye: function (sip) {
                console.log(sip)
                audioElement.pause()
                setIncome(false)
                setIncomeCall(true)
                if (localStream) {
                    // Stop the audio tracks
                    localStream.getAudioTracks().forEach(track => track.stop());

                    // Release the stream
                    localStream = null;
                }

                // onSessionReceivedBye(lineObj, sip)
            },
            onMessage: function (sip) {
                console.log(sip)

                // onSessionReceivedMessage(lineObj, sip);
            },
            onInvite: function (sip) {
                console.log(sip)

                // onSessionReinvited(lineObj, sip);
            },
            onSessionDescriptionHandler: async function (sdh, provisional) {
                localStream = await navigator.mediaDevices.getUserMedia({audio: true})
                audioTracks = localStream.getAudioTracks()[0];
                session.sessionDescriptionHandler.peerConnection.addTrack(audioTracks, localStream);
                const pc = session.sessionDescriptionHandler.peerConnection;
                console.log(pc)
                pc.addEventListener('track', (event) => {
                    const remoteTrack = event.track;
                    if (remoteTrack.kind === 'audio') {
                        const remoteAudioStream = new MediaStream();
                        remoteAudioStream.addTrack(remoteTrack);
                        audioElement.srcObject = remoteAudioStream;
                        audioElement.play();
                    }
                });


                // onSessionDescriptionHandlerCreated(lineObj, sdh, provisional, lineObj.SipSession.data.withvideo);
            },
            onReject:function (sip){
                console.log(sip)
            }

        }

        lineObj.SipSession.incomingInviteRequest.delegate = {
            onCancel: function (sip) {
                console.log(sip)
                document.getElementById('callMusicId').pause()
                setIncome(false)
                // onInviteCancel(lineObj, sip)
            }
        }
        setSession(lineObj.SipSession)
    }

    useEffect(() => {
        try {
            window.addEventListener('beforeunload', handleUALogout)
            if (profileData?.employee?.sip_manager && !ua.current) {
                var options = {

                    uri: UserAgent.makeURI('sip:' + profileData?.employee?.sip_manager?.uri + '@' + profileData?.employee?.sip_manager?.sip_server + ':8089'),
                    transportOptions: {
                        server: 'wss://' + profileData?.employee?.sip_manager?.sip_server + ':' + profileData?.employee?.sip_manager.sip_port + '/ws',
                        traceSip: false,
                        connectionTimeout: 3600
                        // keepAliveInterval: 30 // Uncomment this and make this any number greater then 0 for keep alive...
                        // NB, adding a keep alive will NOT fix bad internet, if your connection cannot stay open (permanent WebSocket Connection) you probably
                        // have a router or ISP issue, and if your internet is so poor that you need to some how keep it alive with empty packets
                        // upgrade you internet connection. This is voip we are talking about here.
                    },
                    sessionDescriptionHandlerFactoryOptions: {
                        peerConnectionConfiguration: {
                            "bundlePolicy": "balanced",
                            // bundlePolicy: BundlePolicy,
                            // certificates: undefined,
                            // iceCandidatePoolSize: 10,
                            iceServers: [{urls: 'stun:stun.l.google.com:19302'}],
                            // iceTransportPolicy: "all",
                            // peerIdentity: undefined,
                            // rtcpMuxPolicy: "require",
                        },
                        iceGatheringTimeout: 3600
                    },
                    contactName: profileData?.employee?.sip_manager?.uri,
                    displayName: profileData?.employee?.sip_manager?.username,
                    authorizationUsername: profileData?.employee?.sip_manager?.uri,
                    authorizationPassword: profileData?.employee?.sip_manager?.password,
                    hackIpInContact: true,           // Asterisk should also be set to rewrite contact
                    userAgentString: profileData?.employee?.sip_manager?.uri,
                    autoStart: false,
                    autoStop: true,
                    register: false,
                    noAnswerTimeout: 120,
                    // sipExtension100rel: // UNSUPPORTED | SUPPORTED | REQUIRED NOTE: rel100 is not supported

                    contactParams: {"transport": "wss"},
                    delegate: {
                        onInvite: function (sip) {
                            ReceiveCall(sip);
                            console.log(sip)
                        },
                    }
                }
                ua.current = new UserAgent(options);
                ua.current.sessions = ua.current._sessions;
                ua.current.registrationCompleted = false;
                ua.current.registering = false;
                ua.current.transport.ReconnectionAttempts = 300;
                ua.current.transport.attemptingReconnection = false;
                ua.current.BlfSubs = [];
                ua.current.lastVoicemailCount = 0;

                console.log("Creating User Agent... Done");

                ua.current.transport.onConnect = function () {
                    ua.current.isReRegister = false;
                    ua.current.transport.attemptingReconnection = false;
                    ua.current.transport.ReconnectionAttempts = 999;

                    // Auto start register
                    if (ua.current.transport.attemptingReconnection === false && ua.current.registering === false) {
                        window.setTimeout(function () {
                            if (ua.current == null) return;
                            if (ua.current.registering == true) return;
                            // if (ua.current.isRegistered()) return;

                            var RegistererRegisterOptions = {

                                requestDelegate: {
                                    onReject: function (sip) {
                                        console.log(sip)
                                    }
                                }
                            }

                            console.log("Sending Registration...");
                            ua.current.registering = true
                            ua.current.registerer.register(RegistererRegisterOptions).then((e) => {
                            }).catch(e => console.log(e, 'regerr'));
                        }, 500);
                    } else {
                        console.warn("onTransportConnected: Register() called, but attemptingReconnection is true or registering is true")
                    }
                }
                // ua.current.transport.onDisconnect = function(error){
                //     if(error){
                //         onTransportConnectError(error);
                //     }
                //     else {
                //         onTransportDisconnected();
                //     }
                // }

                var RegistererOptions = {
                    expires: 3000,
                    extraHeaders: [],
                    extraContactHeaderParams: []
                }

                // Added to the SIP Headers

                // Added to the contact AFTER the '>' (not permanent)

                ua.current.registerer = new Registerer(ua.current, RegistererOptions);
                console.log("Creating Registerer... Done");
                ua.current.registerer.stateChange.addListener(function (newState) {
                    console.log("User Agent Registration State:", newState);
                    switch (newState) {
                        case RegistererState.Initial:
                            // Nothing to do
                            break;
                        case RegistererState.Registered:
                            ua.current.registrationCompleted = true;
                            if (!ua.current.isReRegister) {
                                console.log("Registered!");
                                setStatus('Registered')

                                // Start Subscribe Loop

                                // Output to status

                                ua.current.registering = false;

                            } else {
                                ua.current.registering = false;
                                setStatus('ReRegistered')
                                console.log("ReRegistered!");
                            }
                            ua.current.isReRegister = true;
                            break;
                        case RegistererState.Unregistered:
                            setStatus('Unregistered')
                            break;
                        case RegistererState.Terminated:
                            // Nothing to do
                            break;
                    }
                });
                ua.current.start().catch(function (error) {
                    console.log(error)
                });

            }

            return () => window.removeEventListener('beforeunload', handleUALogout)
        } catch (e) {
            console.log(e)
        }
    }, [profileData, ua])
    const dispatch = useDispatch()
    useEffect(() => {
        if(localStorage.getItem('itCrmLang')){
            dispatch(langChange(+localStorage.getItem('itCrmLang')))
        }
    },[])
    return (
        <>
            <BrowserRouter>
                <AdminWrapper incomeCall={incomeCall} ua={ua} setIncomeCall={setIncomeCall} income={income} setIncome={setIncome} callNumber={callNumber} sessionIn={session} >
                    <Routes>
                        <Route path="/" element={<Navigate to="/process/line" />}/>
                        <Route path="/call" element={<CallSingle setStatus={setStatus} ua={ua} status={status}/>}
                        />
                        <Route path="/settings" element={<Settings/>}/>
                        <Route path="/sip" element={<Sip/>}/>
                        <Route path="/company" element={<Company/>}/>
                        <Route path="/phones" element={<Clients/>}/>
                        <Route path="/employee" element={<Employee/>}/>
                        <Route path="/login" element={<AdminLogin/>}/>
                        <Route path="/profile" element={<Profile/>}/>
                        <Route path="/history" element={<History/>}/>
                        <Route path="/history_records" element={<HistoryRecords/>}/>
                        <Route path="/process/line" element={<ProcessLine/>}/>
                        <Route path="/process/list" element={<ProcessList/>}/>
                        <Route path="/process/:id" element={<ProcessSingle/>}/>
                        <Route path="/task/line" element={<TaskLine/>}/>
                        <Route path="/task/list" element={<TaskList/>}/>
                        <Route path="*" element={<Navigate to="/not-found"/>}/>
                        <Route path="/not-found" element={<NotFound/>}/>
                    </Routes>

                </AdminWrapper>
            </BrowserRouter>
            <ToastContainer closeOnClick hideProgressBar/>
        </>
    );
}

export default App;


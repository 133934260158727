import React, {useCallback, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useSelector} from "react-redux";
import {processListStatus} from "../menu";

import {useLocation} from "react-router";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function ProcessHistory({create, setCreate,data}) {
    const lang = useSelector(state => state.users.lang)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create}
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <div className="createHeader justify-content-between align-items-center">
                                <h4 className="m-0">Process Status history</h4>
                                <span style={{cursor: 'pointer'}} onClick={() => setCreate(false)}>X</span>
                            </div>

                        </Typography>
                        <div className="d-flex createDiv flex-column justify-content-center">
                            {data ? data.map(d=> (
                                <div style={{marginTop:20}} className="d-flex flex-column createDiv align-items-center justify-content-center">
                                    <p>{new Date(d.when).toLocaleString('en-US', {
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                        hour12: false,
                                    })}</p>
                                    <p>{d.changed} - {processListStatus?processListStatus.find(s => s.id === +d.value).text[+lang]:null}</p>

                                    <p>Changer {d.his_man_p.user.email}</p>
                                </div>
                            )):null}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default ProcessHistory;

import React, {useCallback} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Loader from "./Loader";
import {employeeDeleteRequest} from "../store/actions/employee";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
};

function EmployeeDelete({deleteItem, setDeleteItem}) {
    const dispatch = useDispatch()
    const companyStatus = useSelector(state => state.company.companyStatus)
    const handleDelete = useCallback(async () => {
        await dispatch(employeeDeleteRequest(deleteItem))
        setDeleteItem(0)
    }, [deleteItem])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!deleteItem}
                onClose={() => setDeleteItem(0)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!deleteItem}>
                    <Box sx={style}>

                        {companyStatus === 'request'?(<div className="d-flex justify-content-center">
                            <Loader/>
                        </div>):
                            (<>
                                <Typography id="transition-modal-title" variant="h6" component="h2">
                                    <div className="createHeader">
                                        <span style={{cursor: 'pointer'}} onClick={() => setDeleteItem(0)}>X</span>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <HighlightOffIcon sx={{fontSize:120, color:'#5bbeda'}} />
                                    </div>
                                </Typography>
                                <div className="createDiv">
                                    <p className="deleteTitle">Are you sure?</p>
                                    <p className="d-flex text-center">Do you really want to delete this employee? The process can't be undone.</p>
                                    <div className="deleteBtnBlock">
                                        <button onClick={() => setDeleteItem(0)} className="btn_no">No</button>
                                        <button onClick={handleDelete} className="btn_Yes">Yes</button>
                                    </div>
                                </div>
                            </>)
                        }


                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default EmployeeDelete;

export const CLIENT_REQUEST = 'CLIENT_REQUEST';
export const CLIENT_SUCCESS = 'CLIENT_SUCCESS';
export const CLIENT_FAIL = 'CLIENT_FAIL';

export function clientRequest(rowsPerPage,page, search) {
    return {
        type: CLIENT_REQUEST,
        payload: {rowsPerPage,page, search},
    };
}


export const CLIENT_SINGLE_REQUEST = 'CLIENT_SINGLE_REQUEST';
export const CLIENT_SINGLE_SUCCESS = 'CLIENT_SINGLE_SUCCESS';
export const CLIENT_SINGLE_FAIL = 'CLIENT_SINGLE_FAIL';

export function clientSingleRequest(phone) {
    return {
        type: CLIENT_SINGLE_REQUEST,
        payload: {phone},
    };
}


export const CLIENT_EDIT_REQUEST = 'CLIENT_EDIT_REQUEST';
export const CLIENT_EDIT_SUCCESS = 'CLIENT_EDIT_SUCCESS';
export const CLIENT_EDIT_FAIL = 'CLIENT_EDIT_FAIL';

export function clientEditRequest(id, formData, limit, page, search) {
    return {
        type: CLIENT_EDIT_REQUEST,
        payload: {id, formData,limit, page, search},
    };
}

export const CLIENT_DELETE_REQUEST = 'CLIENT_DELETE_REQUEST';
export const CLIENT_DELETE_SUCCESS = 'CLIENT_DELETE_SUCCESS';
export const CLIENT_DELETE_FAIL = 'CLIENT_DELETE_FAIL';

export function clientDeleteRequest(id) {
    return {
        type: CLIENT_DELETE_REQUEST,
        payload: {id},
    };
}
export const CLIENT_CREATE_REQUEST = 'CLIENT_CREATE_REQUEST';
export const CLIENT_CREATE_SUCCESS = 'CLIENT_CREATE_SUCCESS';
export const CLIENT_CREATE_FAIL = 'CLIENT_CREATE_FAIL';

export function clientCreateRequest(form,limit, page, search) {
    return {
        type: CLIENT_CREATE_REQUEST,
        payload: {form, limit, page, search},
    };
}





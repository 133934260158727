import {
    EMPLOYEE_CREATE_FAIL,
    EMPLOYEE_CREATE_REQUEST,
    EMPLOYEE_CREATE_SUCCESS,
    EMPLOYEE_DELETE_FAIL,
    EMPLOYEE_DELETE_REQUEST,
    EMPLOYEE_DELETE_SUCCESS,
    EMPLOYEE_EDIT_FAIL,
    EMPLOYEE_EDIT_REQUEST,
    EMPLOYEE_EDIT_SUCCESS,
    EMPLOYEE_FAIL,
    EMPLOYEE_REQUEST,
    EMPLOYEE_SUCCESS
} from '../actions/employee';

const initialState = {
    employeeData: {},
    employeeStatus:'',
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case EMPLOYEE_REQUEST: {
            return {
                ...state,
                employeeStatus: 'request',
                employeeData: {},
            };
        }
        case EMPLOYEE_SUCCESS: {
            return {
                ...state,
                employeeStatus: 'success',
                employeeData: action.payload.data,
            };
        }

        case EMPLOYEE_CREATE_SUCCESS: {
            return {
                ...state,
                employeeStatus: 'success',
                employeeData: action.payload.data,
            };
        }

        case EMPLOYEE_DELETE_SUCCESS: {
            return {
                ...state,
                employeeStatus: 'success',
                employeeData: action.payload.data,
            };
        }

        case EMPLOYEE_EDIT_SUCCESS: {
            return {
                ...state,
                employeeStatus: 'success',
                employeeData: action.payload.data,
            };
        }
        case EMPLOYEE_CREATE_REQUEST: {
            return {
                ...state,
                employeeStatus: 'request',
                employeeData: {},
            };
        }

        case EMPLOYEE_DELETE_REQUEST: {
            return {
                ...state,
                employeeStatus: 'request',
                employeeData: {},
            };
        }

        case EMPLOYEE_EDIT_REQUEST: {
            return {
                ...state,
                employeeStatus: 'request',
                employeeData: {},
            };
        }case EMPLOYEE_CREATE_FAIL: {
            return {
                ...state,
                employeeStatus: 'fail',
                employeeData: {},
            };
        }

        case EMPLOYEE_DELETE_FAIL: {
            return {
                ...state,
                employeeStatus: 'fail',
                employeeData: {},
            };
        }

        case EMPLOYEE_EDIT_FAIL: {
            return {
                ...state,
                employeeStatus: 'fail',
                employeeData: {},
            };
        }
        case EMPLOYEE_FAIL: {
            return {
                ...state,
                employeeStatus: 'fail',
            };
        }

        default: {
            return {
                ...state,
            };
        }
    }
}

import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBarsProgress, faBarsStaggered} from "@fortawesome/free-solid-svg-icons";
import {NavLink} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import Utils from "../helpers/Utils";
import {useDispatch, useSelector} from "react-redux";
import {taskDeleteRequest, taskEditRequest, taskRequest} from "../store/actions/task";
import {Helmet} from "react-helmet";
import qs from "query-string";
import classNames from "classnames";
import FilterDrop from "../components/FilterDrop";
import TaskCreate from "../components/TaskCreate";
import DeleteIcon from "@mui/icons-material/Delete";
import {list} from "../menu";

function TaskLine() {
    const dispatch = useDispatch()
    const location = useLocation();
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [create, setCreate] = useState(false);
    const [boards, setBoards] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const taskData = useSelector(state => state.task.taskData)
    const taskStatus = useSelector(state => state.task.taskStatus)

    useEffect(() => {
        (async () => {
            setSearch(query.filter + '' || '')
            await dispatch(taskRequest(1000, 1, query.filter || '', query.search || 'all'));
        })();
    }, []);

    useEffect(() => {
        setBoards(Utils.sortTasks(taskData))

    }, [taskData])


    const [currentBoard, setCurrentBoard] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)

    function handleDragEnd(ev) {
        ev.target.style.boxShadow = 'none'
        setIsEditing(false)

    }

    function handleLeave(ev) {
        ev.target.style.boxShadow = 'none'

    }

    function handleDragStart(ev, board, item) {
        setCurrentBoard(board)
        setIsEditing(true)
        setCurrentItem(item)
    }

    function handleDragOver(ev) {
        ev.preventDefault()
        if (ev.target.className === 'item') {
            ev.target.style.boxShadow = '0 4px 3px #ccc'
        }
    }

    const handleDrop = useCallback(async (ev, board, item) => {
        ev.preventDefault()
        if (board.id === 1) {
            return
        }
        if(board.id === currentBoard.id){
            return
        }


        const currentIndex = currentBoard.items.indexOf(currentItem)
        currentBoard.items.splice(currentIndex, 1)
        const dropIndex = board.items.indexOf(item)
        board.items.splice(dropIndex + 1, 0, currentItem)
        setBoards(boards.map(b => {
            if (b.id === board.id) {
                return board
            }
            if (b.id === currentBoard.id) {
                return currentBoard
            }
            return b
        }))

        ev.target.style.boxShadow = 'none'
        if(board.id === 2){
            await dispatch(taskEditRequest(currentItem.id,{...currentItem,deadline:Utils.dateToTimestamp(new Date().toISOString()).split(" ")[0]+' '+'23:59:59',responsible: currentItem['employee_task.user.email']},1,1000,'','all'))
        }else if(board.id === 3){
            await dispatch(taskEditRequest(currentItem.id,{...currentItem,deadline:Utils.tomorrow()+' '+'23:59:59',responsible: currentItem['employee_task.user.email']},1,1000,'','all'))
        }

    },[boards,currentItem,currentBoard])

    function handleDragEndLi(ev) {
        ev.target.style.boxShadow = 'none'

    }

    function handleLeaveLi(ev) {
        ev.target.style.boxShadow = 'none'

    }

    function handleDragStartLi(ev, board, item) {
        setCurrentBoard(board)
        setCurrentItem(item)
    }

    function handleDragOverLi(ev) {
        ev.preventDefault()
        if (ev.target.className === 'item') {
            ev.target.style.boxShadow = '0 4px 3px #ccc'
        }
    }

    const handleDropLi = useCallback(async (ev) => {
        ev.preventDefault()
        setIsEditing(false)

        if(+ev.target.id === 1){
            await dispatch(taskDeleteRequest(currentItem.id, currentItem['employee_task.user.email'],1,1000,'','all',))
        }else if(+ev.target.id === 2){
            await dispatch(taskEditRequest(currentItem.id,{...currentItem,deadline:Utils.tomorrowTomorrow()+' '+'23:59:59',responsible: currentItem['employee_task.user.email']},1,1000,'','all'))
        }else if(+ev.target.id === 4){
            await dispatch(taskEditRequest(currentItem.id,{...currentItem,deadline:Utils.nextMonth()+' '+'23:59:59',responsible: currentItem['employee_task.user.email']},1,1000,'','all'))
        }else if(+ev.target.id === 3){
            await dispatch(taskEditRequest(currentItem.id,{...currentItem,status:2,responsible: currentItem['employee_task.user.email']},1,1000,'','all'))
        }

    },[boards,currentItem,currentBoard])



    const handleSearch = useCallback(async (val) => {
        if (query.filter && query.filter === val) {
            query.filter = ''
            setSearch('')
        } else {
            query.filter = val
            setSearch(val)
        }

        await dispatch(taskRequest(1000, 1, query.filter || '', query.search || ''));

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    return (
        <>
            <Helmet>
                <title>Task</title>
            </Helmet>
                <header className="taskFilter">
                    <div className="menuLink">
                        <NavLink to={`/task/line`}>
                            <FontAwesomeIcon icon={faBarsProgress}/>
                        </NavLink>
                        <NavLink to='/task/list'>
                            <FontAwesomeIcon icon={faBarsStaggered}/>
                        </NavLink>
                    </div>
                    <div className="dayFilter">
                        <span className={classNames({active: search === 'day'})}
                              onClick={() => handleSearch('day')}>Day</span>
                        <span className={classNames({active: search === 'week'})}
                              onClick={() => handleSearch('week')}>Week</span>
                        <span className={classNames({active: search === 'month'})}
                              onClick={() => handleSearch('month')}>Month</span>
                    </div>
                    <label className="d-flex align-items-center" style={{width: '60%'}}>
                        Filters
                        <FilterDrop line={true}/>
                    </label>
                    <button className="taskBtn" onClick={() => setCreate(true)}>
                        Add task
                    </button>
                </header>


                <div className="mainTask justify-content-center" style={{paddingBottom:150,justifyContent:'center'}}>
                    {boards.map((board) => {
                        if (board.items.length) {
                            return (
                                <div
                                    id={board.id}
                                    key={board.id}>

                                    <div className={board.class}>
                                        <span>{board.content}</span>
                                        <span className="count">{board.items.length} task</span>
                                    </div>
                                    {board.items.map((item) => (
                                        <div
                                            id={item.id}
                                            onDragStart={(ev) => handleDragStart(ev, board, item)}
                                            onDragEnd={(ev) => handleDragEnd(ev, item)}
                                            onDragOver={(ev) => handleDragOver(ev)}
                                            onDragLeave={(ev) => handleLeave(ev, item)}
                                            onDrop={(ev) => handleDrop(ev, board, item)}
                                            draggable={true}
                                            key={item.id}
                                            className="item"
                                        >
                                            <span>
                                                <span className={board.class} style={{fontSize: 10}}>
                                                {Utils.tomorrow() === new Date(item?.deadline).toISOString().split('T')[0]?'Tomorrow ' + Utils.TimestampToDate(new Date(item.deadline)).split(' ') [4] :
                                                    Utils.yesterday() === new Date(item.deadline).toISOString().split('T')[0]?'Yesterday ' + Utils.TimestampToDate(new Date(item.deadline)).split(' ') [4] :
                                                        new Date().toISOString().split('T')[0] === new Date(item.deadline).toISOString().split('T')[0]?'Today ' + Utils.TimestampToDate(new Date(item.deadline)).split(' ') [4]
                                                            :Utils.TimestampToDate(new Date(item.deadline))} </span>
                                            <span style={{fontSize: 10, marginLeft:3}}> for {item['employee_task.user.email']}</span>
                                            </span>
                                            <span>
                                                <b style={{fontSize: 10}}>{item.type}</b>
                                                <span style={{marginLeft:3,fontSize: 10}}>{item.description}</span>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            )
                        }else{
                            return null
                        }
                    })}
                </div>
                {isEditing? <div className="taskLineEdit">
                    <ul>
                        {list.map((l) => (<li
                            key={l.id}
                            id={l.id}
                            onDragStart={(ev) => handleDragStartLi(ev)}
                            onDragEnd={(ev) => handleDragEndLi(ev)}
                            onDragOver={(ev) => handleDragOverLi(ev)}
                            onDragLeave={(ev) => handleLeaveLi(ev)}
                            onDrop={(ev) => handleDropLi(ev)}
                            draggable={true}
                            className={l.icon?"delete":'' }>{l.icon?<DeleteIcon/>:l.text}</li>)
                        )}
                    </ul>
                </div>:null}
                <TaskCreate create={create} line={true} setCreate={setCreate}/>
        </>
    );
}

export default TaskLine;

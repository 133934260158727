import React, {useCallback, useEffect, useState} from 'react';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {companyColumn} from "../tableColumns";
import {useDispatch, useSelector} from "react-redux";
import CompanyCreateAndEdit from "../components/CompanyCreateAndEdit";
import CompanyDelete from "../components/CompanyDelete";
import {companyRequest} from "../store/actions/company";
import Loader from "../components/Loader";
import {useLocation, useNavigate} from "react-router";
import qs from "query-string";
import {Pagination} from "@mui/material";
import {Helmet} from "react-helmet";
import CompanyDrop from "../components/CompanyDrop";
import CompanyExel from "../components/CompanyExel";
import {companyStatusRequest} from "../store/actions/department";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object
    .keys(Icons)
    .filter(key => key !== "fas" && key !== "prefix" )
    .map(icon => Icons[icon])

library.add(...iconList)
import _ from 'lodash'
import {faHourglass} from "@fortawesome/free-solid-svg-icons";
import {company, global} from "../translate";
import CompanyPrice from "../components/CompanyPrice";

function Company(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const dispatch = useDispatch()
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [edit, setEdit] = useState(0);
    const [deleteItem, setDeleteItem] = useState(0);
    const [create, setCreate] = useState(false);
    const [createExel, setCreateExel] = useState('');
    const [page, setPage] = useState(1);
    const [price, setPrice] = useState([]);
    const [isPrice, setIsPrice] = useState(false);
    const companyData = useSelector((state) => state.company.companyData)
    const lang = useSelector((state) => state.users.lang)
    const companyStatus = useSelector((state) => state.company.companyStatus)
    useEffect(() => {
        (async () => {
            setRowsPerPage(+query.limit || 10)
            setPage(+query.page || 1);
            await dispatch(companyRequest(query.limit || 10, query.page || 1, query.search || '', query.startTime || '', query.endTime || new Date(), query.searchByManager || '', query.status || '',));
        })();
    }, []);
    const statusData = useSelector((state) => state.department.companyStatusData)


    useEffect(() => {
        (async () => {
            await dispatch(companyStatusRequest());
        })();
    }, []);


    const handleChangePage = useCallback(async (event, newPage) => {
        query.page = newPage;
        setPage(newPage);
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
        await dispatch(companyRequest(query.limit || 10, query.page || 1, query.search || '', query.startTime || '', query.endTime || new Date(), query.searchByManager || '', query.status || '',query.phoneFil || '', query.isTest || '',
            query.isBlocked || '',));

    }, [location.search]);

    const handleChangeRowsPerPage = useCallback((event) => {
        setPage(1);
        setRowsPerPage(+event.target.value);
        query.limit = event.target.value;
        query.page = '1';
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    const handleEdit = useCallback((id) => {
        setEdit(id)
    }, [])
    const handleDelete = useCallback((id) => {
        setDeleteItem(id)
    }, [])

    return (
        <>
            <Helmet>
                <title>{company.title[+lang]}</title>
            </Helmet>
            <header className="taskFilter justify-content-between">
                <div className="d-flex align-items-center w-100">
                    <div style={{
                        padding: window.innerWidth < 450 ? '0 15px 0px 90px' : '0 20px',
                        height: 65,
                        borderRight: '1px solid #74c5e2'
                    }}
                         className="d-flex align-items-center">
                        <b>{company.title[+lang]}</b>
                    </div>

                </div>
                <button className="taskBtn" style={{width: 100}} onClick={() => setCreate(true)}>
                    {global.add[+lang]}
                </button>
                <button className="taskBtn" style={{width: 100}} onClick={() => setCreateExel('create')}>
                    {global.upload[+lang]}

                </button>
                <button className="taskBtn" style={{width: 200}} onClick={() => setCreateExel('edit')}>
                    Edit By Exel
                </button>
            </header>

            <CompanyDrop/>
            <div className="taskTable" style={{paddingTop: 0,marginBottom:60}}>

                {companyStatus === 'request' ? (<div className="loaderBack">
                    <Loader/>
                </div>) : (
                    <table>
                        <thead>
                        <tr>
                            {companyColumn.map((column) => (
                                <th
                                    key={column}
                                >
                                    {column[+lang]}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {companyData.company?.length ? companyData.company?.map((row) => {
                            return (
                                <tr key={row.id}>
                                    <td>{row.title}</td>
                                    <td>{row.description}</td>
                                    <td style={{cursor:'pointer'}} onClick={() =>
                                    {
                                        setIsPrice(true);
                                        setPrice(row.totalPrice);
                                    }}>{row?.totalPrice?.total_price || 0}</td>
                                    <td>{row.phone}</td>
                                    <td>{new Date(row.createdAt).toLocaleString('en-US', {
                                        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                        hour12: false,
                                    })}</td>
                                    <td style={{textAlign:'center',fontSize:20,color:row?.status && statusData?statusData.find(s => s.id === row?.status)?.color:'#ccc'}}><FontAwesomeIcon icon={row?.status && statusData?statusData.find(s => s.id === row?.status)?.icon:'hourglass'} /></td>
                                    <td style={{textAlign:'center',fontSize:20,color:+row?.isTest && statusData ?statusData.find(s => s.id === 4)?.color:statusData ?statusData.find(s => s.id === 5)?.color:'#ccc'}}><FontAwesomeIcon icon={+row?.isTest && statusData ?statusData.find(s => s.id === 4)?.icon:statusData ?statusData.find(s => s.id === 5)?.icon:'hourglass'} /></td>
                                    <td style={{textAlign:'center',fontSize:20,color:+row?.isBlocked && statusData ?statusData.find(s => s.id === 6)?.color: statusData ?statusData.find(s => s.id === 7)?.color:'#ccc'}}><FontAwesomeIcon icon={+row?.isBlocked && statusData ?statusData.find(s => s.id === 6)?.icon:statusData ?statusData.find(s => s.id === 7)?.icon:'hourglass'} /></td>
                                    <td align="center">
                                        <EditIcon style={{marginRight: 30, cursor: 'pointer'}}
                                                  onClick={() => handleEdit(row.id)}/>
                                        <DeleteIcon style={{cursor: 'pointer'}}
                                                    onClick={() => handleDelete(row.id)}/></td>
                                </tr>
                            );
                        }) : <tr>
                            <td colSpan={11} align="center">No data</td>
                        </tr>}

                        </tbody>
                        <tfoot style={{borderTop: '1px solid #ccc'}}>
                        <tr>
                            <td align="right" colSpan='1'>
                                {/*<label>*/}
                                {/*    Select row count*/}
                                {/*    <select value={rowsPerPage} style={{margin: '0 10px'}} onChange={(ev) => handleChangeRowsPerPage(ev)}>*/}
                                {/*        <option value="10">10</option>*/}
                                {/*        <option value="30">30</option>*/}
                                {/*        <option value="50">50</option>*/}
                                {/*    </select>*/}
                                {/*</label>*/}
                            </td>

                            <td className="paginationBlock" colSpan='9' align="right">
                                <Pagination count={companyData.totalPages} page={+page} onChange={handleChangePage}
                                            variant="outlined" color="primary"/>
                            </td>

                        </tr>
                        </tfoot>
                    </table>)}
            </div>
            {edit || create ?
                <CompanyCreateAndEdit edit={edit} setEdit={setEdit} create={create} setCreate={setCreate}/> : null}
            <CompanyDelete deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>
            {createExel ? <CompanyExel create={createExel} setCreate={setCreateExel}/> : null}
            {isPrice && !_.isEmpty(price)? <CompanyPrice create={isPrice} setCreate={setIsPrice} data={price}/> : null}
        </>
    );
}

export default Company;

import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Helmet} from "react-helmet";
import AdminWrapper from "../components/AdminWrapper";
import {clientColumn} from "../tableColumns";
import qs from 'query-string';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {clientRequest} from "../store/actions/clients";
import ClientCreateAndEdit from "../components/ClientCreateAndEdit";
import ClientDelete from "../components/ClientDelete";
import Loader from "../components/Loader";
import {useLocation, useNavigate} from "react-router";
import {Pagination} from "@mui/material";
import CompanyCreateAndEdit from "../components/CompanyCreateAndEdit";
import {global, phones} from "../translate";

function Clients(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [edit, setEdit] = useState(0);
    const [deleteItem, setDeleteItem] = useState(0);
    const [phone, setPhone] = useState('');
    const [create, setCreate] = useState(false);
    const [createCompany, setCreateCompany] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const clientData = useSelector((state) => state.client.clientData)
    const lang = useSelector((state) => state.users.lang)
    const clientStatus = useSelector((state) => state.client.clientStatus)
    useEffect(() => {
        (async () => {
            setRowsPerPage(+query.limit || 10)
            setPage(+query.page || 1);
            setSearch(query.search || '')
            await dispatch(clientRequest(query.limit || 10, query.page || 1, query.search || ''));
        })();
    }, []);

    const handleChangePage = useCallback(async (event, newPage) => {
        setPage(newPage);
        query.page = newPage;
        await dispatch(clientRequest(query.limit || 10, query.page || 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    const handleChangeRowsPerPage = useCallback(async (event) => {
        setRowsPerPage(+event.target.value);
        setPage(1);
        query.limit = event.target.value;
        query.page = '1';
        await dispatch(clientRequest(query.limit || 10, query.page || 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    const handleSearch = useCallback(async (ev) => {
        setSearch(ev.target.value)
        query.search = ev.target.value;
        query.page = '1'
        setPage(1)
        await dispatch(clientRequest(query.limit || 10, 1, query.search || ''));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])
    const handleEdit = useCallback((id) => {
        setEdit(id)
    }, [])

    const handleDelete = useCallback((id) => {
        setDeleteItem(id)
    }, [])

    return (
        <>
            <Helmet>
                <title>
                    {phones.title[+lang]}
                </title>
            </Helmet>
                <header className="taskFilter justify-content-between">
                    <div className="d-flex align-items-center" style={{width:'100%'}}>
                        <div style={{padding:window.innerWidth < 450? '0 15px 0px 90px': '0 20px', height:65, borderRight:'1px solid #74c5e2'}} className="d-flex align-items-center">
                            <b>{phones.title[+lang]}</b>
                        </div>
                        <label className="d-flex align-items-center" style={{width: '60%'}}>
                            {phones.title[+lang]}
                            <input style={{width:'100%',border:'none'}} value={search} onChange={handleSearch}/>
                        </label>
                    </div>
                    <button className="taskBtn" onClick={() => setCreate(true)}>
                        {global.add[+lang]}
                    </button>
                </header>
                <div className="taskTable">
                    {clientStatus === 'request' ? (<div className="loaderBack">
                        <Loader/>
                    </div>) : (<table>
                        <thead>
                        <tr>
                            {clientColumn.map((column) => (
                                <th
                                    key={column}
                                >
                                    {column[+lang]}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {clientData.client?.length ? clientData.client?.map((row) => {
                            return (
                                <tr key={row.id}>
                                    <td>{row.first_name}</td>
                                    <td>{row.last_name}</td>
                                    <td>{row.phone}</td>
                                    <td>{row.comment}</td>
                                    <td align="center">
                                        <EditIcon style={{marginRight:30, cursor: 'pointer'}}
                                                  onClick={() => handleEdit(row.id)}/>
                                        <DeleteIcon style={{cursor: 'pointer'}}
                                                    onClick={() => handleDelete(row.id)}/>
                                    </td>
                                    <td style={{cursor:'pointer'}} onClick={() => {
                                        setPhone(row.phone)
                                        setCreateCompany(true)
                                    }}>+ Create company</td>
                                </tr>
                            );
                        }) : <tr>
                            <td colSpan={10} align="center">No data</td>
                        </tr>}

                        </tbody>
                        <tfoot style={{borderTop: '1px solid #ccc'}}>
                        <tr>
                            <td align="right" colSpan='2'>
                                {/*<label>*/}
                                {/*    Select row count*/}
                                {/*    <select value={rowsPerPage} style={{margin: '0 10px'}}*/}
                                {/*            onChange={(ev) => handleChangeRowsPerPage(ev)}>*/}
                                {/*        <option value="10">10</option>*/}
                                {/*        <option value="30">30</option>*/}
                                {/*        <option value="50">50</option>*/}
                                {/*    </select>*/}
                                {/*</label>*/}
                            </td>
                            <td className="paginationBlock" colSpan='4' align="right">
                                <Pagination count={clientData.totalPages} page={+page} onChange={handleChangePage}
                                            variant="outlined" color="primary"/>
                            </td>

                        </tr>
                        </tfoot>
                    </table>)}
                </div>
                <ClientCreateAndEdit edit={edit} setEdit={setEdit} create={create} setCreate={setCreate}/>
                {createCompany?<CompanyCreateAndEdit phone={phone} create={createCompany} setCreate={setCreateCompany} />:null}
                <ClientDelete deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>
        </>
    );
}

export default Clients;

import React, {useCallback, useState} from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {useDispatch, useSelector} from "react-redux";
import {taskAllRequest, taskDeleteRequest, taskEditRequest} from "../store/actions/task";
import TaskEdit from "./TaskEdit";
import {useLocation} from "react-router";
import qs from "query-string";

function TaskComponent({isCheckAll, handleAllCheck,setIsCheck, id, data}) {
    const dispatch = useDispatch();
    const profileData = useSelector(state => state.users.userData)

    const [open, setOpen] = useState(false)
    const [resone, setResone] = useState('')
    const [isAll,setIsAll] = useState(false)
    const location = useLocation();
    const query = qs.parse(location.search, {arrayFormat: 'comma'});

    const handleCheck = useCallback(async () => {
        if(+id === 0){
            if(query.search === 'done' || query.search === 'deleted' || !query.search){
                await dispatch(taskAllRequest({},1,10,'','reset'))
            }else{
                await dispatch(taskAllRequest({},1,10,'','check'))
            }
        }
        else{
            if(query.search === 'done' || query.search === 'deleted'){

                await dispatch(taskEditRequest(data.id, {
                    ...data,
                    responsible: data['employee_task.user.email'],
                    result:'',
                    status: 1
                },query.page || 1,query.limit|| 10,query.filter || '',query.search || ''))

            }else{

                setResone('result')
                setOpen(true)
            }
        }

    },[id,data,location.search])
    const handleDelete = useCallback(async () => {
        if(+id === 0){
            await dispatch(taskAllRequest({},1,10,'','delete'))
        }else{
            await dispatch(taskDeleteRequest(id, data['employee_task.user.email'],query.page||1,query.limit || 10,'',query.search ||''))
        }

    }, [id,location.search,data])
    const handleEmployeeChange = useCallback(async () => {
        if(+id === 0){
            setIsAll(true)
            setResone('responsible')
            setOpen(true)
        }else{
            setResone('responsible')
            setOpen(true)
        }

    }, [id])
    const handleDeadlineChange = useCallback(async () => {
        if(+id === 0){
            setIsAll(true)
            setResone('deadline')
            setOpen(true)
        }else{
            setResone('deadline')
            setOpen(true)
        }

    }, [id])
    const handleChangeTaskType = useCallback(async () => {
        if(+id === 0){
            setResone('type')
            setOpen(true)
            setIsAll(true)
        }else{
            setResone('type')
            setOpen(true)
        }

    }, [id])

    return (
        <tr style={{background: '#d7e5fa'}}>
            <td className="taskAction"  colSpan='42'>
                <span className="p-0">
                    <input
                        id={id}
                        checked={isCheckAll}
                        onChange={handleAllCheck}
                        type="checkbox"/>
                </span>
                <span onClick={handleCheck}>
                    <CheckCircleOutlineIcon/>
                    <span>{query.search === 'all' || !query.search?'Check':'Recover'}</span>
                </span>
                {query.search === 'all' || !query.search?
                    <span onClick={handleDelete}>
                    <DeleteIcon/>
                    <span>Delete</span>
                </span>:null}

                {profileData.is_admin? <span onClick={handleEmployeeChange}>
                    <ChangeCircleIcon/>
                    <span>Change the employer</span>
                </span>:null}


                <span onClick={handleDeadlineChange}>
                    <AccessTimeIcon/>
                    <span> Change deadline</span>
                </span>
                <span onClick={handleChangeTaskType}>
                    <ChangeCircleIcon/>
                    <span>Change task type</span>
                </span>
                <TaskEdit
                    data={data}
                    isAll={isAll}
                    setIsAll={setIsAll}
                    setIsCheck={setIsCheck}
                    handleAllCheck={handleAllCheck}
                    resone={resone}
                    open={open}
                    setOpen={setOpen}/>
            </td>
        </tr>
    );
}

export default TaskComponent;

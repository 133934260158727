import React, {useCallback, useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';

function NotFound() {
    // const [theme, setTheme] = useState(false);
    // useEffect(() => {
    //     if(localStorage.getItem('theme')==='light' || !localStorage.getItem('theme')){
    //         setTheme(true)
    //     }else{
    //         setTheme(false)
    //     }
    // },[localStorage])
    //
    // const changeTheme = useCallback(() => {
    //     if(localStorage.getItem('theme')==='light' || !localStorage.getItem('theme')){
    //         localStorage.setItem('theme','dark')
    //     }else{
    //         localStorage.setItem('theme','light')
    //     }
    //
    //     setTheme(!theme)
    // }, [localStorage,theme])
  return (
    <>
      <Helmet>
        <title>Not found</title>
      </Helmet>
      <div className="error__block d-flex flex-column align-items-center justify-content-center">
        <div className="Error404">404</div>
        <div className="ErrorMessage">Oops! The page you&apos;re looking for is not found</div>
      </div>
    </>
  );
}

export default NotFound;

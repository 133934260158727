import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {Helmet} from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import {sipCreateRequest, sipEditRequest} from "../store/actions/sip";

function Settings(props) {
    const dispatch = useDispatch()
    const profile = useSelector(state => state.users.userData)
    const [socketData, setSocketData] = useState({
        server_url:'',
        port:'',
        way:'/ws',
        username:'',
        domain:'',
        sipUser:'',
        sipPass:''
    })
    useEffect(() => {
        if(profile){
            setSocketData({
                server_url:profile?.employee?.sip_manager?.sip_server || '',
                port:profile?.employee?.sip_manager?.sip_port || '',
                way:'/ws',
                username:profile?.employee?.sip_manager?.username || '',
                domain:profile?.employee?.sip_manager?.sip_server || '',
                sipUser:profile?.employee?.sip_manager?.uri || '',
                sipPass:profile?.employee?.sip_manager?.password || '',
                sipApi:profile?.employee?.sip_manager?.sip_api_key || ''
            })
        }
    }, [profile])
    const handleInput = useCallback((name,ev) => {
        setSocketData((prev) => ({
            ...prev,
            [name]: ev.target.value,
        }));
    },[])

    const handleSubmit = useCallback(async () => {
        if(profile?.employee?.sip_manager?.password){
            await dispatch(sipEditRequest(socketData))
        }else{
            await dispatch(sipCreateRequest(socketData))
        }
    }, [socketData,profile])
    return (
        <>
            <Helmet>
                <title>Settings</title>
            </Helmet>
                <div className="settings">
                    <span style={{fontSize:20}}>Account</span>
                    <label htmlFor='server'>Asterisk server address</label>
                    <input required id='server' value={socketData.server_url} onChange={(ev) => handleInput('server_url', ev)}/>
                    <label htmlFor='port'>WebSocket port</label>
                    <input required id='port' value={socketData.port} onChange={(ev) => handleInput('port', ev)}/>
                    <label htmlFor='WebSocket'>WebSocket</label>
                    <input required id='WebSocket' value={socketData.way} onChange={(ev) => handleInput('way', ev)}/>
                    <label htmlFor='username'>User name</label>
                    <input required id='username' value={socketData.username} onChange={(ev) => handleInput('username', ev)}/>
                    <label htmlFor='domain'>Domain</label>
                    <input required id='domain' value={socketData.domain} onChange={(ev) => handleInput('domain', ev)}/>
                    <label htmlFor='sipUser'>Sip user</label>
                    <input required id='sipUser' value={socketData.sipUser} onChange={(ev) => handleInput('sipUser', ev)}/>
                    <label htmlFor='sipPass'>Sip password</label>
                    <input required id='sipPass' value={socketData.sipPass} onChange={(ev) => handleInput('sipPass', ev)}/>
                    <label htmlFor='sipPass'>Sip Api key</label>
                    <input required id='sipPass' value={socketData.sipApi} onChange={(ev) => handleInput('sipApi', ev)}/>
                    <div className="d-flex align-items-center justify-content-center" style={{marginTop:20}}>
                        <button className="taskBtn" onClick={handleSubmit}>Save</button>
                    </div>
                </div>
        </>
    );
}

export default Settings;

import React, {useCallback, useState} from 'react';
import classNames from "classnames";
import {Link} from "react-router-dom";
import {menu} from "../menu";
import {faCoffee} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {useLocation} from "react-router";
import {useSelector} from "react-redux";

function AsideMenu({show,windowWidth}) {
    const [selected, setSelected] = useState(null)
    const location = useLocation();
    const userData = useSelector((state) => state.users.userData)
    const lang = useSelector((state) => state.users.lang)

    const toggleAccordion = useCallback((id) => {
        if (+selected === +id) {
            setSelected(null)
            return;
        }
        setSelected(id)
    }, [selected])
    return (
        <div style={{paddingTop:windowWidth?75:0}} className="sideBarMenuUl">
            {menu.map((l) => {
                if(l.title === "Call" && !userData.isAllowed){
                    return
                }
                return (
                    <Link to={l.link} key={l.id}

                          className={classNames("barMenuLi", {"active": +selected === +l.id || l.link === location.pathname && show})}>

                        <FontAwesomeIcon icon={l.icon ? l.icon : faCoffee}/>

                        <span className={classNames("text z")}>{l.title[+lang] || l.title}</span>


                        {/*{l.children.length ?*/}
                        {/*    (<div className={classNames("content", {"showAcc": +selected === +l.id})}*/}
                        {/*          style={{top: 35, width: '100%', left: 0}}>*/}
                        {/*        <ul>*/}
                        {/*            {l.children.map((c) => (<li key={c.id}>{c.title}</li>))}*/}
                        {/*        </ul>*/}
                        {/*    </div>) : null}*/}
                        {/*{l.children.length ?*/}
                        {/*    (*/}
                        {/*        <div className="custom_tooltip flex-column">*/}
                        {/*            <Link to={l.link} style={{ margin: 0}}>*/}
                        {/*                <span style={{color: '#8dd7f1'}}>{l.title}</span>*/}
                        {/*            </Link>*/}
                        {/*            <div className={classNames("content", {"showAcc": +selected === +l.id})}*/}
                        {/*                 style={{top: 35, width: '100%', left: 0 , borderRadius: 10}}>*/}
                        {/*                <ul>*/}
                        {/*                    {l.children.map((c) => (<li key={c.id}>{c.title}</li>))}*/}
                        {/*                </ul>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    ) : (*/}
                        {/*        <span className="custom_tooltip">*/}
                        {/*        <Link to={l.link} style={{ margin: 0}}>*/}
                        {/*            <span style={{color: '#8dd7f1'}}>{l.title}</span>*/}
                        {/*        </Link>*/}
                        {/*    </span>*/}
                        {/*    )}*/}
                    </Link>
                )
            })}

        </div>
    );
}

export default AsideMenu;


import { toast } from 'react-toastify';
import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  PROFILE_USER_REQUEST,
  PROFILE_USER_SUCCESS,
  PROFILE_USER_FAIL,
  SIP_REQUEST,
  SIP_SUCCESS,
  SIP_FAIL,
  LANGUAGE, EDIT_USER_SUCCESS,
} from '../actions/users';
import {SIP_CHECK_FAIL, SIP_CHECK_REQUEST, SIP_CHECK_SUCCESS} from "../actions/sip";

const initialState = {
  userStatus:'',
  userData:{},
  sip:[],
  sipStatus:'',
  sipCheck:'',
  sipCheckStatus:'',
  lang:0
};
// eslint-disable-next-line default-param-last
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LANGUAGE: {
      return {
        ...state,
        lang: action.payload.lang,
      };
    }
    case LOGIN_USER_SUCCESS: {
      return {
        ...state,
        userStatus: 'ok',
      };
    }
    case LOGIN_USER_FAIL: {
      return {
        ...state,
        userStatus: 'fail',
      };
    }

    case PROFILE_USER_REQUEST: {
      return {
        ...state,
        userStatus: 'request',
      };
    }
    case EDIT_USER_SUCCESS:
    case PROFILE_USER_SUCCESS: {
      return {
        ...state,
        userStatus: 'ok',
        userData: action.payload.data.user
      };
    }
    case PROFILE_USER_FAIL: {
      return {
        ...state,
        userStatus: 'fail',
      };
    }

    case SIP_REQUEST: {
      return {
        ...state,
        sipStatus: 'request',
      };
    }
    case SIP_SUCCESS: {
      return {
        ...state,
        sipStatus: 'ok',
        sip: action.payload.data.data
      };
    }
    case SIP_FAIL: {
      return {
        ...state,
        sipStatus: 'fail',
      };
    }
    case SIP_CHECK_REQUEST: {
      return {
        ...state,
        sipCheck:'',
        sipCheckStatus: 'request',
      };
    }
    case SIP_CHECK_SUCCESS: {
      return {
        ...state,
        sipCheckStatus: 'ok',
        sipCheck: action.payload.data.data?'ok':'no'
      };
    }
    case SIP_CHECK_FAIL: {
      return {
        ...state,
        sipCheck:'',
        sipCheckStatus: 'fail',
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

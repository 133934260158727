import React, {useCallback, useState} from 'react';
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {toast} from "react-toastify";
import qs from "query-string";
import {useLocation} from "react-router";
import {companyCreateExelRequest} from "../store/actions/company";
import {company} from "../translate";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 2,
    p: 4,
    height: window.innerHeight - 60,
    overflowY: 'auto',
};

function CompanyHistory({create, setCreate,data,dataAll}) {
    const statusData = useSelector((state) => state.department.companyStatusData)
    const lang = useSelector(state => state.users.lang)

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={!!create}
                onClose={() => setCreate(false)}
                closeAfterTransition
                slots={{backdrop: Backdrop}}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={!!create}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            <div className="createHeader justify-content-between align-items-center">
                                <h4 className="m-0">Company details and history</h4>
                                <span style={{cursor: 'pointer'}} onClick={() => setCreate(false)}>X</span>
                            </div>

                        </Typography>
                        <div className="d-flex createDiv flex-column justify-content-center">
                            {data?<h5 style={{textAlign: 'center',
                                borderBottom: '1px solid #30eeff',
                                paddingBottom: 10}}>General changes</h5>:null}
                            {data ? data.map(d=> {
                              return  (
                                    <div key={d.id} style={{marginTop:20}} className="d-flex flex-column createDiv align-items-center justify-content-center">
                                        <p>{new Date(d.when).toLocaleString('en-US', {
                                            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                            hour12: false,
                                        })}</p>
                                        {d.changed === 'status' || d.changed === 'blocked' || d.changed === 'test mode'? <p>{d.changed} - {d.changed === 'blocked' || d.changed === 'test mode'? Boolean(+d.value).toString() :statusData ? statusData.find(s => s.id === +d.value).title:null}</p>:<p style={{textAlign: 'center'}}>{d.changed} - {d.value}</p>}

                                        <p>Changer {d.his_man?.user?.email || 'not mentioned'}</p>
                                    </div>
                                )
                            }):null}
                        </div>
                        {dataAll?.old_com?.length ? <p style={{margin: '20px 0'}}
                                                               className="d-flex align-items-center justify-content-between">
                            <span>{company.comment[+lang]}</span>
                        </p> : null}
                        {dataAll?.old_com?.length ? dataAll.old_com.map((c,i) => <label key={i}
                                                                                        style={{fontSize:15}}
                                                                                            className="col-md-12">
                            <p>
                                {c.messages}
                            </p>
                            {c.created_at ?
                                <span style={{fontSize: 10, marginBottom: 20, display: 'inline-block'}}>Created at: {c.created_at}</span> : null}
                            {c.username ? <span style={{
                                fontSize: 10,
                                marginBottom: 20,
                                display: 'inline-block'
                            }}>By: <span>{c.username}</span></span> : null}
                        </label>) : null}
                        {dataAll?.price?.length ? <h5 style={{textAlign: 'center',
                            borderBottom: '1px solid #30eeff',
                            paddingBottom: 10}}>Price</h5>:null}
                        {dataAll?.price?.length ? (
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: '20px 0 0'
                            }}>
                                {dataAll.price.map((c, index) => {
                                    const elements = [];
                                    for (const key in c) {
                                        if (c.hasOwnProperty(key)) {
                                            if (key !== 'sdate') {
                                                elements.push(
                                                    <div key={key}>
                                                        <span>{key} - </span>
                                                        <span>{c[key]}</span>
                                                    </div>
                                                );
                                            } else {
                                                elements.unshift(
                                                    <div key={key}>
                                                        <span>{c[key]}</span>
                                                    </div>
                                                );
                                            }

                                        }
                                    }
                                    return elements;
                                })}
                            </div>

                        ) : null}
                    </Box>
                </Fade>
            </Modal>
        </div>

    );
}

export default CompanyHistory;

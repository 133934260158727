import React, {useCallback, useEffect, useState} from 'react';
import AdminWrapper from "../components/AdminWrapper";
import {Helmet} from "react-helmet";
import {historyColumn} from "../tableColumns";
import qs from "query-string";
import {useLocation, useNavigate} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {historyExelRequest, historyRequest} from "../store/actions/history";
import Loader from "../components/Loader";
import Utils from "../helpers/Utils";
import _ from 'lodash'
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Pagination} from "@mui/material";
import CompanyCreateAndEdit from "../components/CompanyCreateAndEdit";
import HistoryDrop from "../components/HistoryDrop";
import Details from "../components/Details";
import { REACT_APP_API_URL } from '../config';
import {history} from "../translate";
import {companyStatusRequest} from "../store/actions/department";
function History(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [edit, setEdit] = useState('');
    const [title, setTitle] = useState('');
    const [create, setCreate] = useState(false);
    const [detail, setDetail] = useState({hisId:0,record:''});
    const [phone, setPhone] = useState('');
    const [page, setPage] = useState(1);
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const userData = useSelector((state) => state.users.userData)
    const historyData = useSelector((state) => state.history.historyData)
    const lang = useSelector((state) => state.users.lang)
    const historyStatus = useSelector((state) => state.history.historyStatus)
    useEffect(() => {
        (async () => {
            // setRowsPerPage(+query.limit || 10)
            setPage(+query.page || 1);
            await dispatch(companyStatusRequest());
            await dispatch(historyRequest(query.limit || 10, query.page || 1, {
                startTime: query.startTime || new Date(),
                endTime: query.endTime || new Date(),
                number: query.number || '',
                status: query.status || '',
                disposition: query.disposition || '',
                company: query.company || ''
            }));
        })();
    }, []);

    const handleCreateCompany = useCallback( (phone) => {
        // setPhone('+' + phone)
        setCreate(true)
    }, [])


    const handleChangePage = useCallback(async (ev, newPage) => {
        setPage(newPage);
        query.page = newPage;
        await dispatch(historyRequest(query.limit || 10, query.page || 1, {
            startTime: query.startTime || new Date(),
            endTime: query.endTime || new Date(),
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            company: query.company || ''
        }));
        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search]);

    // const handleChangeRowsPerPage = useCallback(async (event) => {
    //     setRowsPerPage(+event.target.value);
    //     setPage(1);
    //     query.limit = event.target.value;
    //     query.page = '1';
    //     await dispatch(historyRequest(query.limit || 10, query.page || 1, search));
    //     navigate(`?${qs.stringify(query, {
    //         arrayFormat: 'comma',
    //         skipEmptyString: true,
    //     })}`);
    // }, [location.search,search]);

    const handleSearch = useCallback(async (ev) => {
        query.page = '1'
        setPage(1)
        let start = new Date();
        let end
        if (ev === 'Week') {
            end = new Date()
            start = new Date(start.setDate(end.getDate() - 7))
        } else {
            end = new Date()
            start = new Date(start.setMonth(end.getMonth() - 1))
        }
        query.endTime = end
        query.startTime = start
        await dispatch(historyRequest(query.limit || 10, 1, {
            startTime: query.startTime || new Date(),
            endTime: query.endTime || new Date(),
            number: query.number || '',
            status: query.status || '',
            disposition: query.disposition || '',
            company: query.company || ''
        }));

        navigate(`?${qs.stringify(query, {
            arrayFormat: 'comma',
            skipEmptyString: true,
        })}`);
    }, [location.search])

    return (
        <>
            <Helmet>
                <title>{history.title[+lang]}</title>
            </Helmet>
                <header className="taskFilter justify-content-start">
                    <div style={{padding: window.innerWidth < 450? '0 15px 0px 90px': '0 20px', height: 65, borderRight: '1px solid #74c5e2'}}
                         className="d-flex align-items-center">
                        <b>{history.title[+lang]}</b>
                    </div>
                    <div className="dayFilter">
                        <span onClick={() => handleSearch('Week')}>{history.week[+lang]}</span>
                        <span onClick={() => handleSearch('Month')}>{history.month[+lang]}</span>
                    </div>
                </header>
                <div className="taskTable" style={{marginBottom:60}}>
                    <HistoryDrop/>
                    {historyStatus === 'request' ? (<div className="loaderBack">
                        <Loader/>
                    </div>) : (<table>
                        <thead>
                        <tr>
                            {historyColumn.map((column) => (
                                <th
                                    key={column}
                                >
                                    {column[+lang]}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {historyData.history?.length ? historyData.history.map((row, i) => {
                            return (
                                <tr key={row.id}>
                                    <td>{new Date(row.start_time).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,hour12: false, })} - {new Date(row.end_time).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,hour12: false, })} </td>
                                    <td>{Utils.convertHMS(row.billsec)}</td>
                                    <td><p>{row.op}</p>{row.spyur?"INN "+row.spyur:null}{row.userOp?row.userOp.first_name + ' ' + row.userOp.last_name:null}</td>
                                    <td><p>{row.clid}</p>{row.userClId?row.userClId.first_name + ' ' + row.userClId.last_name:null}</td>
                                    <td>{row.disposition}</td>
                                    <td>{row.company ? row.history_company.title :
                                        <span style={{color: '#00d2ff', cursor: 'pointer'}}
                                              onClick={() => {
                                                  handleCreateCompany(row.disposition)
                                                  if(row.spyur){
                                                      setTitle(row.spyur)
                                                      setPhone('+' + row.op)
                                                  }else{
                                                      setTitle('')
                                                  }
                                              }}>Add company</span>}</td>
                                    <td>{row.calldirect === '1' ? 'Out Call' : row.calldirect === '0' ? 'Income Call' : row.calldirect === '3' ? 'Redirect Call' : 'Local Call'}</td>
                                    <td>
                                        <VisibilityIcon
                                            onClick={() => setDetail((prev) => ({
                                                ...prev,
                                                hisId: row.op,
                                                record: row.record_link
                                            }))}
                                            className="history_icon"
                                            style={{fontSize: 20, cursor: "pointer"}}/>
                                    </td>
                                </tr>
                            );
                        }) : <tr>
                            <td colSpan={10} align="center">No data</td>
                        </tr>}

                        </tbody>
                        <tfoot style={{borderTop: '1px solid #ccc'}}>
                        <tr>
                            <td align="left" colSpan='4'>
                                <a
                                    href={REACT_APP_API_URL + `/history/xlsx?page=${query.page || 1}&limit=${query.limit || 10}&phone=${query.number || ''}&startTime=${query.startTime || new Date()}&endTime=${query.endTime || new Date()}&disposition=${query.disposition || ''}&status=${query.status || ''}&token=${localStorage.getItem('access_token')}`}
                                    className="taskBtn" style={{width: 100,color:'white', textDecoration: 'none'}}>Exel</a>
                                {/*<label>*/}
                                {/*    Select row count*/}
                                {/*    <select value={rowsPerPage} style={{margin:'0 10px'}} onChange={(ev) => handleChangeRowsPerPage(ev)}>*/}
                                {/*        <option value="10">10</option>*/}
                                {/*        <option value="30">30</option>*/}
                                {/*        <option value="50">50</option>*/}
                                {/*    </select>*/}
                                {/*</label>*/}
                            </td>
                            <td className="paginationBlock" align="right" colSpan="6">
                                <Pagination count={historyData.totalPages} page={+page} onChange={handleChangePage}
                                            variant="outlined" color="primary"/>
                            </td>

                        </tr>
                        </tfoot>
                    </table>)}
                    {detail.hisId? <Details detail={detail} setDetail={setDetail}/>:null}
                    <CompanyCreateAndEdit title={title} create={create} setCreate={setCreate} phone={phone} edit={edit}
                                          setEdit={setEdit}/>
                </div>

        </>
    );
}

export default History;


import { takeLatest, call, put } from 'redux-saga/effects';
import {
    CLIENT_DELETE_SUCCESS,
    CLIENT_DELETE_REQUEST,
    CLIENT_DELETE_FAIL,
    CLIENT_EDIT_FAIL,
    CLIENT_EDIT_REQUEST,
    CLIENT_EDIT_SUCCESS,
    CLIENT_REQUEST,
    CLIENT_SUCCESS,
    CLIENT_FAIL,
    CLIENT_CREATE_SUCCESS,
    CLIENT_CREATE_FAIL,
    CLIENT_CREATE_REQUEST,
    CLIENT_SINGLE_REQUEST,
    CLIENT_SINGLE_SUCCESS, CLIENT_SINGLE_FAIL
} from '../actions/clients';
import Api from '../../Api';
import {toast} from "react-toastify";

function* handleClient(action) {
    try {
        const {rowsPerPage,page, search} = action.payload
        const { data } = yield call(Api.getClient, rowsPerPage,page,search);
        yield put({
            type: CLIENT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CLIENT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleClientSingle(action) {
    try {
        const {phone} = action.payload
        const { data } = yield call(Api.getClientSingle, phone);
        yield put({
            type: CLIENT_SINGLE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CLIENT_SINGLE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleClientEdit(action) {
    try {
        const { id, formData,limit, page, search } = action.payload;
        const {data} = yield call(Api.updateClient,id, formData,limit, page, search);
        toast.success('Client is successfully edited')

        yield put({
            type: CLIENT_EDIT_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)

        yield put({
            type: CLIENT_EDIT_FAIL,
            payload: { error: e.response },
        });
    }
}

function* handleClientCreate(action) {
    try {
        const {form,limit, page, search} = action.payload;
        const {data} = yield call(Api.createClient,form,limit, page, search);
        toast.success('Client is successfully created')

        yield put({
            type: CLIENT_CREATE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CLIENT_CREATE_FAIL,
            payload: { error: e.response },
        });
    }
}
function* handleClientDelete(action) {
    try {
        const { id } = action.payload;
        const {data} = yield call(Api.deleteClient,id);
        toast.success('Client is successfully deleted')

        yield put({
            type: CLIENT_DELETE_SUCCESS,
            payload: { data },
        });
    } catch (e) {
        toast.error(e.response.data.message)
        yield put({
            type: CLIENT_DELETE_FAIL,
            payload: { error: e.response },
        });
    }
}



export default function* watcher() {
    yield takeLatest(CLIENT_REQUEST, handleClient);
    yield takeLatest(CLIENT_CREATE_REQUEST, handleClientCreate);
    yield takeLatest(CLIENT_EDIT_REQUEST, handleClientEdit);
    yield takeLatest(CLIENT_DELETE_REQUEST, handleClientDelete);
    yield takeLatest(CLIENT_SINGLE_REQUEST, handleClientSingle);
}

import React, {useCallback, useState} from 'react';
import {useNavigate} from "react-router";
import {toast} from "react-toastify";
import Api from "../Api";
import {useDispatch, useSelector} from "react-redux";
import {sipEditRequest} from "../store/actions/sip";
import {profileUserRequest} from "../store/actions/users";

function VerifyCall({setVerHome,phone,loading}) {
    const [verify, setVerify] = useState()
    const navigate = useNavigate()
    const profile = useSelector(state => state.users.userData)
    const dispatch = useDispatch()

    const handleClose = useCallback(() => {
        setVerHome(false)
        navigate('/')
    }, [])
    const handleSubmit = useCallback(async () => {
        try{
            if(!verify){
                toast.error('Please enter verification code')
                return
            }
            const {data} = await Api.verifySip({phone:profile?.employee?.sip_manager?.uri,code:verify})
            if(data.status === 'ok'){
                await dispatch(sipEditRequest({
                    server_url:profile?.employee?.sip_manager?.sip_server || '',
                    port:profile?.employee?.sip_manager?.sip_port || '',
                    way:'/ws',
                    username:profile?.employee?.sip_manager?.username || '',
                    domain:profile?.employee?.sip_manager?.sip_server || '',
                    sipUser:profile?.employee?.sip_manager?.uri || '',
                    sipPass:profile?.employee?.sip_manager?.password || '',
                    sipApi:data.data.api_key
                }))
                setVerHome(false)

            }
            await dispatch(profileUserRequest())
        }catch (e) {
            toast.error(e.response.data.message)
        }

    }, [verify,phone,profile])
    return (
        <div className="verifyBlock">
            <div className="verifyChild">
                {loading?'loading':<>
                    <div>
                        <h4>Verify your api key</h4>
                        <span onClick={handleClose}>x</span>
                    </div>
                    <span>We sent you verification code</span>
                    <label>
                        Enter Code:
                        <input value={verify} onChange={(ev) => setVerify(ev.target.value)} type="number"/>
                    </label>
                    <button className="taskBtn" onClick={handleSubmit}>Submit</button>
                </>}
            </div>
        </div>

    );
}

export default VerifyCall;
